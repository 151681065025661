import React from "react";

import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";

import DashboardView from "../modules/Dashboard";

export default function Dashboard() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DashboardView />
    </Box>
  );
}
