import React from "react";

import { Box } from "@mui/material";

import { Paragraph, SubtitleStyles } from "../../types";
import SubtitleModeSwitcher from "./components/SubtitleModeSwitcher";
import StyleEditor from "./components/StyleEditor";
import SubtitlesEditor from "./components/SubtitlesEditor";

interface SubtitleProps {
  styles: SubtitleStyles;
  paragraphs: Paragraph[];
  onUpdateStyles: (styles: SubtitleStyles, paragraph?: Paragraph) => void;
  onUpdateParagraph: (paragraph: Paragraph) => void;
  currentTimeInMilliseconds: number;
  onParagraphClick: (ms: number) => void;
}

export default function Subtitle({
  styles,
  paragraphs,
  onUpdateStyles,
  onUpdateParagraph,
  currentTimeInMilliseconds,
  onParagraphClick,
}: SubtitleProps) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Box>
      <SubtitleModeSwitcher
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
      />

      <Box
        sx={{
          overflowY: "scroll",
          height: "570px",
        }}
      >
        {selectedTab === 1 && (
          <SubtitlesEditor
            paragraphs={paragraphs}
            onUpdateParagraph={onUpdateParagraph}
            currentTimeInMilliseconds={currentTimeInMilliseconds}
            onParagraphClick={(paragraph) =>
              // add 1 ms to fix the issue with selecting two paragraphs
              onParagraphClick(paragraph.start * 1000 + 1)
            }
          />
        )}

        {selectedTab === 0 && (
          <StyleEditor styles={styles} onUpdateStyles={onUpdateStyles} />
        )}
      </Box>
    </Box>
  );
}
