import React, { useEffect, useState } from "react";

import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import GlobalErrorAlertService from "./services/global-error-alert.service";

export default function GlobalErrorAlert() {
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const showAlert = (message: string): void => {
    setAlertMessage(message);
    setOpen(true);
  };

  useEffect(() => {
    GlobalErrorAlertService.setAlertHandler(showAlert);
  }, []);

  const handleClose = (
    event: React.SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setAlertMessage("");
  };

  return (
    <Snackbar
      open={open}
      message={alertMessage}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  );
}
