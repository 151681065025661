import React from "react";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

function Copyright(props: any) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://mui.com/">
          Podcast Copilot
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

export default Copyright;
