import React from "react";

import { Autocomplete, Box, TextField, Alert, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import BoltIcon from "@mui/icons-material/Bolt";

import { coreService } from "../../../../services";

interface Props {
  handleOnChange: (event: React.SyntheticEvent, data: any) => void;
  isPlanLimitReached: boolean;
  handleUpgradePlan: () => void;
}

export default function SelectProject({
  handleOnChange,
  isPlanLimitReached,
  handleUpgradePlan,
}: Props) {
  const { data: response } = useQuery({
    queryKey: ["projects"],
    queryFn: () => coreService.httpClient.get("/projects"),
  });

  const options = response?.data.projects.map(
    (project: Record<string, string>) => {
      return { label: project.name, id: project.id };
    },
  );

  return (
    <Box
      sx={{
        mt: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Autocomplete
        disablePortal
        clearOnEscape
        options={options || []}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Select Project" />
        )}
        onChange={handleOnChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <Box>
        {isPlanLimitReached && (
          <Box sx={{ mt: 2 }}>
            <Alert
              severity="error"
              action={
                <Button
                  onClick={handleUpgradePlan}
                  variant="text"
                  size="medium"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    mr: 1,
                    boxShadow: "none",
                    ":hover": { color: "#000", backgroundColor: "#fff" },
                    textTransform: "none",
                  }}
                  startIcon={<BoltIcon sx={{ color: "#ebac6e" }} />}
                >
                  Upgrade
                </Button>
              }
            >
              You have reached the limit of maximum minutes allowed for your
              plan. Please upgrade your plan to continue.
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
}
