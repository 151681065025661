import React from "react";

import { IconButton } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import EditDialog from "./components/EditDialog";
import DeleteDialog from "./components/DeleteDialog";
import RoleBasedAccess from "../../../../common/RoleBasedAccess";
import { RoleName } from "../../../../common/enums/RoleName";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

interface Props {
  projectId: string;
  projectName: string;
  projectDescription: string;
}

export default function ProjectActionCell({
  projectId,
  projectName,
  projectDescription,
}: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] =
    React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(!openEditDialog);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };
  return (
    <>
      <EditDialog
        handleClose={handleEditDialogClose}
        open={openEditDialog}
        projectId={projectId}
        projectName={projectName}
        projectDescription={projectDescription}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        projectId={projectId}
        projectName={projectName}
      />
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
          <MenuItem onClick={handleOpenEditDialog} disableRipple>
            <EditIcon />
            Edit
          </MenuItem>
        </RoleBasedAccess>
        <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
          <MenuItem onClick={handleOpenDeleteDialog} disableRipple>
            <DeleteIcon />
            Delete
          </MenuItem>
        </RoleBasedAccess>
      </StyledMenu>
    </>
  );
}
