import React from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, ErrorMessage } from "formik";
import {
  Alert,
  Box,
  TextField,
  FormControlLabel,
  FormLabel,
  FormControl,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";

import CustomButton from "../../../common/Button";
import { AddUserToOrganizationPayload } from "../../services/user-management.service";
import { RoleName } from "../../../common/enums/RoleName";
import { userManagementService } from "../../services";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  handleClose: () => void;
  open: boolean;
}

const roleDescriptions: Record<RoleName, string> = {
  [RoleName.VIEWER]: "Can view content and access basic features",
  [RoleName.EDITOR]: "Can create and modify content",
  [RoleName.ADMIN]: "Full access to all features and user management",
};

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function AddUserDialog({ handleClose, open }: Props) {
  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["add-user-to-organization"],
    mutationFn: (values: AddUserToOrganizationPayload) =>
      userManagementService.adduserToOrganization(values),
  });

  const handleAddUserToOrganization = async (values: {
    email: string;
    role: RoleName;
  }) => {
    await mutateAsync({
      email: values.email,
      roles: [values.role],
    });
  };

  if (isSuccess) {
    handleClose();
    window.location.reload();
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add User to Organization
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Once you submit the form, the user will receive an email with further
          instructions.
        </Typography>
        <Formik
          initialValues={{ email: "", role: RoleName.VIEWER }}
          validationSchema={Yup.object({
            email: Yup.string().required("Required"),
            role: Yup.string().required("Role is required"),
          })}
          onSubmit={handleAddUserToOrganization}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isError ? (
                  <Alert severity="error" sx={{ marginBottom: "12px" }}>
                    {error.message}
                  </Alert>
                ) : null}

                {isSuccess ? (
                  <Alert severity="success" sx={{ marginBottom: "12px" }}>
                    User Successfully Added!
                  </Alert>
                ) : null}
                <TextField
                  margin="normal"
                  label="Email"
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                  {...formik.getFieldProps("email")}
                />
                <FormControl
                  required
                  error={!!formik.errors.role}
                  component="fieldset"
                  sx={{ mt: 2 }}
                  variant="standard"
                >
                  <FormLabel component="legend" sx={{ textAlign: "left" }}>
                    Roles
                  </FormLabel>
                  <StyledRadioGroup
                    value={formik.values.role}
                    onChange={(e) =>
                      formik.setFieldValue("role", e.target.value)
                    }
                  >
                    {Object.values(RoleName).map((role) => (
                      <FormControlLabel
                        key={role}
                        value={role}
                        control={<Radio />}
                        label={
                          <Box sx={{ textAlign: "left" }}>
                            <Typography variant="subtitle1">{role}</Typography>
                            <Typography variant="body2" color="text.secondary">
                              {roleDescriptions[role]}
                            </Typography>
                          </Box>
                        }
                      />
                    ))}
                  </StyledRadioGroup>
                  <ErrorMessage name="role" component="div" />
                </FormControl>
                <CustomButton
                  label="Add User"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isPending || Object.keys(formik.errors).length > 0}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
