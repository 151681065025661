import { AxiosResponse } from "axios";

import { coreService } from "../../../services";
import {
  ExportVideoCompressionLevel,
  ExportVideoResolution,
  Paragraph,
  SubtitleStyles,
  VideoResponse,
} from "../types";

interface ExportShortVideoPayload {
  video_resolution: ExportVideoResolution;
  compression_level: ExportVideoCompressionLevel;
  fps: number;
}

const getVideo = async (
  episodeId: string | undefined,
): Promise<VideoResponse | undefined> => {
  if (episodeId) {
    const response = await coreService.httpClient.get(
      `/short-videos/${episodeId}`,
    );

    return response.data;
  }

  return undefined;
};

const updateShortVideo = async (
  id: string,
  data: {
    subtitleStyles?: SubtitleStyles;
    paragraph?: Paragraph;
  },
): Promise<AxiosResponse<void>> => {
  return coreService.httpClient.patch(`/short-videos/${id}`, {
    subtitle_styles: data.subtitleStyles,
    paragraph: data.paragraph,
  });
};

const exportShortVideo = async (
  id: string,
  payload: ExportShortVideoPayload,
): Promise<AxiosResponse<void>> => {
  return coreService.httpClient.post(`/short-videos/${id}/export`, payload);
};

export { getVideo, updateShortVideo, exportShortVideo };
