import React from "react";

import Container from "@mui/material/Container";

import LoginView from "../modules/Login";
import Footer from "../modules/common/Footer";

function LoginPage() {
  return (
    <Container component="main" maxWidth="xs">
      <LoginView />
      <Footer />
    </Container>
  );
}

export default LoginPage;
