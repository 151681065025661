import React from "react";

import { TableRow } from "@mui/material";

import { Column } from "../../../../common/Table/types";
import EpisodeSharedWithMeCell from "./EpisodeSharedWithMeCell";

interface Props {
  columns: Column[];
  row: Record<string, any>;
}

export default function EpisodeSharedWithMeRow({ columns, row }: Props) {
  const handleEpisodeClick = () => {
    window.location.href = `episodes-shared-with-me/${row.id}`;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      sx={{
        cursor: "pointer",
      }}
      onClick={handleEpisodeClick}
    >
      {columns.map((column) => {
        return (
          <EpisodeSharedWithMeCell
            key={column.id}
            column={column}
            value={row[column.id]}
          />
        );
      })}
    </TableRow>
  );
}
