import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function NewPasswordSuccessfullySet() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        padding: "30px 0px 10px 0px",
      }}
    >
      <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
        Your new password has been successfully set. You will be redirected to
        the sign-in page in a few seconds.
      </Typography>
    </Box>
  );
}
