import React from "react";

import { Box, Button, ButtonOwnProps } from "@mui/material";

interface Props extends ButtonOwnProps {
  type: "button" | "submit" | "reset" | undefined;
  label: string;
  handleOnClick?: () => void;
}

function ButtonView({
  type,
  fullWidth,
  variant,
  sx,
  label,
  startIcon,
  endIcon,
  disabled,
  handleOnClick,
  href,
}: Props) {
  return (
    <Box>
      {handleOnClick ? (
        <Button
          type={type || "submit"}
          fullWidth={fullWidth}
          variant={variant}
          sx={sx}
          endIcon={endIcon}
          startIcon={startIcon}
          onClick={handleOnClick}
          disabled={disabled}
        >
          {label}
        </Button>
      ) : (
        <Button
          type={type || "submit"}
          fullWidth={fullWidth}
          variant={variant}
          sx={sx}
          endIcon={endIcon}
          startIcon={startIcon}
          disabled={disabled}
          href={href}
        >
          {label}
        </Button>
      )}
    </Box>
  );
}

export default ButtonView;
