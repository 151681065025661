import React, { useState } from "react";

import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Stack,
  ListItemText,
  Divider,
} from "@mui/material";
import { ScreenShareOutlined } from "@mui/icons-material";

import {
  ExportVideoCompressionLevel,
  ExportVideoResolution,
} from "../../types";

export interface ExportVideoModalProps {
  open: boolean;
  handleClose: () => void;
  handleExport: (
    video_resolution: ExportVideoResolution,
    compression_level: ExportVideoCompressionLevel,
    fps: number,
  ) => void;
}

export default function ExportVideoModal({
  open,
  handleClose,
  handleExport,
}: ExportVideoModalProps) {
  const [compressionLevel, setCompressionLevel] = useState(
    ExportVideoCompressionLevel.BALANCED,
  );
  const [resolution, setResolution] = useState(ExportVideoResolution["1080p"]);
  const [fps, setFps] = useState<number>(30); // Default FPS

  const handleCompressionChange = (event: any) => {
    setCompressionLevel(event.target.value);
  };

  const handleResolutionChange = (event: any) => {
    setResolution(event.target.value);
  };

  const handleFpsChange = (event: any) => {
    setFps(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="export-video-modal"
      aria-describedby="export-video-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Export Video
        </Typography>

        <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
          <InputLabel>Compression Level</InputLabel>
          <Select
            value={compressionLevel}
            onChange={handleCompressionChange}
            label="Compression Level"
          >
            <MenuItem value={ExportVideoCompressionLevel.HIGHER_QUALITY}>
              <ListItemText
                primary="Higher Quality"
                secondary="Best visual quality, longer render times"
              />
            </MenuItem>
            <MenuItem value={ExportVideoCompressionLevel.BALANCED}>
              <ListItemText
                primary="Balanced"
                secondary="Good balance between quality and speed"
              />
            </MenuItem>
            <MenuItem value={ExportVideoCompressionLevel.VERY_FAST}>
              <ListItemText
                primary="Very Fast"
                secondary="Fast render times, lower quality"
              />
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
          <InputLabel>Resolution</InputLabel>
          <Select
            value={resolution}
            onChange={handleResolutionChange}
            label="Resolution"
          >
            <MenuItem value={ExportVideoResolution["320p"]}>
              <ListItemText
                primary="320p"
                secondary="Low resolution, faster upload and processing"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution["480p"]}>
              <ListItemText
                primary="480p"
                secondary="Standard definition, faster processing"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution["720p"]}>
              <ListItemText
                primary="720p"
                secondary="High definition, standard for online videos"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution["1080p"]}>
              <ListItemText
                primary="1080p"
                secondary="Full HD, good quality for most purposes"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution["4k"]}>
              <ListItemText
                primary="4K"
                secondary="Ultra HD, highest quality with long render times"
              />
            </MenuItem>
            <Divider />
            <MenuItem value={ExportVideoResolution.instagram}>
              <ListItemText
                primary="Instagram"
                secondary="Optimized for Instagram stories"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution.tiktok}>
              <ListItemText
                primary="TikTok"
                secondary="Optimized for TikTok format"
              />
            </MenuItem>
            <MenuItem value={ExportVideoResolution["youtube-shorts"]}>
              <ListItemText
                primary="YouTube Shorts"
                secondary="Optimized for YouTube Shorts"
              />
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
          <InputLabel>FPS</InputLabel>
          <Select value={fps} onChange={handleFpsChange} label="FPS">
            <MenuItem value={24}>
              <ListItemText
                primary="24 FPS"
                secondary="Cinematic feel, ideal for storytelling and narrative-driven content"
              />
            </MenuItem>
            <MenuItem value={30}>
              <ListItemText
                primary="30 FPS"
                secondary="Standard for most online videos, provides a smooth viewing experience"
              />
            </MenuItem>
            <MenuItem value={60}>
              <ListItemText
                primary="60 FPS"
                secondary="Smooth motion, great for dynamic and action-packed content"
              />
            </MenuItem>
            <MenuItem value={120}>
              <ListItemText
                primary="120 FPS"
                secondary="Ultra smooth, excellent for slow-motion and high-impact scenes"
              />
            </MenuItem>
          </Select>
        </FormControl>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            variant="outlined"
            startIcon={<ScreenShareOutlined />}
            onClick={() => handleExport(resolution, compressionLevel, fps)}
          >
            Export
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
