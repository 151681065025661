import { coreService } from "../../../services";
import { OrganizationResponse } from "../types";

const fetchOrganizationDetails = async (): Promise<OrganizationResponse> => {
  const response = await coreService.httpClient.get(`/organizations`);

  return response.data;
};

const updateOrganization = async (
  companyName: string,
): Promise<OrganizationResponse> => {
  const response = await coreService.httpClient.patch(`/organizations`, {
    name: companyName,
  });

  return response.data;
};

export { fetchOrganizationDetails, updateOrganization };
