/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { Formik } from "formik";
import { Box, TextField } from "@mui/material";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Bounce, toast } from "react-toastify";

import CustomButton from "../../Button";
import { episodeService } from "../../../Episode/services";
import { Category } from "../../types";
import { Chapter } from "../../../Episode/services/episode.service";

interface Props {
  chapter: string;
  chapters: Chapter[];
  handleCloseModal: () => void;
  refetch: () => void;
  category: Category;
}

function chapterModal({
  chapter,
  handleCloseModal,
  chapters,
  refetch,
  category,
}: Props) {
  const { id: episodeId } = useParams();

  const { mutateAsync, error, isSuccess, isPending } = useMutation({
    mutationKey: ["updateInsights-chapter"],
    mutationFn: (values: Chapter[]) =>
      episodeService.updateInsights(episodeId, {
        category,
        value: values,
      }),
  });

  const handlechapterUpdate = async (formikValue: { chapter: string }) => {
    const updatedChapters = chapters.map((val) => {
      if (chapter === val.title) {
        return { title: formikValue.chapter, start: val.start, end: val.end };
      }
      return val;
    });

    await toast.promise(mutateAsync(updatedChapters), {
      success: {
        toastId: `${chapter}-success`,
        render() {
          return "Saved!";
        },
        type: "success",
        theme: "colored",
        autoClose: 2000,
        transition: Bounce,
        delay: 500,
      },
      pending: {
        toastId: `${chapter}-pending`,
        render() {
          return "Saving, please wait...";
        },
        type: "default",
        theme: "colored",
        transition: Bounce,
      },
      error: {
        toastId: `${chapter}-error`,
        render() {
          return `Error: ${error?.message}`;
        },
        type: "error",
        theme: "colored",
        autoClose: false,
        transition: Bounce,
        delay: 500,
      },
    });
  };

  if (isSuccess) {
    handleCloseModal();
    refetch();
    return null;
  }

  const formikInitialValues = {
    chapter,
  };
  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={Yup.object({
        chapter: Yup.string().required("Required"),
      })}
      onSubmit={handlechapterUpdate}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                sx={{ width: "70%", alignSelf: "center" }}
                margin="normal"
                label="Chapter"
                error={!!formik.errors.chapter}
                helperText={formik.errors.chapter}
                multiline
                {...formik.getFieldProps("chapter")}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleCloseModal} disabled={isPending}>
              Close
            </Button>
            <CustomButton
              label="Save"
              type="submit"
              variant="contained"
              color="success"
              size="small"
              disabled={!formik.isValid || isPending}
            />
          </DialogActions>
        </form>
      )}
    </Formik>
  );
}

export default chapterModal;
