import React, { useEffect, useCallback } from "react";

import Box from "@mui/material/Box";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { googleLoginService } from "./services";
import { CookieKey, setCookie } from "../../hooks/useCookie";
import { useAuth } from "../../middlewares/Auth/AuthContext";

export default function GoogleLoginCallbackPageView() {
  const queryString = window.location.search;
  const auth = useAuth();
  const navigate = useNavigate();

  const handleGoogleLogin = useCallback(async (): Promise<void> => {
    try {
      const response = await googleLoginService.loginCallback(queryString);
      setCookie(CookieKey.JWT_TOKEN, response.jwt_token, {
        expires: moment()
          .add(response.jwt_token_expires_in, "milliseconds")
          .toDate()
          .toUTCString(),
      });
      setCookie(CookieKey.REFRESH_TOKEN, response.refresh_token, {
        expires: moment()
          .add(response.refresh_token_expires_in, "milliseconds")
          .toDate()
          .toUTCString(),
      });
      auth.login();
      navigate("/");
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    } catch (e) {
      navigate("/login");
    }
  }, [queryString, auth, navigate]);

  useEffect(() => {
    if (queryString) {
      handleGoogleLogin();
    }
  }, [queryString, handleGoogleLogin]);

  return <Box />;
}
