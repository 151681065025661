import React from "react";

import { Group } from "react-konva";

import Element from "../Element";
import { IElement } from "../Element/types/IElement";
import { ROW_HEIGHT_IN_PX, RULER_HEIGHT_IN_PX } from "../../constants";

interface RowProps {
  elements: IElement[];
  rowIndex: number;
  onElementChange: (element: IElement) => void;
  pixelsPerMillisecond: number;
  numberOfRows: number;
}

export default function Row({
  elements,
  rowIndex,
  onElementChange,
  pixelsPerMillisecond,
  numberOfRows,
}: RowProps) {
  return (
    <Group y={RULER_HEIGHT_IN_PX + rowIndex * ROW_HEIGHT_IN_PX}>
      {elements.map((element) => (
        <Element
          key={element.id}
          element={element}
          onElementChange={onElementChange}
          pixelsPerMillisecond={pixelsPerMillisecond}
          numberOfRows={numberOfRows}
        />
      ))}
    </Group>
  );
}
