import React from "react";

import { Box, Button, Switch, TableCell, Typography } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star"; // or any other icon you prefer

import { RoleName } from "../../../../common/enums/RoleName";
import { Roles } from "./Roles";
import { Column } from "../../../../common/Table/types";
import RoleBasedAccess from "../../../../common/RoleBasedAccess";

interface Props {
  isAuthenticatedUser: boolean;
  isOrganizationOwner: boolean;
  column: Column;
  value: string | boolean | RoleName[];
  handleCustomEvent: (event: string, value?: string) => void;
}

const cellBoxStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyItems: "center",
};

function UserCell({
  isAuthenticatedUser,
  isOrganizationOwner,
  column,
  value,
  handleCustomEvent,
}: Props): JSX.Element {
  switch (column.id) {
    case "email":
      return (
        <TableCell align={column.align}>
          <Typography
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {isOrganizationOwner && (
              <StarIcon
                titleAccess="Account owner"
                sx={{
                  color: "yellow",
                }}
              />
            )}
            {value}
          </Typography>
        </TableCell>
      );
    case "is_active":
      return (
        // Fall back to an empty cell in order to keep the table structure
        <RoleBasedAccess
          allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}
          fallback={<TableCell />}
        >
          <TableCell align={column.align}>
            <Switch
              disabled={isAuthenticatedUser || isOrganizationOwner}
              checked={!!value}
              title="Toggle is active"
              onChange={() => handleCustomEvent("set_is_active")}
            />
          </TableCell>
        </RoleBasedAccess>
      );
    case "roles":
      return (
        <TableCell align={column.align}>
          <Box sx={{ ...cellBoxStyle, justifyContent: "space-between" }}>
            <Roles roles={value as RoleName[]} />
            {!isAuthenticatedUser && !isOrganizationOwner && (
              <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
                <Button
                  onClick={() => handleCustomEvent("edit_roles")}
                  title="Edit Roles"
                  startIcon={<ManageAccountsIcon />}
                />
              </RoleBasedAccess>
            )}
          </Box>
        </TableCell>
      );
    case "delete":
      if (isAuthenticatedUser || isOrganizationOwner) {
        return <TableCell align={column.align} />;
      }
      return (
        <RoleBasedAccess
          allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}
          fallback={<TableCell />}
        >
          <TableCell align={column.align}>
            <Button
              title="Delete user"
              startIcon={<DeleteIcon />}
              onClick={() => handleCustomEvent("delete_user")}
            >
              Delete
            </Button>
          </TableCell>
        </RoleBasedAccess>
      );
    default:
      return (
        <TableCell align={column.align}>
          <Typography>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </Typography>
        </TableCell>
      );
  }
}

export default UserCell;
