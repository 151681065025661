import React from "react";

import { Line, Text, Circle } from "react-konva";

import { RULER_HEIGHT_IN_PX } from "../../constants";
import { formatMillisecondsToHHMMSSForRuler } from "../../../../../utils/formatMillisecondsToHHMMSSForRuler";

interface RulerProps {
  totalMilliseconds: number;
  pixelsPerMillisecond: number;
}

export default function Ruler({
  totalMilliseconds,
  pixelsPerMillisecond,
}: RulerProps) {
  const majorStepIntervals = [
    1000, 5000, 15000, 30000, 60000, 300000, 600000, 900000, 1800000, 3600000,
  ];

  // Find an appropriate major step size based on the zoom level (pixelsPerMillisecond)
  const majorStepSize =
    majorStepIntervals.find(
      (interval) => interval * pixelsPerMillisecond >= 50, // Ensure at least 50 pixels between major steps
    ) || majorStepIntervals[majorStepIntervals.length - 1];

  const majorSteps = [];
  for (let i = 0; i <= totalMilliseconds + majorStepSize; i += majorStepSize) {
    const x = i * pixelsPerMillisecond;
    const text = formatMillisecondsToHHMMSSForRuler(i);
    const textWidth = text.length * 6; // Approximate text width based on font size 12
    majorSteps.push(
      <React.Fragment key={i}>
        {i <= totalMilliseconds && (
          <Text
            x={x - textWidth / 2}
            y={25}
            text={text}
            fontSize={12}
            fill="black"
          />
        )}

        {/* Adding 5 evenly spaced dots between major steps */}
        {Array.from({ length: 5 }).map((_, dotIndex) => {
          const dotX =
            x + (dotIndex + 1) * (majorStepSize / 6) * pixelsPerMillisecond;
          return (
            dotX <= totalMilliseconds * pixelsPerMillisecond && (
              <Circle
                key={dotX}
                x={dotX}
                y={RULER_HEIGHT_IN_PX - 5}
                radius={2}
                fill="black"
              />
            )
          );
        })}
      </React.Fragment>,
    );
  }

  return (
    <>
      <Line
        points={[
          0,
          RULER_HEIGHT_IN_PX,
          totalMilliseconds * pixelsPerMillisecond,
          RULER_HEIGHT_IN_PX,
        ]}
        stroke="black"
      />
      {majorSteps}
    </>
  );
}
