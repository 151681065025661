import React from "react";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Box, Button, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import ButtonView from "../../../common/Button";
import { Column } from "../../../common/Table/types";
import { Data } from "../../types";
import TableView from "../../../common/Table";
import { projectsService } from "../../services";
import ProjectRow from "./parts/ProjectRow";
import { formatDateAndTime } from "../../../../utils/formatDate";
import RoleBasedAccess from "../../../common/RoleBasedAccess";
import { RoleName } from "../../../common/enums/RoleName";

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "description", label: "Desciption", minWidth: 100 },
  { id: "created_at", label: "Created At", minWidth: 100 },
  { id: "number_of_episodes", label: "Number Of Episodes", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 10 },
];

function createData(
  id: string,
  name: string,
  description: string,
  created_at: string,
  number_of_episodes: number,
): Data {
  return { id, name, description, created_at, number_of_episodes };
}

interface Props {
  handleProjectCreateDialog: () => void;
}

export default function ProjectsList({ handleProjectCreateDialog }: Props) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data, isLoading, isPending, refetch } = useQuery({
    queryKey: ["projects", page, rowsPerPage],
    queryFn: () =>
      projectsService.fetchProjects({
        page,
        perPage: rowsPerPage,
      }),
  });

  const rows =
    data?.projects?.map((project) => {
      return createData(
        project.id,
        project.name,
        project.description,
        formatDateAndTime(project.created_at),
        project.metadata.number_of_episodes,
      );
    }) || [];

  // const rows: any[] = [];
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage === 0) {
      setPage(newPage + 1);
    }

    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: "0px 0px 24px 0px",
        }}
      >
        <Box>
          <Button type="submit" variant="outlined" onClick={() => refetch()}>
            <RefreshIcon />
          </Button>
        </Box>
        <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
          <Box sx={{ alignSelf: "end", pl: 4 }}>
            <ButtonView
              type="button"
              variant="contained"
              size="small"
              label="Add"
              startIcon={<AddOutlinedIcon />}
              handleOnClick={handleProjectCreateDialog}
            />
          </Box>
        </RoleBasedAccess>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {isPending || isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 10 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: 800 }} />
            <TableView
              columns={columns}
              rows={rows}
              handleClick={() => {}}
              TableList={ProjectRow}
              handleCustomEvent={() => {}}
            />
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data?.pagination.total_items || 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </>
        )}
      </Paper>
    </>
  );
}
