import React from "react";

import { TableRow } from "@mui/material";

import { Column } from "../../../../common/Table/types";
import UserCell from "./UserCell";
import { useUserState } from "../../../../../store";

interface Props {
  columns: Column[];
  row: Record<string, any>;
  handleCustomEvent: (event: string, value?: string) => void;
}

export default function UserRow({ columns, row, handleCustomEvent }: Props) {
  const { user } = useUserState();
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map((column) => (
        <UserCell
          isAuthenticatedUser={user?.id === row.id}
          isOrganizationOwner={user?.organization?.owner_id === row.id}
          key={column.id}
          column={column}
          value={row[column.id]}
          handleCustomEvent={(event) => handleCustomEvent(event, row.id)}
        />
      ))}
    </TableRow>
  );
}
