import React, { useEffect, useState } from "react";

import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";

import { useAuth } from "./AuthContext";
import { CookieKey, getCookie, setCookie } from "../../hooks/useCookie";
import { loginService } from "../../modules/Login/services";
import { SigninResponse } from "../../modules/Login/services/login.service";

interface Props {
  children: JSX.Element;
}

function PrivateRoute({ children }: Props) {
  const { authenticated, login } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (
        !authenticated &&
        !["/login", "/register"].includes(location.pathname)
      ) {
        const refreshToken = getCookie(CookieKey.REFRESH_TOKEN);
        if (refreshToken) {
          const response: SigninResponse =
            await loginService.refreshToken(refreshToken);
          setCookie(CookieKey.JWT_TOKEN, response.jwt_token, {
            expires: moment()
              .add(response.jwt_token_expires_in, "milliseconds")
              .toDate()
              .toUTCString(),
          });
          setCookie(CookieKey.REFRESH_TOKEN, response.refresh_token, {
            expires: moment()
              .add(response.refresh_token_expires_in, "milliseconds")
              .toDate()
              .toUTCString(),
          });
          login();
        }
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, [authenticated, login, location.pathname]);

  if (isLoading) {
    return <div>Loading...</div>; // Or your preferred loading indicator
  }

  if (!authenticated && !["/login", "/register"].includes(location.pathname)) {
    return <Navigate to="/login" />;
  }

  if (authenticated && ["/login", "/register"].includes(location.pathname)) {
    return <Navigate to="/episodes" />;
  }

  return children;
}

export default PrivateRoute;
