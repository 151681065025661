import React, { useRef, forwardRef, useImperativeHandle, Ref } from "react";

import { Box } from "@mui/material";
import ReactPlayer from "react-player";

import { VideoPlayerState } from "../types/VideoPlayerState";

interface VideoPlayerProps {
  src: string;
  videoPlayerState: VideoPlayerState;
  onUpdatePlayerState: (state: VideoPlayerState) => void;
}

export interface VideoPreviewPlayerHandle {
  seekTo: (time: number) => void;
}

const VideoPreviewPlayer = forwardRef<
  VideoPreviewPlayerHandle,
  VideoPlayerProps
>(
  (
    { src, videoPlayerState, onUpdatePlayerState }: VideoPlayerProps,
    ref: Ref<VideoPreviewPlayerHandle>,
  ) => {
    const playerRef = useRef<ReactPlayer>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { isPlaying } = videoPlayerState;

    const togglePlay = (): void => {
      onUpdatePlayerState({
        ...videoPlayerState,
        isPlaying: !isPlaying,
      });
    };

    const handleProgress = ({
      playedSeconds,
    }: {
      playedSeconds: number;
    }): void => {
      onUpdatePlayerState({
        ...videoPlayerState,
        currentTime: playedSeconds,
      });
    };

    // Expose the seekTo method to the parent component
    useImperativeHandle(ref, () => ({
      seekTo: (time: number): void => {
        if (playerRef.current) {
          playerRef.current.seekTo(time);
        }
      },
    }));

    return (
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          bgcolor: "common.black",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            aspectRatio: "16/9",
            position: "relative",
          }}
        >
          <ReactPlayer
            ref={playerRef}
            url={src}
            playing={isPlaying}
            onProgress={handleProgress}
            width="100%"
            height="100%"
            onClick={togglePlay}
            controls
          />
        </Box>
      </Box>
    );
  },
);

export default VideoPreviewPlayer;
