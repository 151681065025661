import React from "react";
import "./style.css";

import { Box, Button, Popover, Typography } from "@mui/material";

import { LetterCase, SubtitleStyles } from "../../../../../../types";

interface LetterCasingProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function LetterCasing({
  styles,
  onUpdateStyles,
}: LetterCasingProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const letterCaseOptions = React.useMemo(
    () => [
      { value: LetterCase.DEFAULT, display: "Default" },
      { value: LetterCase.CAPITALIZE, display: "Ab" },
      { value: LetterCase.UPPERCASE, display: "AB" },
      { value: LetterCase.LOWERCASE, display: "ab" },
    ],
    [],
  );

  const handleUpdateStyles = React.useCallback(
    (letterCase: LetterCase) => {
      onUpdateStyles({
        ...styles,
        letter_case: letterCase,
      });
    },
    [styles, onUpdateStyles],
  );

  const open = Boolean(anchorEl);
  const id = open ? "letter-case-popover" : undefined;

  return (
    <Box
      sx={{
        border: "1px solid #0000003b",
        borderRadius: "12px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        type="button"
        className="letter-casing-button"
        onClick={handleClick}
        aria-describedby={id}
        aria-label="Select Letter Casing"
      >
        <span>Aa</span>
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: "-10px",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle1">Letter Casing</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            {letterCaseOptions.map((option) => (
              <Button
                sx={{
                  textTransform: "none",
                }}
                key={option.value}
                // variant={selectedStyleType === "text" ? "contained" : "outlined"}
                variant={
                  styles.letter_case === option.value ? "contained" : "outlined"
                }
                onClick={() => handleUpdateStyles(option.value)}
              >
                {option.display}
              </Button>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
