import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function PaymentSuccess() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        padding: "30px 0px 10px 0px",
      }}
    >
      <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
        Your payment was successful! You will be redirected to your account page
        in a few seconds.
      </Typography>
    </Box>
  );
}
