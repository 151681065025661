import React, { useCallback, useState } from "react";

import {
  Box,
  Container,
  Input,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { formatEpisodeLengthToHumanFriendlyDuration } from "../../../../utils/formatEpisodeLengthToHumanFriendlyDuration";

interface UploadProps {
  setFiles: (files: File[]) => void;
  files: File[];
}

export default function Upload({ setFiles, files }: UploadProps) {
  const [fileDurationInSeconds, setFileDurationInSeconds] = useState<number>(0);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      // Create a media element to calculate the duration
      const mediaElement = file.type.startsWith("audio")
        ? new Audio(URL.createObjectURL(file))
        : document.createElement("video");

      mediaElement.src = URL.createObjectURL(file);

      mediaElement.addEventListener("loadedmetadata", () => {
        setFileDurationInSeconds(mediaElement.duration);
        setFiles(
          acceptedFiles.map((currentFile) =>
            Object.assign(currentFile, {
              duration_in_seconds: mediaElement.duration,
              preview: URL.createObjectURL(currentFile),
            }),
          ),
        );
      });
    },
    [setFiles],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "video/*": [],
      "audio/*": [],
    },
  });

  const filesList = files.map((file: Record<string, any>) => (
    <ListItem key={file.path}>
      <CheckCircleIcon sx={{ color: "#22bb33" }} /> {file.path}
    </ListItem>
  ));

  return (
    <Container
      sx={{
        marginTop: "64px",
      }}
    >
      {!files.length ? (
        <Box
          sx={{
            border: "1px dashed #646e73",
            padding: 10,
            textAlign: "center",
            cursor: "pointer",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <Input inputProps={{ ...getInputProps() }} />
          <Typography
            variant="body2"
            sx={{
              paddingBottom: "18px",
              color: "#646e73",
            }}
          >
            Drag 'n' drop some files here, or click to select files
          </Typography>
          <CloudUploadIcon sx={{ fontSize: "48px", color: "#0288d1" }} />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <List>{filesList}</List>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {fileDurationInSeconds && (
              <Typography variant="body1" sx={{ color: "#22bb33" }}>
                File duration:{" "}
                {formatEpisodeLengthToHumanFriendlyDuration(
                  fileDurationInSeconds,
                )}
              </Typography>
            )}
          </Box>
          {fileRejections.length
            ? fileRejections[0].errors[0].code === "too-many-files" && (
                <Box sx={{ display: "flex" }}>
                  <ErrorOutlineIcon sx={{ color: "#F0AD4E" }} />
                  <Typography
                    variant="body1"
                    sx={{ color: "#F0AD4E", marginLeft: "10px" }}
                  >
                    You can upload only one file!
                  </Typography>
                </Box>
              )
            : null}
        </>
      )}
    </Container>
  );
}
