import React from "react";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Box, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMutation, useQuery } from "@tanstack/react-query";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import UserRow from "./parts/UserRow";
import {
  OrganizationUser,
  SetUserActivePayload,
} from "../../services/user-management.service";
import { userManagementService } from "../../services";
import { Column } from "../../../common/Table/types";
import TableView from "../../../common/Table";
import { formatDateAndTime } from "../../../../utils/formatDate";
import { RoleName } from "../../../common/enums/RoleName";
import ConfirmUserDeleteDialog from "../ConfirmUserDeleteDialog";
import ManageRolesDialog from "../ManageRolesDialog";
import ButtonView from "../../../common/Button";
import RoleBasedAccess from "../../../common/RoleBasedAccess";

const columns: Column[] = [
  { id: "email", label: "Email", minWidth: 100 },
  { id: "first_name", label: "First Name", minWidth: 100 },
  { id: "last_name", label: "Last Name", minWidth: 100 },
  { id: "is_active", label: "Is Active", minWidth: 100 },
  { id: "roles", label: "Roles", minWidth: 100 },
  { id: "created_at", label: "Created At", minWidth: 100 },
  { id: "delete", label: "Delete", minWidth: 100 },
];

function createData(
  id: string,
  email: string,
  first_name: string,
  last_name: string,
  is_active: boolean,
  created_at: string,
  roles: RoleName[],
): any {
  return {
    id,
    email,
    first_name,
    last_name,
    is_active,
    created_at,
    roles,
  };
}

interface Props {
  handleOpenAddUserDialog: () => void;
}

export default function UsersList({ handleOpenAddUserDialog }: Props) {
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [
    showConfirmUserDeleteDialogForUser,
    setShowConfirmUserDeleteDialogForUser,
  ] = React.useState<OrganizationUser | null>(null);
  const [showManageRolesDialogForUser, setShowManageRolesDialogForUser] =
    React.useState<OrganizationUser | null>(null);

  const { data, refetch } = useQuery({
    queryKey: ["User Management", page, rowsPerPage],
    queryFn: () =>
      userManagementService.fetchOrganizationUsers({
        page,
        perPage: rowsPerPage,
      }),
  });

  const { mutateAsync } = useMutation({
    mutationKey: ["set-is-user-active"],
    mutationFn: (payload: SetUserActivePayload) =>
      userManagementService.setIsUserActive(payload),
  });

  const handleSetIsUserActive = async (payload: SetUserActivePayload) => {
    await mutateAsync(payload);
  };

  const { mutateAsync: deleteUserMutateAsync } = useMutation({
    mutationKey: ["delete-user"],
    mutationFn: (userId: string) => userManagementService.deleteUser(userId),
  });

  const handleDeleteUser = async (userId: string) => {
    setShowConfirmUserDeleteDialogForUser(null);
    await deleteUserMutateAsync(userId);
    refetch();
  };

  const rows =
    data?.users?.map((user: OrganizationUser) => {
      return createData(
        user.id,
        user.email,
        user.first_name,
        user.last_name,
        user.is_active,
        formatDateAndTime(user.created_at),
        user.roles,
      );
    }) || [];

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage === 0) {
      setPage(newPage + 1);
    }

    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  return (
    <>
      {showConfirmUserDeleteDialogForUser && (
        <ConfirmUserDeleteDialog
          user={showConfirmUserDeleteDialogForUser}
          open={!!showConfirmUserDeleteDialogForUser}
          handleClose={() => setShowConfirmUserDeleteDialogForUser(null)}
          handleConfirm={() =>
            handleDeleteUser(showConfirmUserDeleteDialogForUser.id)
          }
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: "0px 0px 24px 0px",
        }}
      >
        <Box>
          <Button type="submit" variant="outlined" onClick={() => refetch()}>
            <RefreshIcon />
          </Button>
        </Box>
        <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
          <Box sx={{ alignSelf: "end", pl: 4 }}>
            <ButtonView
              type="button"
              variant="contained"
              size="small"
              label="Add"
              startIcon={<AddOutlinedIcon />}
              handleOnClick={handleOpenAddUserDialog}
            />
          </Box>
        </RoleBasedAccess>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 800 }} />
        <TableView
          columns={columns}
          rows={rows}
          handleCustomEvent={async (
            event: string,
            value?: string,
          ): Promise<void> => {
            switch (event) {
              case "set_is_active": {
                const selectedUser = data?.users?.find(
                  (user: OrganizationUser) => user.id === value,
                );
                if (selectedUser) {
                  await handleSetIsUserActive({
                    id: selectedUser.id,
                    isActive: !selectedUser?.is_active,
                  });
                  refetch();
                }
                break;
              }
              case "edit_roles": {
                const selectedUser = data?.users?.find(
                  (user: OrganizationUser) => user.id === value,
                );
                if (selectedUser) {
                  setShowManageRolesDialogForUser(selectedUser);
                }
                break;
              }
              case "delete_user": {
                const selectedUser = data?.users?.find(
                  (user: OrganizationUser) => user.id === value,
                );
                if (selectedUser) {
                  setShowConfirmUserDeleteDialogForUser(selectedUser);
                }
                break;
              }
              default:
                break;
            }
          }}
          handleClick={() => {}}
          TableList={UserRow}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.pagination.total_items || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
      {!!showManageRolesDialogForUser && (
        <ManageRolesDialog
          open={!!showManageRolesDialogForUser}
          handleClose={() => setShowManageRolesDialogForUser(null)}
          handleRoleUpdate={refetch}
          user={showManageRolesDialogForUser}
        />
      )}
    </>
  );
}
