import {
  fetchInsights,
  updateInsights,
  getEpisodeById,
  regenerateInsights,
  exportSrtByEpisodeId,
} from "./episode.service";

export const episodeService = {
  fetchInsights,
  updateInsights,
  getEpisodeById,
  regenerateInsights,
  exportSrtByEpisodeId,
};
