import {
  fetchSharedEpisodes,
  revokeSharedEpisode,
  updateSharedEpisode,
  shareEpisode,
  getEpisodesSharedWithMe,
  getEpisodesSharedWithMeByEpisodeId,
  getInsightsForEpisodeSharedWithMeByEpisodeId,
} from "./shared-episodes.service";

export const sharedEpisodeService = {
  fetchSharedEpisodes,
  revokeSharedEpisode,
  updateSharedEpisode,
  shareEpisode,
  getEpisodesSharedWithMe,
  getEpisodesSharedWithMeByEpisodeId,
  getInsightsForEpisodeSharedWithMeByEpisodeId,
};
