import React from "react";

import { TableCell, Typography } from "@mui/material";

import { RoleName } from "../../../../common/enums/RoleName";
import { Column } from "../../../../common/Table/types";

interface Props {
  column: Column;
  value: string | boolean | RoleName[];
}

function EpisodeSharedWithMeCell({
  column,
  value,
  // handleCustomEvent,
}: Props): JSX.Element {
  switch (column.id) {
    default:
      return (
        <TableCell align={column.align}>
          <Typography>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </Typography>
        </TableCell>
      );
  }
}

export default EpisodeSharedWithMeCell;
