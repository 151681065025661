import React from "react";

import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";

import EpisodeSharedWithMeView from "../modules/EpisodesSharedWithMe/EpisodeSharedWithMeView";

export default function EpisodesSharedWithMe() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <EpisodeSharedWithMeView />
    </Box>
  );
}
