import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router";

import Notify from "./modules/common/Notify";
import GlobalErrorAlert from "./modules/GlobalErrorAlert";
import { UserContextProvider } from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <div className="App">
          <header className="App-header" />
          <main>
            <Notify />
            <Outlet />
          </main>
          <GlobalErrorAlert />
        </div>
      </UserContextProvider>
    </QueryClientProvider>
  );
}

export default App;
