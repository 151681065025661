import React from "react";

import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";

import AccountView from "../modules/Account";

export default function Account() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AccountView />
    </Box>
  );
}
