import React from "react";

import { Box, Tabs, Tab, styled } from "@mui/material";
import { Contrast, FormatColorText } from "@mui/icons-material";

export enum StyleType {
  TEXT = 0,
  EFFECT = 1,
}

interface StyleSelectorButtonsProps {
  selectedStyleType: StyleType;
  onStyleTypeChange: (styleType: StyleType) => void;
}

const StyledTab = styled(Tab)(
  () => `
  font-size: 10px;
  min-height: 42px;
  padding: 0px 20px;
`,
);

export default function StyleSelectorButtons({
  selectedStyleType,
  onStyleTypeChange,
}: StyleSelectorButtonsProps) {
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    onStyleTypeChange(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Tabs value={selectedStyleType} onChange={handleTabChange}>
        <StyledTab
          label="Text"
          icon={<FormatColorText />}
          iconPosition="start"
          onClick={() => onStyleTypeChange(StyleType.TEXT)}
        />
        <StyledTab
          label="Effect"
          icon={<Contrast />}
          iconPosition="start"
          onClick={() => onStyleTypeChange(StyleType.EFFECT)}
        />
      </Tabs>
    </Box>
  );
}
