import { coreService } from "../../../services";
import { Pagination } from "../../common/types/Pagination";
import { PaginationQueryRequest } from "../../common/types/PaginationQueryRequest";
import { User } from "../../common/types/User";
import { Role } from "../../common/types/Role";
import { RoleName } from "../../common/enums/RoleName";
import {
  Episode,
  InsightsResponse,
} from "../../Episode/services/episode.service";

export interface SharedEpisode {
  id: string;
  episode: Episode;
  shared_by_user: User;
  shared_with: string;
  role: Role;
  created_at: string;
  expires_at?: string;
}

interface GetSharedEpisodesResponse {
  episodes: SharedEpisode[];
  pagination: Pagination;
}

export interface UpdateSharedEpisodeRequest {
  id: string;
  role: RoleName;
  expires_at?: string;
}

export interface ShareEpisodePayload {
  episode_id: string;
  role: RoleName;
  email: string;
  expires_at?: string;
}

const fetchSharedEpisodes = async ({
  perPage = 20,
  page = 1,
}: PaginationQueryRequest): Promise<GetSharedEpisodesResponse> => {
  const response = await coreService.httpClient.get(
    `/shared-episodes?page=${page}&per_page=${perPage}`,
  );
  return response.data;
};

const revokeSharedEpisode = async (id: string): Promise<void> => {
  await coreService.httpClient.del(`/shared-episodes/${id}`);
};

const updateSharedEpisode = async (
  data: UpdateSharedEpisodeRequest,
): Promise<void> => {
  await coreService.httpClient.patch(`/shared-episodes/${data.id}`, {
    role: data.role,
    expires_at: data.expires_at,
  });
};

const shareEpisode = async (data: ShareEpisodePayload): Promise<void> => {
  await coreService.httpClient.post("/shared-episodes", data);
};

// Episodes Shared with Me

const getEpisodesSharedWithMe = async ({
  perPage = 20,
  page = 1,
}: PaginationQueryRequest): Promise<GetSharedEpisodesResponse> => {
  const response = await coreService.httpClient.get(
    `/shared-episodes/shared-with-user?page=${page}&per_page=${perPage}`,
  );
  return response.data;
};

const getEpisodesSharedWithMeByEpisodeId = async (
  episodeId?: string,
): Promise<Episode | undefined> => {
  if (!episodeId) return undefined;
  const response = await coreService.httpClient.get(
    `/shared-episodes/shared-with-user/${episodeId}`,
  );
  return response.data;
};

const getInsightsForEpisodeSharedWithMeByEpisodeId = async (
  episodeId?: string,
): Promise<InsightsResponse | undefined> => {
  if (!episodeId) return undefined;
  const response = await coreService.httpClient.get(
    `/shared-episodes/shared-with-user/${episodeId}/insights`,
  );
  return response.data;
};

export {
  fetchSharedEpisodes,
  revokeSharedEpisode,
  updateSharedEpisode,
  shareEpisode,
  getEpisodesSharedWithMe,
  getEpisodesSharedWithMeByEpisodeId,
  getInsightsForEpisodeSharedWithMeByEpisodeId,
};
