import { coreService } from "../../../services";
import { Pagination } from "../../common/types/Pagination";
import { EpisodeStatus } from "../../Episode/enums/EpisodeStatus";

interface EpisodesPayload {
  projectId: string;
  page?: number;
  perPage?: number;
}

interface Project {
  id: string;
  name: string;
  created_at: string;
}

interface Upload {
  id: string;
  key: string;
  url: string;
  mime_type: string;
  size: string;
  created_at: string;
}

interface Episodes {
  id: string;
  name: string;
  created_at: string;
  project: Project;
  upload: Upload;
  status: EpisodeStatus;
}

interface EpisodesResponse {
  episodes: Episodes[];
  pagination: Pagination;
}

const fetchEpisodes = async ({
  perPage = 20,
  page = 1,
  projectId,
}: EpisodesPayload): Promise<EpisodesResponse | null> => {
  let url = `/episodes?page=${page}&per_page=${perPage}`;
  if (projectId && projectId.length) {
    url = `${url}&project_id=${projectId}`;
  }
  const response = await coreService.httpClient.get(url);
  return response.data;
};

export { fetchEpisodes };
