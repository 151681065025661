export const formatEpisodeLengthToHumanFriendlyDuration = (
  seconds: number,
): string => {
  const roundedSeconds = Math.floor(seconds);

  if (roundedSeconds < 0) {
    return "0 second(s)";
  }
  if (roundedSeconds < 60) {
    return `${roundedSeconds} second(s)`;
  }
  if (roundedSeconds < 3600) {
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;
    return `${minutes} minute(s) ${remainingSeconds > 0 ? `${remainingSeconds} second(s)` : ""}`.trim();
  }
  const hours = Math.floor(roundedSeconds / 3600);
  const minutes = Math.floor((roundedSeconds % 3600) / 60);
  const remainingSeconds = roundedSeconds % 60;
  return `${hours} hour(s) ${minutes > 0 ? `${minutes} minute(s)` : ""} ${remainingSeconds > 0 ? `${remainingSeconds} second(s)` : ""}`.trim();
};
