import React from "react";

import { Box, Card, Stack, Typography } from "@mui/material";

import EditSharedEpisodeDialog from "./components/EditSharedEpisodeDialog";
import { SharedEpisode } from "./services/shared-episodes.service";
import ListOfSharedEpisodes from "./components/ListOfSharedEpisodes";
import SidebarView from "../common/Sidebar";

export default function SharedEpisodesView() {
  const [
    showEditSharedEpisodeDialogForSharedEpisode,
    setShowEditSharedEpisodeDialogForSharedEpisode,
  ] = React.useState<SharedEpisode | null>(null);

  const handleOpenEditSharedEpisodeDialog = (sharedEpisode: SharedEpisode) => {
    setShowEditSharedEpisodeDialogForSharedEpisode(sharedEpisode);
  };

  const handleCloseEditSharedEpisodeDialog = () => {
    setShowEditSharedEpisodeDialogForSharedEpisode(null);
  };

  return (
    <>
      {!!showEditSharedEpisodeDialogForSharedEpisode && (
        <EditSharedEpisodeDialog
          open={!!EditSharedEpisodeDialog}
          handleClose={handleCloseEditSharedEpisodeDialog}
          sharedEpisode={showEditSharedEpisodeDialogForSharedEpisode}
        />
      )}
      <SidebarView pageName="Shared Episodes" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <Typography variant="h5" component="h1">
            List of Shared Episodes
          </Typography>
          <ListOfSharedEpisodes
            handleOpenEditSharedEpisodeDialog={
              handleOpenEditSharedEpisodeDialog
            }
          />
        </Card>
      </Box>
    </>
  );
}
