import { coreService } from "../../../services";
import { SigninResponse } from "../../Login/services/login.service";

const loginCallback = async (queryString: string): Promise<SigninResponse> => {
  const response = await coreService.httpClient.post(
    `/auth/signin/google/callback${queryString}`,
    {},
  );
  return response.data;
};
export { loginCallback };
