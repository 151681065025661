import React, { useRef, useEffect } from "react";

import { Form } from "react-router-dom";
import { Formik } from "formik";
import { Box, Typography } from "@mui/material";

import InputField from "../InputField";
import { Paragraph } from "../../../../types";

interface SubtitleModeSwitcherProps {
  paragraphs: Paragraph[];
  onUpdateParagraph: (paragraph: Paragraph) => void;
  currentTimeInMilliseconds: number;
  onParagraphClick: (paragraph: Paragraph) => void;
}

export default function SubtitlesEditor({
  paragraphs,
  onUpdateParagraph,
  currentTimeInMilliseconds,
  onParagraphClick,
}: SubtitleModeSwitcherProps) {
  const inputRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleUpdateText = (text: string, paragraphStartsAt: string) => {
    const paragraph = paragraphs.find(
      (p) => p.start === Number(paragraphStartsAt),
    );
    if (paragraph) {
      onUpdateParagraph({
        ...paragraph,
        text,
      });
    }
  };

  const currentTimeInSeconds = currentTimeInMilliseconds / 1000;

  const currentParagraph = paragraphs.find(
    (p) => p.start <= currentTimeInSeconds && currentTimeInSeconds <= p.end,
  );

  useEffect(() => {
    if (currentParagraph) {
      const key = currentParagraph.start.toString();
      const currentElement = inputRefs.current[key];
      if (currentElement) {
        currentElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [currentParagraph]);

  return (
    <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Edit Transcription
      </Typography>
      <Formik
        enableReinitialize
        initialValues={paragraphs?.reduce((acc, paragraph) => {
          return {
            ...acc,
            [paragraph.start]: paragraph?.text,
          };
        }, {})}
        onSubmit={() => {}}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {Object.keys(values)?.map((key) => {
              const paragraph = paragraphs.find((p) => p.start === Number(key));
              const isCurrentParagraph =
                paragraph &&
                paragraph.start <= currentTimeInSeconds &&
                currentTimeInSeconds <= paragraph.end;

              return (
                <InputField
                  key={key}
                  name={key}
                  values={values}
                  onUpdateText={(text: string) => {
                    handleUpdateText(text, key);
                  }}
                  isCurrentParagraph={!!isCurrentParagraph}
                  ref={(el) => {
                    inputRefs.current[key] = el;
                  }}
                  onParagraphClick={() => {
                    if (paragraph) {
                      onParagraphClick(paragraph);
                    }
                  }}
                />
              );
            })}
          </Form>
        )}
      </Formik>
    </Box>
  );
}
