import { useState } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";

import { config } from "../config";

const envConfig = config();

export enum CookieKey {
  JWT_TOKEN = "jwt_token",
  REFRESH_TOKEN = "refresh_token",
}
export interface CookieOptions {
  path?: string;
  expires?: Date | "Session" | string;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
  encode?: (value: string) => string;
}

export function stringifyOptions(options: any): string {
  return Object.keys(options).reduce((acc: string, key: string) => {
    if (options[key] === false) {
      return acc;
    }
    if (options[key] === true) {
      return `${acc}; ${key}`;
    }
    return `${acc}; ${key}=${options[key]}`;
  }, "");
}

export const getCookie = (name: CookieKey) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const setCookie = <T>(
  key: CookieKey,
  value: T,
  options: CookieOptions = {},
): void => {
  const defaultExpiresIn = moment().add("30", "minutes");
  document.cookie = `${key}=${value}${stringifyOptions({
    expires: defaultExpiresIn,
    path: "/",
    secure: envConfig.currentEnv !== "dev",
    sameSite: "strict",
    ...options,
  })}`;
};

export const deleteCookie = (name: CookieKey) => {
  document.cookie = `${name}=;${stringifyOptions({
    expires: "Thu, 01 Jan 1970 00:00:01 GMT;`",
    path: "/",
    secure: envConfig.currentEnv !== "dev",
    sameSite: "strict",
  })}`;
};

function useCookies<T>(
  key: CookieKey,
  initialValue: T,
  options: CookieOptions = {},
): readonly [T, (input: T) => void] {
  const [cookieValue, setCookieValue] = useState<T>((): T => {
    try {
      const item = getCookie(key);
      // @ts-ignore
      return item || initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    setCookieValue(value);
    setCookie(key, value, options);
  };

  return [cookieValue, setValue];
}

export default useCookies;
