import React from "react";

import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";

import EpisodesSharedWithMeDetailsView from "../modules/EpisodeSharedWithMeDetails/EpisodesSharedWithMeDetailsView";

export default function EpisodesSharedWithMeDetails() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <EpisodesSharedWithMeDetailsView />
    </Box>
  );
}
