import { coreService } from "../../../services";

export interface SetNewPasswordPayload {
  forgot_password_hash: string;
  password: string;
}

export interface ForgotPasswordHashResponse {
  is_valid: boolean;
}

const verifyForgotPasswordHash = async (
  hash: string,
): Promise<ForgotPasswordHashResponse> => {
  const response = await coreService.httpClient.get(
    `/auth/forgot-password/${hash}`,
  );
  return response.data;
};

const setNewPassword = async (
  payload: SetNewPasswordPayload,
): Promise<void> => {
  const response = await coreService.httpClient.post(
    "/auth/set-new-password",
    payload,
  );
  return response.data;
};

export { verifyForgotPasswordHash, setNewPassword };
