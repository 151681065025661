export default function formatWord(string: string) {
  if (!string || typeof string !== "string") {
    throw new Error("Invalid input: Please provide a valid string.");
  }

  return string
    .replace(/-/g, " ") // Replace hyphens with spaces
    .split(" ") // Split into words by spaces
    .map((word) => {
      // Capitalize the first letter of each word
      let formatted =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

      // Handle simple plural-to-singular conversion
      if (formatted.endsWith("s") && formatted.length > 1) {
        formatted = formatted.slice(0, -1);
      }

      return formatted;
    })
    .join(" ");
}
