import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import QuoteModal, { QuoteObject } from "./parts/QuoteModal";
import { Chapter, Quote } from "../../Episode/services/episode.service";
import { toHHMMSS } from "../../../utils/formatTIme";
import TitleModal from "./parts/TitleModal";
import { Category } from "../types";
import ChapterModal from "./parts/ChapterModal";
import ContentModal from "./parts/ContentModal";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export enum ModalType {
  QUOTES = "quotes",
  TITLES = "titles",
  CHAPTERS = "chapters",
  BLOGPOST = "blog_posts",
  NEWSLETTER = "newsletter",
  KEYWORDS = "keywords",
  TWEETS = "tweets",
}

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  modalTitle: string;
  modalType: ModalType;
  objectValue: Record<string, unknown>;
  refetch: () => void;
  category: Category;
  values?: any;
}

function InsightsModalManager({
  open,
  setOpen,
  modalTitle,
  modalType,
  objectValue,
  values,
  refetch,
  category,
}: Props) {
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        {ModalType.QUOTES === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-quote">
            {`${modalTitle} - ${toHHMMSS(objectValue.timestamp as keyof QuoteObject)}`}
          </DialogTitle>
        )}

        {ModalType.TITLES === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {modalTitle}
          </DialogTitle>
        )}

        {ModalType.TWEETS === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Edit Tweet
          </DialogTitle>
        )}

        {ModalType.KEYWORDS === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Edit Keyword
          </DialogTitle>
        )}

        {ModalType.CHAPTERS === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-chapter">
            Edit Chapter
          </DialogTitle>
        )}

        {ModalType.BLOGPOST === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-blogPost">
            Edit Blog Post
          </DialogTitle>
        )}

        {ModalType.NEWSLETTER === modalType && (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-newsletter">
            Edit Newsletter
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {ModalType.QUOTES === modalType && (
          <QuoteModal
            category={Category.QUOTES}
            quoteObject={objectValue as QuoteObject}
            quotes={values as Quote[]}
            handleCloseModal={handleClose}
            refetch={refetch}
          />
        )}

        {ModalType.TITLES === modalType && (
          <TitleModal
            title={objectValue.title as keyof { title: string }}
            titles={values as string[]}
            handleCloseModal={handleClose}
            refetch={refetch}
            category={category}
          />
        )}

        {ModalType.CHAPTERS === modalType && (
          <ChapterModal
            chapter={objectValue.title as keyof { title: string }}
            chapters={values as Chapter[]}
            handleCloseModal={handleClose}
            refetch={refetch}
            category={category}
          />
        )}
        {(ModalType.BLOGPOST === modalType ||
          ModalType.NEWSLETTER === modalType) && (
          <ContentModal
            title={
              ModalType.BLOGPOST === modalType ? "Blog Post" : "Newsletter"
            }
            content={objectValue.title as keyof { title: string }}
            handleCloseModal={handleClose}
            refetch={refetch}
            category={category}
          />
        )}
      </BootstrapDialog>
    </Box>
  );
}

export default InsightsModalManager;
