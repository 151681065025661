import React from "react";

import Container from "@mui/material/Container";

import Footer from "../modules/common/Footer";
import GoogleLoginCallbackPageView from "../modules/GoogleLoginCallbackPage";

function GoogleLoginCallbackPage() {
  return (
    <Container component="main" maxWidth="xs">
      <GoogleLoginCallbackPageView />
      <Footer />
    </Container>
  );
}

export default GoogleLoginCallbackPage;
