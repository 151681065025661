import React from "react";

import { Box, Card, Stack, Typography } from "@mui/material";

import SidebarView from "../common/Sidebar";
import UsersList from "./components/UsersList";
import AddUserDialog from "./components/AddUserDialog";

export default function UsersView() {
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
  };

  return (
    <>
      {openAddUserDialog && (
        <AddUserDialog
          open={openAddUserDialog}
          handleClose={handleCloseAddUserDialog}
        />
      )}
      <SidebarView pageName="Users" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <Typography variant="h5" component="h1">
            Users List
          </Typography>
          <UsersList handleOpenAddUserDialog={handleOpenAddUserDialog} />
        </Card>
      </Box>
    </>
  );
}
