import React, { useEffect, useState } from "react";

import { Alert, Box, Card, LinearProgress, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { CheckCircleOutline } from "@mui/icons-material";

import SidebarView from "../common/Sidebar";
import StepperView from "./components/Stepper";
import ButtonView from "../common/Button";
import Upload from "./components/Upload";
import { StepsEnum } from "./types";
import AddEpisodeInfo from "./components/AddEpisodeInfo";
import SelectProject from "./components/SelectProject";
import { createEspisodeService } from "./services";
import PricingPlaneDialog from "../common/PricingPlansDialog";

interface PreSignedUrlPayload {
  formData: Record<string, any>;
  file: File;
}

export default function CreateEpisode() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    id: string;
  } | null>(null);
  const [isPlanLimitReached, setIsPlanLimitReached] = useState<boolean>(false);
  const [openPricingPlanDialog, setOpenPricingPlanDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleOpenPricingPlanDialog = () => {
    setOpenPricingPlanDialog(!openPricingPlanDialog);
  };

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const total = progressEvent.total || 1;
    const current = progressEvent.loaded;
    const percentCompleted = Math.round((current / total) * 100);
    setUploadProgress(percentCompleted);
  };

  const preSignedUrlMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: ({ formData, file }: PreSignedUrlPayload) =>
      createEspisodeService.getS3PreSignedUrl(formData, file, onUploadProgress),
    onError: (error: any) => {
      const statusCode = error?.statusCode;
      if (statusCode === 429) {
        setIsPlanLimitReached(true);
      }
    },
  });

  if (preSignedUrlMutation.isSuccess) {
    setTimeout(() => {
      window.location.href = "/episodes";
    }, 2500);
  }

  const handleUpload = () => {
    const formData = { name, project_id: selectedProject?.id };
    preSignedUrlMutation.mutateAsync({ formData, file: files[0] });
  };

  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEpisodeNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setName(event.target.value);

    if (!event.target.value) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const handleProject = (
    _event: React.SyntheticEvent,
    data: { label: string; id: string },
  ) => {
    setSelectedProject(data);
  };

  useEffect(() => {
    if (activeStep === StepsEnum.UPLOAD && files.length) {
      setNextButtonDisabled(false);
    } else if (activeStep === StepsEnum.EPISODE_INFO && name) {
      setNextButtonDisabled(false);
    } else if (activeStep === StepsEnum.SELECT_PROJECT && selectedProject) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [files, name, activeStep, selectedProject]);

  return (
    <>
      <SidebarView pageName="Episode Upload" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 2,
            py: 6,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          {preSignedUrlMutation.isSuccess && (
            <Alert
              sx={{ marginBottom: "40px" }}
              icon={<CheckCircleOutline fontSize="inherit" />}
              severity="success"
            >
              File has been successfully uploaded you will get a notification
              once the file is processed.
            </Alert>
          )}

          <StepperView activeStep={activeStep} />
          <Box sx={{ width: "60%", alignSelf: "center" }}>
            {activeStep === StepsEnum.UPLOAD && (
              <Upload setFiles={setFiles} files={files} />
            )}{" "}
            {activeStep === StepsEnum.EPISODE_INFO && (
              <Box>
                <AddEpisodeInfo
                  name={name}
                  error={nameError}
                  handleEpisodeNameChange={handleEpisodeNameChange}
                />
              </Box>
            )}
            {activeStep === StepsEnum.SELECT_PROJECT &&
              !preSignedUrlMutation.isPending &&
              !preSignedUrlMutation.isSuccess && (
                <Box>
                  <SelectProject
                    handleOnChange={handleProject}
                    isPlanLimitReached={isPlanLimitReached}
                    handleUpgradePlan={handleOpenPricingPlanDialog}
                  />
                </Box>
              )}
          </Box>
          {preSignedUrlMutation.isPending && (
            <Box sx={{ width: "60%", marginTop: 14, mx: "auto" }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Box sx={{ textAlign: "center", marginTop: 1 }}>
                {uploadProgress}%
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ marginTop: 10 }}>
              {activeStep > 0 && (
                <ButtonView
                  variant="outlined"
                  label="Previous"
                  type="button"
                  handleOnClick={handlePreviousStep}
                  disabled={preSignedUrlMutation.isPending}
                />
              )}
            </Box>
            <Box sx={{ marginTop: 10, position: "relative" }}>
              {activeStep !== StepsEnum.SELECT_PROJECT ? (
                <ButtonView
                  variant="outlined"
                  label="Next"
                  type="button"
                  handleOnClick={handleNextStep}
                  disabled={nextButtonDisabled}
                />
              ) : (
                <ButtonView
                  variant="contained"
                  color="success"
                  label="Upload"
                  type="button"
                  handleOnClick={handleUpload}
                  disabled={
                    nextButtonDisabled ||
                    preSignedUrlMutation.isPending ||
                    preSignedUrlMutation.isSuccess
                  }
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
      <PricingPlaneDialog
        handleOpenPricingPlanDialog={handleOpenPricingPlanDialog}
        open={openPricingPlanDialog}
      />
    </>
  );
}
