import React, { useEffect, useState, useCallback } from "react";

import { Box, Card, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import SidebarView from "../common/Sidebar";
import VideoPlayer from "./components/VideoPlayer";
import Subtitle from "./components/Subtitle";
import { editShortVideoService } from "./services";
import { Paragraph, SubtitleStyles } from "./types";
import Timeline from "../Editor/Timeline";
import { IElement } from "../Editor/Timeline/components/Element/types/IElement";
import { WebSocketEventName } from "../common/enums/WebSocketEventName";
import { useWebSocket } from "../../hooks/useWebSocket";

// Add new interface for video controls
interface VideoControls {
  play: () => void;
  pause: () => void;
  seekTo: (time: number) => void;
  getDuration: () => number;
}

export default function EditShortVideo() {
  const { videoId } = useParams();
  const { data: shortVideo } = useQuery({
    queryKey: ["getVideo", videoId],
    queryFn: () => editShortVideoService.getVideo(videoId),
  });

  const [elements, setElements] = useState<IElement[]>([]);
  const [currentTimeInMilliseconds, setCurrentTimeInMilliseconds] =
    useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [styles, setStyles] = useState<SubtitleStyles>(
    shortVideo?.subtitle_styles ?? {},
  );
  const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);

  // Add ref for video controls
  const [videoControlsRef, setVideoControlsRef] =
    useState<VideoControls | null>(null);

  const socket = useWebSocket();

  // Handle video control methods
  const handleTimeUpdate = useCallback((timeInMs: number) => {
    setCurrentTimeInMilliseconds(timeInMs);
  }, []);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      videoControlsRef?.pause();
    } else {
      videoControlsRef?.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, videoControlsRef]);

  const handleSeek = useCallback(
    (timeInMs: number) => {
      if (videoControlsRef) {
        videoControlsRef.seekTo(timeInMs / 1000); // Convert to seconds for seekTo
        setCurrentTimeInMilliseconds(timeInMs);
      }
    },
    [videoControlsRef],
  );

  // WebSocket effect for video export
  useEffect(() => {
    if (socket) {
      socket.on(WebSocketEventName.VIDEO_EXPORT_DONE, async (data) => {
        if (data?.exported_video_url) {
          const fileResponse = await fetch(data.exported_video_url);
          const blob = await fileResponse.blob();
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = `${data?.short_video?.title}.mp4`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off(WebSocketEventName.VIDEO_EXPORT_DONE);
      }
    };
  }, [socket]);

  // Initialize elements when video loads
  useEffect(() => {
    if (shortVideo) {
      setStyles(shortVideo.subtitle_styles);
      setParagraphs(shortVideo?.paragraphs ?? []);
      setElements([
        {
          id: 1,
          row: 1,
          start: 0,
          width: shortVideo.duration_in_milliseconds,
          isSelected: false,
          isHovered: false,
          isDragging: false,
          isResizing: false,
        },
      ]);
    }
  }, [shortVideo]);

  if (!shortVideo) {
    return <Typography>Loading...</Typography>;
  }

  const handleUpdateStyles = async (
    updatedStyles: SubtitleStyles,
  ): Promise<void> => {
    if (videoId) {
      await editShortVideoService.updateShortVideo(videoId, {
        subtitleStyles: updatedStyles,
      });
      setStyles(updatedStyles);
    }
  };

  const handleUpdateParagraph = async (paragraph: Paragraph): Promise<void> => {
    if (videoId) {
      setParagraphs(
        paragraphs.map((p) => (p.id === paragraph.id ? paragraph : p)),
      );
      await editShortVideoService.updateShortVideo(videoId, {
        paragraph,
      });
    }
  };

  if (!videoId) {
    return null;
  }

  return (
    <>
      <SidebarView
        pageName="Edit Short Video"
        expended={false}
        editFileSidebar
        hidePrimary
      />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 0,
            py: 6,
            borderRadius: 4,
            marginTop: 6,
            height: "100%",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                maxHeight: "calc(100% - 250px)",
                px: 2,
              }}
            >
              <Box sx={{ width: "30%", px: 2 }}>
                <Subtitle
                  styles={styles}
                  paragraphs={paragraphs}
                  onUpdateStyles={handleUpdateStyles}
                  onUpdateParagraph={handleUpdateParagraph}
                  currentTimeInMilliseconds={currentTimeInMilliseconds}
                  onParagraphClick={handleSeek}
                />
              </Box>
              <Box
                sx={{
                  width: "70%",
                  alignSelf: "start",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <VideoPlayer
                  videoId={videoId}
                  url={shortVideo?.url ?? ""}
                  paragraphs={paragraphs}
                  isPlaying={isPlaying}
                  toggleIsPlaying={handlePlayPause}
                  subtitleStyles={styles}
                  onTimeUpdate={handleTimeUpdate}
                  currentTimeInMilliseconds={currentTimeInMilliseconds}
                  onControlsReady={setVideoControlsRef}
                />
              </Box>
            </Box>
            <Box>
              <Timeline
                video={shortVideo}
                currentTimeInMilliseconds={currentTimeInMilliseconds}
                isPlaying={isPlaying}
                onPlayPause={handlePlayPause}
                setCurrentTimeInMilliseconds={handleSeek}
                elements={elements}
                setElements={setElements}
              />
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
}
