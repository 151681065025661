import React from "react";

import { Box, TextField } from "@mui/material";

interface Props {
  name: string;
  error: boolean;
  handleEpisodeNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AddEpisodeInfo({
  name,
  error,
  handleEpisodeNameChange,
}: Props) {
  return (
    <Box
      sx={{
        "& > :not(style)": { mt: 6 },
        margin: 6,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <TextField
        sx={{ maxWidth: "70%" }}
        id="episode-name"
        label="Enter Episode Name"
        value={name}
        fullWidth
        required
        error={error}
        inputProps={{ maxLength: 255 }}
        onChange={handleEpisodeNameChange}
      />
    </Box>
  );
}
