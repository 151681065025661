import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Button, TextField, Box, CircularProgress, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { userManagementService } from "../../Users/services";
import { ConfirmEmailAndSetPasswordPayload } from "../../Users/services/user-management.service";

interface Props {
  hash: string;
}

export default function SetUpAccountForm({ hash }: Props) {
  const navigate = useNavigate();
  const { mutateAsync, isPending, isError, error, isSuccess } = useMutation({
    mutationKey: ["setup-account"],
    mutationFn: (values: ConfirmEmailAndSetPasswordPayload) =>
      userManagementService.confirmEmailAndSetPassword(values),
    onSuccess() {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    },
  });

  const handleSubmit = async (values: any) => {
    await mutateAsync({
      ...values,
      hash,
    });
  };

  if (isPending) {
    return (
      <Box sx={{ display: "flex", marginTop: "64px" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{ first_name: "", last_name: "", password: "" }}
      validationSchema={Yup.object({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        password: Yup.string().min(8).required("Required"),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          {isError && (
            <Alert severity="error" sx={{ marginBottom: "12px" }}>
              {error?.message.toLowerCase() === "forbidden"
                ? "This link is invalid or has expired, please request a new one from your organization admin."
                : (error?.message ?? "An error occurred")}
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success" sx={{ marginBottom: "12px" }}>
              Your account has been successfully set up. Redirecting to login
              page...
            </Alert>
          )}
          <TextField
            margin="normal"
            fullWidth
            label="First Name"
            autoComplete="given-name"
            error={!!formik.errors.first_name}
            helperText={formik.errors.first_name}
            {...formik.getFieldProps("first_name")}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Last Name"
            autoComplete="family-name"
            error={!!formik.errors.last_name}
            helperText={formik.errors.last_name}
            {...formik.getFieldProps("last_name")}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            {...formik.getFieldProps("password")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Finish Setup
          </Button>
        </form>
      )}
    </Formik>
  );
}
