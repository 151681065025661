export const formatMillisecondsToHHMMSS = (ms: number): string => {
  const totalSeconds = ms / 1000;

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 60).toFixed(3); // Keep 3 decimal places for seconds

  const pad = (num: number): string => num.toString().padStart(2, "0");

  return `${pad(hours)}:${pad(minutes)}:${pad(parseInt(seconds, 10))}.${seconds.split(".")[1]}`;
};
