type AlertHandler = (message: string) => void;

class AlertService {
  private static alertHandler: AlertHandler | null = null;

  public static setAlertHandler(handler: AlertHandler): void {
    AlertService.alertHandler = handler;
  }

  public static showAlert(message: string): void {
    if (AlertService.alertHandler) {
      AlertService.alertHandler(message);
    }
  }
}

export default AlertService;
