import React from "react";

import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

import { RoleBadge } from "./RoleBadge";
import { RoleName } from "../../../../common/enums/RoleName";

const AdditionalRolesChip = styled(Chip)({
  padding: "2px 10px",
  borderRadius: "9999px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 8px 0 0",
});

interface Props {
  roles: RoleName[];
}

export function Roles({ roles }: Props) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {roles
        ?.slice(0, 1)
        .map((role: RoleName) => <RoleBadge roleName={role} key={role} />)}
      {roles.length > 1 && (
        <AdditionalRolesChip label={`${roles.length - 1}+`} />
      )}
    </div>
  );
}
