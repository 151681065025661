import React from "react";

import { useUserState } from "../../../store";
import { RoleName } from "../enums/RoleName";

interface Props {
  children: React.ReactElement<any, any>;
  allowedRoles: RoleName[];
  fallback?: React.ReactElement<any, any> | null;
}

export default function RoleBasedAccess({
  children,
  allowedRoles,
  fallback = null,
}: Props) {
  const { user } = useUserState();
  const hasAccess = () => {
    if (!user || !Array.isArray(user.roles) || !Array.isArray(allowedRoles)) {
      return false;
    }
    // Check if there's any intersection between user roles and allowed roles
    return user.roles.some((userRole) =>
      allowedRoles.some((allowedRole) => allowedRole === userRole),
    );
  };

  return hasAccess() ? children : fallback;
}
