import React from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogContentText } from "@mui/material";

import { OrganizationUser } from "../../services/user-management.service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  user: OrganizationUser;
  handleClose: () => void;
  handleConfirm: () => void;
  open: boolean;
}

export default function ConfirmUserDeleteDialog({
  user,
  handleClose,
  handleConfirm,
  open,
}: Props) {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle>Are you sure you want to delete this user?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This action cannot be undone and the user {user.email} will be
          permanently deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          sx={{
            backgroundColor: "#FF0000",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FF0000",
            },
          }}
          onClick={handleConfirm}
        >
          Delete
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
