import React, { useCallback } from "react";

import { Box, Card, Stack } from "@mui/material";

import SidebarView from "../common/Sidebar";
import ProjectsList from "./components/ProjectsList";
import CreateProjectDialog from "./components/CreateProjectsDialog";

export default function Project() {
  const [openCreateProjectDialog, setOpenCreateProjectDialog] =
    React.useState(false);

  // Handlers for opening and closing the dialog
  const openDialog = useCallback(() => setOpenCreateProjectDialog(true), []);
  const closeDialog = useCallback(() => setOpenCreateProjectDialog(false), []);
  return (
    <>
      <CreateProjectDialog
        open={openCreateProjectDialog}
        handleClose={closeDialog}
      />
      <SidebarView pageName="Projects" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 4,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <ProjectsList handleProjectCreateDialog={openDialog} />
        </Card>
      </Box>
    </>
  );
}
