import React from "react";

import { Box, styled, Tab, Tabs } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import EditNoteIcon from "@mui/icons-material/EditNote";

const StyledTab = styled(Tab)(
  () => `
  font-size: 12px;
  min-height: 48px;
  padding: 0px 20px;
`,
);

interface SubtitleModeSwitcherProps {
  selectedTab: number;
  onSelectTab: (tab: number) => void;
}

export default function SubtitleModeSwitcher({
  selectedTab,
  onSelectTab,
}: SubtitleModeSwitcherProps) {
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    onSelectTab(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        gap: 2,
      }}
    >
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <StyledTab
          label="Style"
          icon={<PaletteIcon />}
          iconPosition="start"
          onClick={() => onSelectTab(0)}
        />
        <StyledTab
          label="Edit Transcription"
          icon={<EditNoteIcon />}
          iconPosition="start"
          onClick={() => onSelectTab(1)}
        />
      </Tabs>
    </Box>
  );
}
