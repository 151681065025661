import React from "react";

import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import { Box, Typography, useMediaQuery } from "@mui/material";
import FlutterDashIcon from "@mui/icons-material/FlutterDash";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PeopleIcon from "@mui/icons-material/People";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import IosShareIcon from "@mui/icons-material/IosShare";
import SearchIcon from "@mui/icons-material/Search";
import Add from "@mui/icons-material/Add";
import Audiotrack from "@mui/icons-material/Audiotrack";
import FormatUnderlined from "@mui/icons-material/Subtitles";
import TextIcon from "@mui/icons-material/TextFields";
import SourceIcon from "@mui/icons-material/Source";
import RecordIcon from "@mui/icons-material/EmergencyRecording";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import FilterIcon from "@mui/icons-material/Tune";

import Headerbar from "../Headerbar";
import SidebarList from "./components/SidebarList";
import { SidebarListProps } from "./types";
import { SidebarContent } from "./components/SidebarContent";
import { RoleName } from "../enums/RoleName";
import RoleBasedAccess from "../RoleBasedAccess";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.grey[50],
    border: "none",
    position: "relative",
    whiteSpace: "nowrap",
    width: 260,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(0),
        position: "absolute",
        zIndex: "99",
      },
    }),
  },
}));

interface SidebarProps {
  pageName: string;
  expended?: boolean;
  editFileSidebar?: boolean;
  hidePrimary?: boolean;
}

export default function SidebarView({
  pageName,
  expended = true,
  editFileSidebar = false,
  hidePrimary = false,
}: SidebarProps) {
  const globalTheme = useTheme();
  const mobile = useMediaQuery(globalTheme.breakpoints.down("md"));
  const newExpendedState = mobile ? false : expended;
  const [open, setOpen] = React.useState(newExpendedState);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const sidebarList: SidebarListProps[] = [
    {
      icon: <PodcastsIcon />,
      name: "Episodes",
      link: "/episodes",
      isSelected: pageName === "Episodes",
    },
    {
      icon: <MovieCreationOutlinedIcon />,
      name: "Create Episode",
      link: "/episodes/create",
      isSelected: pageName === "Episode Upload",
    },
    {
      icon: <InventoryOutlinedIcon />,
      name: "Projects",
      link: "/projects",
      isSelected: pageName === "Projects",
    },
    {
      icon: <FolderSharedIcon />,
      name: "Shared Episodes",
      link: "/shared-episodes",
      disabled: true,
      isSelected: false,
      subMenu: [
        {
          icon: <IosShareIcon />,
          name: "Shared Episodes",
          link: "/shared-episodes",
          isSelected: pageName === "Shared Episodes",
        },
        {
          icon: <CoPresentIcon />,
          name: "Episodes Shared With Me",
          link: "/episodes-shared-with-me",
          isSelected: pageName === "Episodes Shared With Me",
        },
      ],
    },
  ];

  const sidebarList2 = [
    {
      icon: <SearchIcon sx={{ width: "100%" }} />,
      name: "Search",
      disabled: true,
    },
    {
      icon: <Add sx={{ width: "100%" }} />,
      name: "Media",
      disabled: true,
    },
    {
      icon: <Audiotrack sx={{ width: "100%" }} />,
      name: "Audio",
      disabled: true,
    },
    {
      icon: <FormatUnderlined sx={{ width: "100%" }} />,
      name: "Subtitles",
    },
    {
      icon: <TextIcon sx={{ width: "100%" }} />,
      name: "Text",
      disabled: true,
    },
    {
      icon: <SourceIcon sx={{ width: "100%" }} />,
      name: "Elements",
      disabled: true,
    },
    {
      icon: <RecordIcon sx={{ width: "100%" }} />,
      name: "Record",
      disabled: true,
    },
    {
      icon: <LinearScaleIcon sx={{ width: "100%" }} />,
      name: "Transitions",
      disabled: true,
    },
    {
      icon: <FilterIcon sx={{ width: "100%" }} />,
      name: "Filters",
      disabled: true,
    },
  ];

  const sidebarBottomList: SidebarListProps[] = [
    {
      icon: <PeopleIcon />,
      name: "Users",
      link: "/users",
      isSelected: pageName === "Users",
    },
  ];

  return (
    <>
      <Headerbar
        open={open}
        toggleDrawer={toggleDrawer}
        pageName={pageName}
        hideToggle={hidePrimary}
      />
      <Drawer
        hidden={hidePrimary}
        variant="permanent"
        open={open}
        sx={{
          position: "relative",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              px: 2,
            }}
          >
            <FlutterDashIcon sx={{ fontSize: "36px", ml: 1 }} />
            <Typography variant="body2" sx={{ m: 1, fontWeight: "bold" }}>
              Podcast Assistant
            </Typography>
          </Box>

          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <SidebarList sidebarList={sidebarList} />
        <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
          <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            <SidebarList sidebarList={sidebarBottomList} />
          </Box>
        </RoleBasedAccess>
      </Drawer>
      {editFileSidebar ? (
        <Box sx={{ position: "relative" }}>
          <SidebarContent sidebarContent={sidebarList2 as SidebarListProps[]} />
        </Box>
      ) : null}
    </>
  );
}
