import React from "react";

import { IconButton, Typography, Box } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import { formatMillisecondsToHHMMSS } from "../../../../../utils/formatMillisecondsToHHMMSS";

interface ControlsProps {
  isPlaying: boolean;
  currentTimestamp: number;
  durationInMilliseconds: number;
  onPlayPause: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export default function Controls({
  isPlaying,
  currentTimestamp,
  durationInMilliseconds,
  onPlayPause,
  onPrevious,
  onNext,
}: ControlsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {/* Previous Button */}
      <IconButton onClick={onPrevious}>
        <SkipPreviousIcon />
      </IconButton>

      <IconButton onClick={onPlayPause}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>

      <IconButton onClick={onNext}>
        <SkipNextIcon />
      </IconButton>

      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
        {formatMillisecondsToHHMMSS(currentTimestamp)} /{" "}
        {formatMillisecondsToHHMMSS(durationInMilliseconds)}
      </Typography>
    </Box>
  );
}
