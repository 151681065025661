import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatStrikethrough,
  FormatUnderlined,
} from "@mui/icons-material";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";

import LetterSpacing from "./LetterSpacing";
import LetterCasing from "./LetterCasing";
import { SubtitleStyles } from "../../../../../types";

interface TextStyleProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function TextStyle({ styles, onUpdateStyles }: TextStyleProps) {
  const [localStyles, setLocalStyles] = useState(styles);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const debouncedUpdateStyles = (updatedStyles: SubtitleStyles) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      onUpdateStyles(updatedStyles);
    }, 500);
    setDebounceTimeout(timeout);
  };

  const handleFontChange = (e: SelectChangeEvent<string>) => {
    const updatedStyles = { ...localStyles, font: e.target.value as string };
    setLocalStyles(updatedStyles);
    debouncedUpdateStyles(updatedStyles);
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedStyles = {
      ...localStyles,
      size: parseInt(e.target.value, 10),
    };
    setLocalStyles(updatedStyles);
    debouncedUpdateStyles(updatedStyles);
  };

  const handleColorChange = (color: string) => {
    const updatedStyles = { ...localStyles, color };
    setLocalStyles(updatedStyles);
    debouncedUpdateStyles(updatedStyles);
  };

  useEffect(() => {
    setLocalStyles(styles);
  }, [styles]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <Select
            title="Font"
            key="font"
            size="small"
            value={localStyles.font || ""}
            onChange={handleFontChange}
            displayEmpty
          >
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Helvetica">Helvetica</MenuItem>
            <MenuItem value="Roboto">Roboto</MenuItem>
            <MenuItem value="Open Sans">Open Sans</MenuItem>
            <MenuItem value="Lato">Lato</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
            <MenuItem value="Times">Times</MenuItem>
            <MenuItem value="Poppins">Poppins</MenuItem>
            <MenuItem value="Rubik">Rubik</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Arvo">Arvo</MenuItem>
            <MenuItem value="Quicksand">Quicksand</MenuItem>
            <MenuItem value="Montserrat">Montserrat</MenuItem>
            <MenuItem value="Lora">Lora</MenuItem>
            <MenuItem value="Cabin">Cabin</MenuItem>
            <MenuItem value="Josefin Sans">Josefin Sans</MenuItem>
            <MenuItem value="Tahoma">Tahoma</MenuItem>
          </Select>
        </FormControl>
        <TextField
          title="Font Size"
          size="small"
          sx={{
            display: "flex",
            flexGrow: 1,
            minWidth: "80px",
          }}
          type="number"
          value={localStyles.size}
          onChange={handleFontSizeChange}
          inputProps={{ min: 8, max: 72 }}
        />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            border: "1px solid #0000003b",
            borderRadius: "4px",
            "&:hover": {
              borderColor: "#000",
              cursor: "pointer",
            },
          }}
        >
          <MuiColorInput
            title="Font Color"
            format="hex"
            value={localStyles.color ?? "black"}
            onChange={handleColorChange}
            size="small"
            sx={{
              "& input": {
                width: 0,
                border: "none",
                paddingRight: 0,
              },
              "& fieldset": {
                display: "none",
              },
              "& button": {
                boxShadow: "none",
                width: 24,
                height: 24,
                marginLeft: "8px",
              },
              "& .MuiInputBase-root": {
                padding: 0,
                margin: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              width: 38,
              height: 38,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: "fit-content",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            border: "1px solid #0000003b",
            borderRadius: "4px",
            padding: 1,
            gap: 2,
            "&:hover": {
              borderColor: "#000",
              cursor: "pointer",
            },
          }}
        >
          <Button
            variant="text"
            size="small"
            onClick={() => {
              const updatedStyles = {
                ...localStyles,
                bold: !localStyles.bold,
              };
              setLocalStyles(updatedStyles);
              debouncedUpdateStyles(updatedStyles);
            }}
            sx={{
              width: "38px",
              height: "38px",
              minWidth: "38px",
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "&:hover": {
                color: "black",
              },
              margin: 0,
              backgroundColor: localStyles.bold ? "#0000003b" : "transparent",
              color: localStyles.bold ? "white" : "black",
            }}
            startIcon={<FormatBold />}
          />
          <Button
            variant="text"
            size="small"
            onClick={() => {
              const updatedStyles = {
                ...localStyles,
                italic: !localStyles.italic,
              };
              setLocalStyles(updatedStyles);
              debouncedUpdateStyles(updatedStyles);
            }}
            sx={{
              width: "38px",
              height: "38px",
              minWidth: "38px",
              padding: 0,
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "&:hover": {
                color: "black",
              },
              margin: 0,
              backgroundColor: localStyles.italic ? "#0000003b" : "transparent",
              color: localStyles.italic ? "white" : "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            startIcon={<FormatItalicIcon sx={{ fontSize: "16px" }} />}
          />

          <Button
            variant="text"
            size="small"
            onClick={() => {
              const updatedStyles = {
                ...localStyles,
                underline: !localStyles.underline,
              };
              setLocalStyles(updatedStyles);
              debouncedUpdateStyles(updatedStyles);
            }}
            sx={{
              width: "38px",
              height: "38px",
              minWidth: "38px",
              padding: 0,
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "&:hover": {
                color: "black",
              },
              margin: 0,
              backgroundColor: localStyles.underline
                ? "#0000003b"
                : "transparent",
              color: localStyles.underline ? "white" : "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            startIcon={<FormatUnderlined sx={{ fontSize: "16px" }} />}
          />

          <Button
            variant="text"
            size="small"
            onClick={() => {
              const updatedStyles = {
                ...localStyles,
                strikethrough: !localStyles.strikethrough,
              };
              setLocalStyles(updatedStyles);
              debouncedUpdateStyles(updatedStyles);
            }}
            sx={{
              width: "38px",
              height: "38px",
              minWidth: "38px",
              padding: 0,
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "&:hover": {
                color: "black",
              },
              margin: 0,
              backgroundColor: localStyles.strikethrough
                ? "#0000003b"
                : "transparent",
              color: localStyles.strikethrough ? "white" : "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            startIcon={<FormatStrikethrough sx={{ fontSize: "16px" }} />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #0000003b",
              borderRadius: "4px",
              padding: 1,
              gap: 0.5,
              width: "fit-content",
            }}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                const updatedStyles = {
                  ...localStyles,
                  text_align: "left",
                };
                setLocalStyles(updatedStyles);
                debouncedUpdateStyles(updatedStyles);
              }}
              sx={{
                width: "38px",
                height: "38px",
                minWidth: "38px",
                padding: 0,
                "& .MuiButton-startIcon": {
                  margin: 0,
                },
                "&:hover": {
                  color: "black",
                },
                margin: 0,
                backgroundColor:
                  localStyles.text_align === "left"
                    ? "#0000003b"
                    : "transparent",
                color: localStyles.text_align === "left" ? "white" : "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              startIcon={<FormatAlignLeft sx={{ fontSize: "16px" }} />}
            />

            <Button
              variant="text"
              size="small"
              onClick={() => {
                const updatedStyles = {
                  ...localStyles,
                  text_align: "center",
                };
                setLocalStyles(updatedStyles);
                debouncedUpdateStyles(updatedStyles);
              }}
              sx={{
                width: "38px",
                height: "38px",
                minWidth: "38px",
                padding: 0,
                "& .MuiButton-startIcon": {
                  margin: 0,
                },
                "&:hover": {
                  color: "black",
                },
                margin: 0,
                backgroundColor:
                  localStyles.text_align === "center"
                    ? "#0000003b"
                    : "transparent",
                color: localStyles.text_align === "center" ? "white" : "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              startIcon={<FormatAlignCenter sx={{ fontSize: "16px" }} />}
            />

            <Button
              variant="text"
              size="small"
              onClick={() => {
                const updatedStyles = {
                  ...localStyles,
                  text_align: "right",
                };
                setLocalStyles(updatedStyles);
                debouncedUpdateStyles(updatedStyles);
              }}
              sx={{
                width: "38px",
                height: "38px",
                minWidth: "38px",
                padding: 0,
                "& .MuiButton-startIcon": {
                  margin: 0,
                },
                "&:hover": {
                  color: "black",
                },
                margin: 0,
                backgroundColor:
                  localStyles.text_align === "right"
                    ? "#0000003b"
                    : "transparent",
                color: localStyles.text_align === "right" ? "white" : "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              startIcon={<FormatAlignRight sx={{ fontSize: "16px" }} />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "fit-content",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <LetterCasing
              styles={localStyles}
              onUpdateStyles={(updatedStyles) => {
                setLocalStyles(updatedStyles);
                debouncedUpdateStyles(updatedStyles);
              }}
            />

            <LetterSpacing
              styles={localStyles}
              onUpdateStyles={(updatedStyles) => {
                setLocalStyles(updatedStyles);
                debouncedUpdateStyles(updatedStyles);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
