import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useMemo,
  useEffect,
} from "react";

import { AuthenticatedUserResponse } from "./modules/common/Sidebar/types";

interface User extends AuthenticatedUserResponse {}

interface UserStateContextType {
  user: User | null; // Changed from undefined to null for clearer intent
  updateUser: (value: User | null) => void;
}

const UserStateContext = createContext<UserStateContextType | undefined>(
  undefined,
);

interface UserContextProviderProps {
  children: ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useState<User | null>(null);

  // Optional: Add persistence
  useEffect(() => {
    // Check if there's a stored user in localStorage on mount
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const updateUser = (value: User | null) => {
    setUser(value);
    // Optional: Persist to localStorage
    if (value) {
      localStorage.setItem("user", JSON.stringify(value));
    } else {
      localStorage.removeItem("user");
    }
  };

  const value = useMemo(() => ({ user, updateUser }), [user]);

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserContextProvider");
  }
  return context;
}
