import React, { useEffect, useRef } from "react";

import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { Episode, Paragraph } from "../../../services/episode.service";
import VerticalDotsMenu from "./VerticalDotsMenu";
import { episodeService } from "../../../services";

interface Props {
  episode: Episode;
  currentTime: number;
  onParagraphClick: (paragraph: Paragraph) => void;
}

export default function SubtitleEditor({
  episode,
  currentTime,
  onParagraphClick,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const paragraphRefs = useRef<any[]>([]);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isUserScrolling = useRef(false);
  const lastAutoScrollTime = useRef(0);

  const { mutateAsync } = useMutation({
    mutationKey: ["exportSrt"],
    mutationFn: () => episodeService.exportSrtByEpisodeId(episode.id),
  });

  const handleExportSrt = async () => {
    const response: any = await mutateAsync();
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.download = `episode-${episode?.name}.srt`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      isUserScrolling.current = true;

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      scrollTimeoutRef.current = setTimeout(() => {
        isUserScrolling.current = false;
      }, 2000); // Adjust timeout as needed
    };

    container.addEventListener("scroll", handleScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!paragraphRefs.current || !paragraphRefs.current.length) return;
    if (isUserScrolling.current) return;

    const now = Date.now();
    if (now - lastAutoScrollTime.current < 500) {
      return;
    }
    lastAutoScrollTime.current = now;

    const currentParagraphIndex = (episode?.paragraphs ?? []).findIndex(
      (paragraph) =>
        paragraph.start <= currentTime && currentTime <= paragraph.end,
    );

    const currentParagraphElement =
      paragraphRefs.current[currentParagraphIndex];

    if (!currentParagraphElement) return;

    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const elementRect = currentParagraphElement.getBoundingClientRect();

    const isInView =
      elementRect.top >= containerRect.top &&
      elementRect.bottom <= containerRect.bottom;

    if (!isInView) {
      currentParagraphElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentTime, episode]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Subtitle</Typography>
        <VerticalDotsMenu onExportSrt={handleExportSrt} />
      </Box>
      <Box
        ref={containerRef}
        sx={{ height: "600px", overflowY: "auto", mt: 2 }}
      >
        {(episode?.paragraphs ?? []).map((paragraph, index) => (
          <Box
            // eslint-disable-next-line no-return-assign
            ref={(el) => (paragraphRefs.current[index] = el)}
            onClick={() => onParagraphClick(paragraph)}
            key={`${paragraph.start}-${paragraph.end}`}
            sx={{
              p: 1,
              marginBottom: 2,
              backgroundColor:
                paragraph.start <= currentTime && currentTime <= paragraph.end
                  ? "rgba(0, 0, 0, 0.1)"
                  : "transparent",
              "&:hover": {
                borderLeft: "2px solid gray",
                pl: 1,
                cursor: "pointer",
              },
            }}
          >
            {paragraph.text}
            <Box display="flex" alignItems="center" mt={1}>
              <Typography variant="caption">
                {paragraph.start.toFixed(2)}
              </Typography>
              <Typography variant="caption" mx={1}>
                →
              </Typography>
              <Typography variant="caption">
                {paragraph.end.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
