/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Popover,
  TextField,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";

import ButtonView from "../../../common/Button";
import { dashboardService } from "../../services";
import TableView from "../../../common/Table";
import { Data } from "./types";
import EpisodeRow from "./parts/EpisodeRow";
import { bytesToSize } from "../../../../utils/bytesToSize";
import { Column } from "../../../common/Table/types";
import { formatDateAndTime } from "../../../../utils/formatDate";
import { ProjectsWithMetadata } from "../../../Project/services/projects.service";
import { useWebSocket } from "../../../../hooks/useWebSocket";
import { WebSocketEventName } from "../../../common/enums/WebSocketEventName";
import { EpisodeStatus } from "../../../Episode/enums/EpisodeStatus";
import { usersService } from "../../../common/Headerbar/services";
import { RoleName } from "../../../common/enums/RoleName";
import RoleBasedAccess from "../../../common/RoleBasedAccess";

const columns: Column[] = [
  { id: "name", label: "Episode", minWidth: 170 },
  { id: "project", label: "Project", minWidth: 100 },
  { id: "upload", label: "Upload Date", minWidth: 100 },
  { id: "file_type", label: "File Type", minWidth: 100 },
  { id: "file_size", label: "File Size", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "view", label: "Actions", minWidth: 100 },
];

function createData(
  id: string,
  name: string,
  project: string,
  upload: string,
  file_type: string,
  file_size: string,
  status: EpisodeStatus,
): Data {
  return { id, name, project, upload, file_type, file_size, status };
}

interface Props {
  projects: ProjectsWithMetadata[] | undefined;
}

export default function EpisodeList({ projects }: Props) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const socket = useWebSocket();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedVideo, setSelectedVideo] = React.useState("");
  const [selectedProject, setSelectedProject] = React.useState<{
    id: string;
    label: string;
  }>({
    label: "",
    id: "",
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedVideo(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { data, refetch, isPending, isLoading } = useQuery({
    queryKey: ["episodes", selectedProject.id, page, rowsPerPage],
    queryFn: () =>
      dashboardService.fetchEpisodes({
        projectId: selectedProject.id,
        page,
        perPage: rowsPerPage,
      }),
  });

  const { refetch: refetchDashboard } = useQuery({
    queryKey: ["dashboard-stats"],
    queryFn: () => dashboardService.fetchDashboardStats(),
  });

  const { refetch: refetchAuthenticatedUser } = useQuery({
    queryKey: ["authenticated-user"],
    queryFn: () => usersService.fetchAuthenticardUser(),
  });

  const handleRefreshEpisode = () => {
    refetch();
    refetchDashboard();
    refetchAuthenticatedUser();
  };

  useEffect(() => {
    if (socket) {
      socket.on(WebSocketEventName.EPISODE_GENERATED, async () => {
        await refetch();
        await refetchDashboard();
        await refetchAuthenticatedUser();
      });
    }

    return () => {
      if (socket) {
        socket.off(WebSocketEventName.EPISODE_GENERATED);
      }
    };
  }, [socket]);

  const rows =
    data?.episodes.map((episode) => {
      return createData(
        episode.id,
        episode.name,
        episode.project.name,
        formatDateAndTime(episode.upload.created_at),
        episode.upload.mime_type,
        bytesToSize(parseFloat(episode.upload.size)),
        episode.status,
      );
    }) || [];

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage === 0) {
      setPage(newPage + 1);
    }

    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleProjectChange = (
    _event: React.SyntheticEvent,
    newValue: { id: string; label: string } | null,
  ) => {
    if (!newValue) {
      setSelectedProject({
        label: "",
        id: "",
      });
    } else {
      setSelectedProject(newValue);
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <video src={selectedVideo} width="450" height="300" autoPlay controls />
      </Popover>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "14px 0px 24px 0px",
        }}
      >
        <Autocomplete
          disablePortal
          clearOnEscape
          onChange={handleProjectChange}
          value={selectedProject}
          options={
            projects && projects.length
              ? projects.map((project) => {
                  return { label: project.name, id: project.id };
                })
              : []
          }
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Filter By Project"
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{
              mb: 1,
              mr: 2,
              ":hover": { border: "none", backgroundColor: "#fff" },
            }}
            type="button"
            variant="text"
            onClick={handleRefreshEpisode}
          >
            <RefreshIcon />
          </Button>
          <RoleBasedAccess allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
            <ButtonView
              type="button"
              variant="contained"
              size="small"
              label="Add"
              startIcon={<AddOutlinedIcon />}
              handleOnClick={() => {
                window.location.href = "/episodes/create";
              }}
            />
          </RoleBasedAccess>
        </Box>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }} variant="outlined">
        {isPending || isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: 10 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <TableView
                columns={columns}
                rows={rows}
                handleClick={handleClick}
                TableList={EpisodeRow}
                handleCustomEvent={() => {}}
              />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data?.pagination.total_items || 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </>
        )}
      </Paper>
    </>
  );
}
