import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

interface RegenerateInsightsDialogProps {
  selectedTab: string;
  open: boolean;
  onClose: () => void;
  onRegenerate: (prompt: string) => void;
}

export default function RegenerateInsightsDialog({
  selectedTab,
  open,
  onClose,
  onRegenerate,
}: RegenerateInsightsDialogProps) {
  const [customPrompt, setCustomPrompt] = useState("");

  const getPromptExamples = (tab: string) => {
    switch (tab) {
      case "tweets":
        return [
          "Generate a tweet summarizing the key takeaway from Chris's journey to success.",
          "Create an engaging tweet about the challenges Chris overcame as a broker.",
        ];
      case "chapters":
        return [
          "Create chapter titles for Chris's story that highlight the major turning points in his career.",
          "Generate a summary for a chapter focusing on Chris's transition from uncertainty to success.",
        ];
      case "blog_post":
        return [
          "Write a blog post outline that details Chris's path from overcoming challenges to achieving success.",
          "Generate an engaging blog post idea about how Chris embraced the broker role and found happiness.",
        ];
      case "newsletter":
        return [
          "Draft a newsletter blurb introducing Chris's new chapter in his career as a broker.",
          "Create a newsletter introduction explaining Chris's journey to happiness in the broker role.",
        ];
      case "quotes":
        return [
          "Generate an inspirational quote from Chris's journey that resonates with overcoming obstacles.",
          "Write a memorable quote summarizing Chris's biggest breakthrough as a broker.",
        ];
      case "titles":
        return [
          "Create a compelling title for Chris's story, focusing on his path to happiness in his career.",
          "Generate a catchy title for Chris's broker adventure that highlights his resilience.",
        ];
      case "keywords":
        return [
          "List important keywords related to Chris's success story and overcoming obstacles in his career.",
          "Generate keywords that capture the essence of Chris's journey into the broker world.",
        ];
      case "descriptions":
        return [
          "Write a concise description summarizing Chris's path to success for use on social media.",
          "Generate a description for Chris's journey from uncertainty to success that will grab attention on content platforms.",
        ];
      case "linkedin_posts":
        return [
          "Write a LinkedIn post celebrating Chris's success as he embraces his new career as a broker.",
          "Generate a professional LinkedIn post detailing the challenges Chris faced and how he overcame them.",
        ];
      default:
        return [];
    }
  };

  const handleRegenerate = () => {
    onRegenerate(customPrompt);
    onClose();
    setCustomPrompt("");
  };

  const promptExamples = getPromptExamples(selectedTab);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Regenerate {selectedTab}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Enter a custom prompt to regenerate the {selectedTab} (optional).
          Custom prompts can help generate specific insights tailored to your
          needs.
        </Typography>
        <TextField
          label="Custom AI Prompt (Optional)"
          fullWidth
          variant="outlined"
          value={customPrompt}
          onChange={(e) => setCustomPrompt(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Typography variant="caption" color="textSecondary">
          Leave the prompt empty to use default parameters.
        </Typography>
        <Typography variant="subtitle1">Prompt Examples:</Typography>
        <ul>
          {promptExamples.map((example) => (
            <li key={example}>{example}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleRegenerate} color="primary" variant="contained">
          Regenerate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
