import React from "react";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Divider, Stack } from "@mui/material";

import LoginForm from "./LoginForm";
import ContinueWithGoogle from "./components/ContinueWithGoogle";
import ContinueWithApple from "./components/ContinueWithApple";

export default function LoginView() {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h4">
        Podcast Assistant
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          padding: "30px 0px 10px 0px",
        }}
      >
        <Avatar sx={{ marginRight: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ marginTop: 0.5 }}>
          Sign In
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <ContinueWithGoogle
          onClick={() => {
            const serverUrl = process.env.REACT_APP_API_URL;
            window.location.href = `${serverUrl}/auth/signin/google`;
          }}
        />
        <ContinueWithApple
          onClick={() => {
            const serverUrl = process.env.REACT_APP_API_URL;
            window.location.href = `${serverUrl}/auth/signin/apple`;
          }}
        />
        <Stack direction="row" alignItems="center" spacing={2}>
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body1" color="textSecondary">
            Or
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Stack>
      </Box>
      {/* Login Form */}
      <LoginForm />
    </Box>
  );
}
