/* eslint-disable no-plusplus */
function getCookie(name: string) {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArray = document.cookie.split(";");

  // Loop through the array elements
  for (let i = 0; i < cookieArray.length; i++) {
    const cookiePair = cookieArray[i].split("=");

    // Removing whitespace at the beginning of the cookie name
    // and compare it with the given string
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export { getCookie };
