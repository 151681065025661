export interface VideoResponse {
  id: string;
  title: string;
  url: string;
  created_at: string;
  duration_in_milliseconds: number;
  paragraphs: Paragraph[];
  subtitle_styles: SubtitleStyles;
  episode: Episode;
}

export interface Word {
  end: number;
  word: string;
  start: number;
  confidence: number;
}

export interface Paragraph {
  id: string;
  start: number;
  end: number;
  text: string;
  words: SentenceObject[];
}

export interface SubtitleStyles {
  font?: string;
  size?: number;
  color?: string;
  background_style?: BackgroundStyle;
  background_color?: string;
  outline_thickness?: number;
  outline_color?: string;
  text_wrap?: boolean;
  line_height?: number;
  letter_case?: LetterCase;
  letter_spacing?: number;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  border_color?: string;
  border_width?: number;
  border_radius?: number;
  border_style?: string;
  margin_top?: number;
  margin_left?: number;
  text_align?: string;
  text_handle_position_x_percentage?: number;
  text_handle_position_y_percentage?: number;
  text_handle_width_percentage?: number;
}

export interface Episode {
  id: string;
  name: string;
  project: Project;
}

export interface Project {
  id: string;
  name: string;
}

export interface SentenceObject {
  start: number | null;
  end: number | null;
  word: string;
}

export enum LetterCase {
  DEFAULT = "default",
  UPPERCASE = "uppercase",
  LOWERCASE = "lowercase",
  CAPITALIZE = "capitalize",
}

export enum BackgroundStyle {
  NONE = "none",
  SOLID = "solid",
  OUTLINE = "outline",
  FULL = "full",
  ROUNDED = "rounded",
}

export enum ExportVideoCompressionLevel {
  HIGHER_QUALITY = "higher_quality",
  BALANCED = "balanced",
  VERY_FAST = "very_fast",
}

export enum ExportVideoResolution {
  "320p" = "320p",
  "480p" = "480p",
  "720p" = "720p",
  "1080p" = "1080p",
  "4k" = "4k",
  "instagram" = "instagram",
  "tiktok" = "tiktok",
  "youtube-shorts" = "youtube-shorts",
}
