import {
  getVideo,
  updateShortVideo,
  exportShortVideo,
} from "./edit-short-video.service";

export const editShortVideoService = {
  getVideo,
  updateShortVideo,
  exportShortVideo,
};
