import React from "react";

import { createBrowserRouter } from "react-router-dom";

import PrivateRoute from "../middlewares/Auth/AuthMiddleware";
import App from "../App";
import LoginPage from "../pages/Login";
import RegisterPage from "../pages/Register";
import Dashboard from "../pages/Dashboard";
import NotFoundPage from "../pages/NotFound";
import CreateEpisodePage from "../pages/CreateEpisode";
import ProjectPage from "../pages/Project";
import EpisodePage from "../pages/Episode";
import SetUpAccount from "../pages/SetUpAccount";
import Users from "../pages/Users";
import SharedEpisodes from "../pages/SharedEpisodes";
import EpisodesSharedWithMe from "../pages/EpisodesSharedWithMe";
import EpisodesSharedWithMeDetails from "../pages/EpisodesSharedWithMeDetails";
import Videos from "../pages/Videos";
import EditorPage from "../pages/Editor";
import GoogleLoginCallbackPage from "../pages/GoogleLoginCallbackPage";
import AppleLoginCallbackPage from "../modules/AppleLoginCallbackPage";
import Account from "../pages/Account";
import ForgotPasswordPage from "../pages/ForgotPassword";
import SetNewPasswordPage from "../pages/SetNewPassword";
import EditShortVideo from "../pages/EditShortVideo";
import ConfirmEmailPage from "../pages/ConfirmEmail";
import PaymentSuccess from "../pages/PaymentSuccess";
import { RoleName } from "../modules/common/enums/RoleName";
import RoleRoute from "../modules/common/RoleRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "login",
        element: (
          <PrivateRoute>
            <LoginPage />
          </PrivateRoute>
        ),
      },
      {
        path: "register",
        element: (
          <PrivateRoute>
            <RegisterPage />
          </PrivateRoute>
        ),
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "set-new-password/:hash",
        element: <SetNewPasswordPage />,
      },
      {
        path: "episodes",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes/:id",
        element: (
          <PrivateRoute>
            <EpisodePage />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes/:id/videos",
        element: (
          <PrivateRoute>
            <Videos />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes/:id/videos/:videoId/edit",
        element: (
          <PrivateRoute>
            <EditShortVideo />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes/create",
        element: (
          <PrivateRoute>
            <CreateEpisodePage />
          </PrivateRoute>
        ),
      },
      {
        path: "projects",
        element: (
          <PrivateRoute>
            <ProjectPage />
          </PrivateRoute>
        ),
      },
      {
        path: "shared-episodes",
        element: (
          <PrivateRoute>
            <SharedEpisodes />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes-shared-with-me",
        element: (
          <PrivateRoute>
            <EpisodesSharedWithMe />
          </PrivateRoute>
        ),
      },
      {
        path: "episodes-shared-with-me/:id",
        element: (
          <PrivateRoute>
            <EpisodesSharedWithMeDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "users",
        element: (
          <PrivateRoute>
            <RoleRoute allowedRoles={[RoleName.ADMIN, RoleName.EDITOR]}>
              <Users />
            </RoleRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <PrivateRoute>
            <EditShortVideo />
          </PrivateRoute>
        ),
      },
      {
        path: "setup-account/:hash",
        element: <SetUpAccount />,
      },
      {
        path: "checkout/success",
        element: <PaymentSuccess />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/",
        element: <NotFoundPage />,
      },
      {
        path: "editor",
        element: (
          <PrivateRoute>
            <EditorPage />
          </PrivateRoute>
        ),
      },
      {
        path: "auth/signin/google/callback",
        element: <GoogleLoginCallbackPage />,
      },
      {
        path: "auth/signin/apple/callback",
        element: <AppleLoginCallbackPage />,
      },
      {
        path: "account",
        element: (
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        ),
      },
      {
        path: "confirm-email/:hash",
        element: <ConfirmEmailPage />,
      },
    ],
  },
]);

export default router;
