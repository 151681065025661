import React from "react";

import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

import { RoleName } from "../../../../common/enums/RoleName";

interface Props {
  roleName: RoleName;
}

const getColor = (roleName: RoleName) => {
  switch (roleName) {
    case RoleName.ADMIN:
      return {
        backgroundColor: "#fee2e2",
        color: "#991b1b",
      };
    case RoleName.EDITOR:
      return {
        backgroundColor: "#dbeafe",
        color: "#1e3a8a",
      };
    case RoleName.VIEWER:
      return {
        backgroundColor: "#fef3c7",
        color: "#92400e",
      };
    default:
      return {
        backgroundColor: "",
        color: "",
      };
  }
};

const RoleChip = styled(Chip)(() => ({
  fontSize: "0.75rem",
  fontWeight: "medium",
  padding: "2px 10px",
  borderRadius: "9999px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 8px 0 0",
}));

export function RoleBadge({ roleName }: Props) {
  const chipStyles = getColor(roleName);

  return <RoleChip label={roleName} style={{ ...chipStyles }} />;
}
