import React from "react";

import { Box, Button } from "@mui/material";

import { DEFAULT_ZOOM_LEVEL } from "../../constants";
import zoomIn from "../../../../../assets/icons/zoom-in.svg";
import zoomOut from "../../../../../assets/icons/zoom-out.svg";

interface ZoomProps {
  zoomLevel: number;
  onZoomChange: (zoom: number) => void;
}

export default function Element({ zoomLevel, onZoomChange }: ZoomProps) {
  const handleZoomIn = () => {
    if (zoomLevel < 5) {
      onZoomChange(zoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.1) {
      onZoomChange(zoomLevel - 0.1);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onZoomChange(parseFloat(event.target.value));
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        type="button"
        onClick={handleZoomOut}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "none",
          border: "none",
        }}
      >
        <img src={zoomOut} alt="zoom-out icon" />
      </Button>
      <input
        type="range"
        min="0.1"
        max="5"
        step="0.1"
        value={zoomLevel}
        onChange={handleSliderChange}
        style={{ width: "150px" }}
      />
      <Button
        type="button"
        onClick={handleZoomIn}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "none",
          border: "none",
        }}
      >
        <img src={zoomIn} alt="zoom-in icon" />
      </Button>
      <Button
        type="button"
        onClick={() => onZoomChange(DEFAULT_ZOOM_LEVEL)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "none",
          border: "none",
        }}
      >
        Fit
      </Button>
    </Box>
  );
}
