import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ReplyIcon from "@mui/icons-material/Reply";

import SidebarView from "../common/Sidebar";
import { videosService } from "./services";
import Video from "./components/Video";
import { episodeService } from "../Episode/services";
import ButtonView from "../common/Button";

export default function Videos() {
  const { id: episodeId } = useParams();
  // fetch videos
  const { data } = useQuery({
    queryKey: ["getVideos", episodeId],
    queryFn: () => videosService.getVideos(episodeId),
  });

  // fetch episode details
  const { data: episode } = useQuery({
    queryKey: ["getEpisodeById", episodeId],
    queryFn: () => episodeService.getEpisodeById(episodeId),
  });

  const episodeName = episode?.name || "";

  return (
    <>
      <SidebarView pageName={`Episode - ${episodeName}`} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "auto",
          minHeight: "100vh",
          overflow: "auto",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            marginTop: 6,
            border: "none",
          }}
        >
          <ButtonView
            startIcon={<ReplyIcon />}
            type="button"
            variant="text"
            size="small"
            label="Go back"
            href={`/episodes/${episodeId}`}
          />
          <Typography sx={{ paddingBottom: 2, mt: 4 }} variant="body1">
            Short videos
          </Typography>
          <Grid container spacing={4}>
            {data?.videos?.map((video) => {
              return (
                <Video
                  key={video?.id}
                  episodeId={episodeId || ""}
                  video={video}
                />
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
