export const formatMillisecondsToHHMMSSForRuler = (ms: number): string => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}:${remainingMinutes}:${remainingSeconds}`;
  }

  if (minutes > 0) {
    if (remainingSeconds === 0) return `${minutes}m`;
    return `${minutes}:${remainingSeconds}`;
  }

  return `${seconds}s`;
};
