import { coreService } from "../../../../services";
import { PricingPlan, UpgradePricingPlanPayload } from "../types";

const getPricingPlans = async (): Promise<PricingPlan[]> => {
  const response = await coreService.httpClient.get(`/pricing-plans`);

  return response.data;
};

const upgradePricingPlan = async (
  payload: UpgradePricingPlanPayload,
): Promise<{ url: string }> => {
  const response = await coreService.httpClient.post(
    `/pricing-plans/create-checkout-session`,
    payload,
  );

  return response.data;
};

export { getPricingPlans, upgradePricingPlan };
