export const formatLabel = (
  key: string,
  replacedChar: string | string[] = "_",
) => {
  // special case
  if (key.toLowerCase().includes("vtm")) {
    return "Turbo"; // User friendly name
  }

  if (typeof replacedChar === "string") {
    const regExp = new RegExp(replacedChar, "g");
    return key
      ?.replace(regExp, " ")
      ?.replace(/\b[a-z]/g, (k) => k.toUpperCase());
  }

  let tempKey = key;

  replacedChar.forEach((char) => {
    const regExp = new RegExp(char, "g");
    tempKey = tempKey
      ?.replace(regExp, " ")
      ?.replace(/\b[a-z]/g, (k) => k.toUpperCase());
  });

  return tempKey;
};
