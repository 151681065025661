/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { Formik } from "formik";
import { Box, TextField } from "@mui/material";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Bounce, toast } from "react-toastify";

import CustomButton from "../../Button";
import { episodeService } from "../../../Episode/services";
import { Category } from "../../types";

interface Props {
  title: string;
  content: string;
  handleCloseModal: () => void;
  refetch: () => void;
  category: Category;
}

function contentModal({
  title,
  content,
  handleCloseModal,
  refetch,
  category,
}: Props) {
  const { id: episodeId } = useParams();

  const { mutateAsync, error, isSuccess, isPending } = useMutation({
    mutationKey: ["updateInsights-content"],
    mutationFn: (value: string) =>
      episodeService.updateInsights(episodeId, {
        category,
        value,
      }),
  });

  const handlechapterUpdate = async (formikValue: { content: string }) => {
    await toast.promise(mutateAsync(formikValue.content), {
      success: {
        toastId: `${title}-success`,
        render() {
          return "Saved!";
        },
        type: "success",
        theme: "colored",
        autoClose: 2000,
        transition: Bounce,
        delay: 500,
      },
      pending: {
        toastId: `${title}-pending`,
        render() {
          return "Saving, please wait...";
        },
        type: "default",
        theme: "colored",
        transition: Bounce,
      },
      error: {
        toastId: `${title}-error`,
        render() {
          return `Error: ${error?.message}`;
        },
        type: "error",
        theme: "colored",
        autoClose: false,
        transition: Bounce,
        delay: 500,
      },
    });
  };

  if (isSuccess) {
    handleCloseModal();
    refetch();
    return null;
  }

  const formikInitialValues = {
    content,
  };
  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={Yup.object({
        content: Yup.string().required("Required"),
      })}
      onSubmit={handlechapterUpdate}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                sx={{ width: "100%", alignSelf: "center" }}
                margin="normal"
                label={title}
                error={!!formik.errors.content}
                helperText={formik.errors.content}
                multiline
                {...formik.getFieldProps("content")}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleCloseModal} disabled={isPending}>
              Close
            </Button>
            <CustomButton
              label="Save"
              type="submit"
              variant="contained"
              color="success"
              size="small"
              disabled={!formik.isValid || isPending}
            />
          </DialogActions>
        </form>
      )}
    </Formik>
  );
}

export default contentModal;
