import React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "@tanstack/react-query";
import { Bounce, toast } from "react-toastify";

import cubeYellowIcon from "../../../../../assets/icons/cube-yellow.svg";
import cubeBlueIcon from "../../../../../assets/icons/cube-blue.svg";
import cubePurpleIcon from "../../../../../assets/icons/cube-purple.svg";
import { pricingPlanService } from "../../services";
import { UpgradePricingPlanPayload } from "../../types";
import { useUserState } from "../../../../../store";

interface Props {
  pricingPlansData: Record<string, any>[];
}

export default function PricingPlanCard({ pricingPlansData }: Props) {
  const { mutateAsync, isSuccess, error, data, isPending } = useMutation({
    mutationKey: ["upgradePricingPlan"],
    mutationFn: (values: UpgradePricingPlanPayload) =>
      pricingPlanService.upgradePricingPlan(values),
  });
  const { user } = useUserState();

  const handlePricingPlanUpgrade = async (
    planId: string,
    recurringInterval: string,
  ) => {
    const payload = {
      pricing_plan_id: planId,
      recurring_interval: recurringInterval,
    };
    await toast.promise(mutateAsync(payload), {
      error: {
        toastId: `Error`,
        render() {
          return `Error: ${JSON.stringify(error, null, 2)}`;
        },
        type: "error",
        theme: "colored",
        autoClose: false,
        transition: Bounce,
        delay: 500,
      },
    });
  };

  if (isSuccess) {
    window.location.replace(data.url);
  }

  if (isPending) {
    return (
      <Box sx={{ display: "flex", marginLeft: "45%", marginTop: "50px" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {pricingPlansData?.map((pricingPlanData, index) => {
        const isCurrentPlan = !!(
          user?.organization?.subscription?.pricing_plan?.id &&
          user?.organization?.subscription?.pricing_plan?.id ===
            pricingPlanData.id
        );
        const isDisabled = pricingPlanData.is_allowed_to_purchase === false;
        return (
          <Grid item xs={12} sm={4} key={pricingPlanData.id}>
            <Card
              variant="outlined"
              sx={{
                opacity: isDisabled ? 0.5 : 1,
                borderColor: isCurrentPlan ? "primary.main" : "grey.300",
                borderWidth: isCurrentPlan ? 2 : 1,
              }}
            >
              <CardContent sx={{ textAlign: "center", minWidth: "250px" }}>
                {index === 0 && <img src={cubeYellowIcon} alt="cube-icon" />}
                {index === 1 && <img src={cubeBlueIcon} alt="cube-icon" />}
                {index === 2 && <img src={cubePurpleIcon} alt="cube-icon" />}
                <Typography variant="h5" component="div" color="primary">
                  {pricingPlanData.name}
                </Typography>
                <Typography variant="h4" component="div">
                  {pricingPlanData.price_in_cents}
                </Typography>
                <Typography color="text.secondary">
                  {pricingPlanData.description}
                </Typography>
                <Button
                  variant="contained"
                  color={isCurrentPlan ? "secondary" : "primary"}
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() =>
                    handlePricingPlanUpgrade(
                      pricingPlanData.id,
                      pricingPlanData.recurring_interval,
                    )
                  }
                  disabled={isDisabled || isCurrentPlan}
                >
                  {isCurrentPlan
                    ? "Current Plan"
                    : `Upgrade to ${pricingPlanData.name}`}
                </Button>

                <Box mt={2} sx={{ textAlign: "left", ml: 1, pr: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <CheckIcon fontSize="small" />
                    <Typography variant="body2">
                      Advanced admin tools
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <CheckIcon fontSize="small" />
                    <Typography variant="body2">SAML and SSO</Typography>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <CheckIcon fontSize="small" />
                    <Typography variant="body2">Custom billing</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
