import * as React from "react";

import Container from "@mui/material/Container";

import Footer from "../modules/common/Footer";
import SetNewPasswordView from "../modules/SetNewPassword";

export default function SetNewPasswordPage() {
  return (
    <Container component="main" maxWidth="xs">
      <SetNewPasswordView />
      <Footer />
    </Container>
  );
}
