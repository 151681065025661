import React, { useState } from "react";

import { Box } from "@mui/material";

import BackgroundStyle from "./BackgroundStyle";
import TextOutline from "./TextOutline";
import { SubtitleStyles } from "../../../../../types";

interface TextStyleProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function TextEffect({ styles, onUpdateStyles }: TextStyleProps) {
  const [localStyles, setLocalStyles] = useState(styles);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  // Debounced update function
  const debouncedUpdateStyles = (updatedStyles: SubtitleStyles) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const timeout = setTimeout(() => {
      onUpdateStyles(updatedStyles);
    }, 500);
    setDebounceTimeout(timeout);
  };

  const handleUpdateStyles = (newStyles: SubtitleStyles) => {
    setLocalStyles(newStyles);
    debouncedUpdateStyles(newStyles);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <BackgroundStyle
        styles={localStyles}
        onUpdateStyles={handleUpdateStyles}
      />
      <TextOutline styles={localStyles} onUpdateStyles={handleUpdateStyles} />
    </Box>
  );
}
