import React, { useState } from "react";

import ReactPlayer from "react-player";
import moment from "moment";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { Box, Grid, Link, Paper, styled, Typography } from "@mui/material";

import { Video as VideoType } from "../../types";

interface Props {
  video: VideoType;
  episodeId: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "350px",
  display: "flex",
  flexDirection: "column",
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

export default function Video({ video, episodeId }: Props) {
  const [hoverIcon, setHoverIcon] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseEnter = () => {
    setHoverIcon(1);
    setIsPlaying(true); // Start playing the video
  };

  const handleMouseLeave = () => {
    setHoverIcon(0);
    setIsPlaying(false); // Stop playing the video
  };

  return (
    <Grid
      item
      xs={4}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        href={`/episodes/${episodeId}/videos/${video.id}/edit`}
        sx={{ textDecoration: "none" }}
      >
        <Item sx={{ position: "relative" }}>
          <Box
            sx={{
              opacity: hoverIcon,
              transition: "all .4s",
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              position: "absolute",
              top: "40%",
              left: "40%",
              p: 1,
              borderRadius: "10%",
            }}
          >
            <ContentCutIcon />
            <Typography variant="body1" sx={{ ml: 0.5 }}>
              Edit
            </Typography>
          </Box>
          <ReactPlayer
            url={video.url}
            width="100%"
            height="100%"
            playing={isPlaying}
            muted
          />
          <Box sx={{ textAlign: "left", px: 1, pb: 0.5 }}>
            <Typography variant="body2">{video.title}</Typography>
            <Typography variant="body2">
              {moment(video.created_at).fromNow()}
            </Typography>
          </Box>
        </Item>
      </Link>
    </Grid>
  );
}
