import { coreService } from "../../../services";

interface DashboardStatsResponse {
  number_of_projects: number;
  number_of_shared_projects: number;
  number_of_episodes: number;
  total_episodes_length_in_minutes: number;
}

const fetchDashboardStats = async (): Promise<DashboardStatsResponse> => {
  const response = await coreService.httpClient.get("/stats/dashboard");

  return response.data;
};

export { fetchDashboardStats };
