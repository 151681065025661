import React, { useState } from "react";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import { useSearchParams } from "react-router-dom";

import ForgotPasswordForm from "./ForgotPasswordForm";

export default function ForgotPasswordView() {
  const [searchParams] = useSearchParams();
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h4">
        Podcast Assistant
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          padding: "30px 0px 10px 0px",
        }}
      >
        <Avatar sx={{ marginRight: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ marginTop: 0.5 }}>
          Forgot Password
        </Typography>
      </Box>
      {isPasswordReset && (
        <Box>
          <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
            We have sent you an email to <b>{email}</b>. Please check your email
            to reset your password.
          </Typography>
          <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
            Back to{" "}
            <Link href="/login" variant="body2">
              Sign In
            </Link>
          </Typography>
        </Box>
      )}
      {!isPasswordReset && (
        <ForgotPasswordForm
          email={searchParams.get("email") || ""}
          handlePasswordReset={(userEmail) => {
            setEmail(userEmail);
            setIsPasswordReset(true);
          }}
        />
      )}
    </Box>
  );
}
