import { coreService } from "../../../services";
import { VideosResponse } from "../types";

const getVideos = async (
  episodeId: string | undefined,
): Promise<VideosResponse | undefined> => {
  if (episodeId) {
    const response = await coreService.httpClient.get(
      `/short-videos/episodes/${episodeId}`,
    );

    return response.data;
  }

  return undefined;
};

export { getVideos };
