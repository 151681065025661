import { coreService } from "../../../services";

export interface SinginPayload {
  email: string;
  password: string;
}

export interface SigninResponse {
  jwt_token: string;
  refresh_token: string;
  jwt_token_expires_in: string;
  refresh_token_expires_in: string;
}

const singin = async (values: SinginPayload): Promise<SigninResponse> => {
  const response = await coreService.httpClient.post("/auth/signin", values);

  return response.data;
};

const refreshToken = async (token: string): Promise<SigninResponse> => {
  const response = await coreService.httpClient.post("/auth/refresh-token", {
    refresh_token: token,
  });
  return response.data;
};

export { singin, refreshToken };
