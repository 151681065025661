import React from "react";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Box, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { Column } from "../../../common/Table/types";
import {
  getEpisodesSharedWithMe,
  SharedEpisode,
} from "../../services/shared-episodes.service";
import TableView from "../../../common/Table";
import EpisodeSharedWithMeRow from "./parts/EpisodeSharedWithMeRow";
import { formatDateAndTime } from "../../../../utils/formatDate";

const columns: Column[] = [
  { id: "episode_name", label: "Episode Name", minWidth: 100 },
  { id: "shared_by", label: "Shared By", minWidth: 100 },
  { id: "shared_at", label: "Shared At", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },
  { id: "expires_at", label: "Expires At", minWidth: 100 },
];

function createData(
  id: string,
  episode_name: string,
  shared_by: string,
  shared_at: string,
  role: string,
  expires_at?: string,
): any {
  return {
    id,
    episode_name,
    shared_by,
    shared_at,
    role,
    expires_at,
  };
}

export default function ListOfEpisodesSharedWithMe() {
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const { data, refetch } = useQuery({
    queryKey: ["fetch-episodes-shared-with-me", page, rowsPerPage],
    queryFn: () =>
      getEpisodesSharedWithMe({
        page,
        perPage: rowsPerPage,
      }),
  });

  const rows =
    data?.episodes?.map((sharedEpisode: SharedEpisode) => {
      return createData(
        sharedEpisode.episode.id,
        sharedEpisode.episode.name,
        `${sharedEpisode.shared_by_user.first_name} ${sharedEpisode.shared_by_user.last_name} (${sharedEpisode.shared_by_user.email})`,
        formatDateAndTime(sharedEpisode.created_at),
        sharedEpisode.role?.name || "",
        sharedEpisode.expires_at
          ? moment(sharedEpisode.expires_at).toDate().toLocaleDateString()
          : undefined,
      );
    }) || [];

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage === 0) {
      setPage(newPage + 1);
    }
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "24px 0px 24px 0px",
        }}
      >
        <Box>
          <Button type="submit" variant="outlined" onClick={() => refetch()}>
            <RefreshIcon />
          </Button>
        </Box>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 800 }} />
        <TableView
          columns={columns}
          rows={rows}
          handleCustomEvent={() => {}}
          handleClick={() => {}}
          TableList={EpisodeSharedWithMeRow}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.pagination.total_items || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </>
  );
}
