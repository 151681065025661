import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";

import { verifyForgotPasswordHash } from "./services/set-new-password.service";
import ForgotPasswordHashExpired from "./components/ForgotPasswordHashExpired";
import SetNewPasswordForm from "./SetNewPasswordForm";
import NewPasswordSuccessfullySet from "./components/NewPasswordSuccessfullySet";

export default function SetNewPasswordView() {
  const { hash } = useParams();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(true);
  const [showInvalidHashMessage, setShowInvalidHashMessage] = useState(false);
  const [showSetNewPasswordForm, setShowSetNewPasswordForm] = useState(false);
  const [
    showNewPasswordSuccessfullySetMessage,
    setShowNewPasswordSuccessfullySetMessage,
  ] = useState(false);

  useEffect(() => {
    const validateHash = async () => {
      const { is_valid: isValid } = await verifyForgotPasswordHash(hash ?? "");
      setIsPending(false);
      setShowInvalidHashMessage(!isValid);
      setShowSetNewPasswordForm(isValid);
    };

    validateHash();
  }, [hash]);

  const handleNewPasswordSuccessfullySet = () => {
    setShowNewPasswordSuccessfullySetMessage(true);
    setShowSetNewPasswordForm(false);

    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  if (isPending) {
    return (
      <Box sx={{ display: "flex", marginTop: "64px" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h4">
        Podcast Assistant
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          padding: "30px 0px 10px 0px",
        }}
      >
        <Avatar sx={{ marginRight: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ marginTop: 0.5 }}>
          Set New Password
        </Typography>
      </Box>
      {showInvalidHashMessage && <ForgotPasswordHashExpired />}
      {showSetNewPasswordForm && (
        <SetNewPasswordForm
          hash={hash ?? ""}
          handleNewPasswordSuccessfullySet={handleNewPasswordSuccessfullySet}
        />
      )}
      {showNewPasswordSuccessfullySetMessage && <NewPasswordSuccessfullySet />}
    </Box>
  );
}
