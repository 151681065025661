import React, { useRef } from "react";

import Konva from "konva";
import { Rect } from "react-konva";

interface VerticalLineProps {
  x: number;
  onDrag: (x: number) => void;
}

export default function VerticalLine({ x, onDrag }: VerticalLineProps) {
  const rectRef = useRef<Konva.Rect>(null);
  const manualUpdateRef = useRef(false);

  const handleDragMove = (e: any) => {
    manualUpdateRef.current = true;
    e.target.y(16); // Prevent vertical movement
    onDrag(e.target.x());
  };

  const handleMouseEnter = (e: any) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = "grab";
  };

  const handleMouseLeave = (e: any) => {
    e.target.getStage().container().style.cursor = "default";
  };

  const handleDragStart = (e: any) => {
    e.target.getStage().container().style.cursor = "grabbing";
  };

  const handleDragEnd = (e: any) => {
    e.target.getStage().container().style.cursor = "grab";
    manualUpdateRef.current = false;
  };

  React.useEffect(() => {
    if (rectRef.current && !manualUpdateRef.current) {
      rectRef.current.to({
        duration: 0.3,
        x,
      });
    }
  }, [x]);

  return (
    <Rect
      ref={rectRef}
      y={16}
      width={3}
      height={200}
      fill="#727ff2"
      draggable
      onDragMove={handleDragMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    />
  );
}
