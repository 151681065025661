import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function ForgotPasswordHashExpired() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        padding: "30px 0px 10px 0px",
      }}
    >
      <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
        Sorry, the link you are using is invalid or has expired. Please try
        again.
      </Typography>
      <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
        Go back to{" "}
        <Link href="/login" variant="body2">
          Sign In Page
        </Link>{" "}
        and try again.
      </Typography>
    </Box>
  );
}
