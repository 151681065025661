import React from "react";

import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import ActionsList from "./ActionsList";
import { Chapter } from "../../../services/episode.service";
import { toHHMMSS } from "../../../../../utils/formatTIme";
import InsightsModalManager, {
  ModalType,
} from "../../../../common/ModalManager";
import { Category } from "../../../../common/types";

interface ChapterProps {
  chapters: Chapter[];
  chapterObject: Chapter;
  category: Category;
  refetch?: () => void;
}

export default function ChapterView({
  chapters,
  chapterObject,
  category,
  refetch,
}: ChapterProps) {
  const chapterTime = `${toHHMMSS(chapterObject.start)} - ${toHHMMSS(chapterObject.end)}`;
  const [openInsightsModalManager, setOpenInsightsModalManager] =
    React.useState(false);
  const handleOpenInsightsModalManager = () =>
    setOpenInsightsModalManager(true);

  const handleCopy = () => {
    navigator.clipboard.writeText(chapterObject.title);
  };

  return (
    <Box>
      <InsightsModalManager
        open={openInsightsModalManager}
        setOpen={setOpenInsightsModalManager}
        modalType={ModalType.CHAPTERS}
        modalTitle="Edit Chapter"
        objectValue={{ title: chapterObject.title }}
        values={chapters}
        refetch={refetch || (() => {})}
        category={category}
      />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "20px",
        }}
      >
        <ListItem alignItems="flex-start">
          <ListItemText primary={chapterObject.title} secondary={chapterTime} />
          <ActionsList
            handleOpenInsightsModalManager={handleOpenInsightsModalManager}
            handleCopy={handleCopy}
          />
        </ListItem>
        <Divider sx={{ width: "100%" }} />
      </List>
    </Box>
  );
}
