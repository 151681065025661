import React from "react";

import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  Button,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomButton from "../../../../../common/Button";
import { projectsService } from "../../../../services";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  handleClose: () => void;
  open: boolean;
  projectId: string;
  projectName: string;
  projectDescription: string;
}

export default function EditDialog({
  handleClose,
  open,
  projectId,
  projectName,
  projectDescription,
}: Props) {
  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["edit-project"],
    mutationFn: (values: {
      project_name: string;
      project_description: string;
    }) =>
      projectsService.updateProject({
        projectId,
        project_name: values.project_name,
        project_description: values.project_description,
      }),
  });

  const handleEditProject = async (values: {
    project_name: string;
    project_description: string;
  }) => {
    await mutateAsync(values);
  };

  if (isSuccess) {
    setTimeout(() => {
      handleClose();
      window.location.reload();
    }, 2000);
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Edit Project
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          initialValues={{
            project_name: projectName,
            project_description: projectDescription,
          }}
          validationSchema={Yup.object({
            project_name: Yup.string().required("Required"),
          })}
          onSubmit={handleEditProject}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isError ? (
                  <Alert severity="error" sx={{ marginBottom: "12px" }}>
                    {error.message}
                  </Alert>
                ) : null}

                {isSuccess ? (
                  <Alert severity="success" sx={{ marginBottom: "12px" }}>
                    Project Updated!
                  </Alert>
                ) : null}

                <TextField
                  sx={{ width: "70%", alignSelf: "center" }}
                  margin="normal"
                  label="Project Name"
                  error={!!formik.errors.project_name}
                  helperText={formik.errors.project_name}
                  {...formik.getFieldProps("project_name")}
                />

                <TextField
                  sx={{ width: "70%", alignSelf: "center" }}
                  margin="normal"
                  label="Project Description"
                  error={!!formik.errors.project_description}
                  helperText={formik.errors.project_description}
                  {...formik.getFieldProps("project_description")}
                />
                <CustomButton
                  label="Save Changes"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isPending || isSuccess}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
