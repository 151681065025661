import * as Yup from "yup";

const envVarsSchema = Yup.object({
  REACT_APP_API_URL: Yup.string().url().required(),
  REACT_APP_CURRENT_ENV: Yup.string()
    .matches(/dev|prod/, { message: "Only dev or prod values are allowed" })
    .required(),
})
  .unknown()
  .required();

function envValidation() {
  const { REACT_APP_API_URL, REACT_APP_CURRENT_ENV } =
    envVarsSchema.validateSync(process.env);

  if (!REACT_APP_API_URL || !REACT_APP_CURRENT_ENV) {
    throw new Error(`Config validation error: REACT_APP_API_URL`);
  }
  return {
    apiUrl: REACT_APP_API_URL,
    currentEnv: REACT_APP_CURRENT_ENV,
  };
}

function config() {
  return envValidation();
}

export { config };
