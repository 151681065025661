import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getCookie } from "../../utils/cookie";
import { deleteCookie, CookieKey } from "../../hooks/useCookie";

interface AuthMiddlewareType {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
}

interface Props {
  children: ReactNode;
}

const AuthContext = createContext<AuthMiddlewareType | undefined>(undefined);

export function AuthProvider({ children }: Props) {
  const tokenExists = !!getCookie(CookieKey.JWT_TOKEN);
  const [authenticated, setAuthenticated] = useState(tokenExists);
  const login = () => {
    // Perform authentication logic
    setAuthenticated(true);
  };

  const logout = () => {
    // Perform logout logic
    deleteCookie(CookieKey.JWT_TOKEN);
    deleteCookie(CookieKey.REFRESH_TOKEN);
    setAuthenticated(false);
  };

  useEffect(() => {
    const token = getCookie("jwt_token");

    if (!token) {
      logout();
    } else {
      login();
    }
  }, [authenticated]);

  const authContextValue = useMemo(
    () => ({
      authenticated,
      login,
      logout,
    }),
    [authenticated], // Only recreate when authenticated changes
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthMiddlewareType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
