import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";

import { RoleName } from "../../../common/enums/RoleName";
import { userManagementService } from "../../services";
import {
  OrganizationUser,
  UpdateUsersRolePayload,
} from "../../services/user-management.service";
import { useUserState } from "../../../../store";
import { usersService } from "../../../common/Headerbar/services";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const StyledErrorMessage = styled(ErrorMessage)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
  marginTop: theme.spacing(0.5),
  textAlign: "left",
}));

const roleDescriptions: Record<RoleName, string> = {
  [RoleName.VIEWER]: "Can view content and access basic features",
  [RoleName.EDITOR]: "Can create and modify content",
  [RoleName.ADMIN]: "Full access to all features and user management",
};

interface Props {
  handleClose: () => void;
  handleRoleUpdate: () => void;
  open: boolean;
  user: OrganizationUser;
}

function getAuthenticatedUser() {
  const { data: authenticatedUser } = useQuery({
    queryKey: ["authenticated-user"],
    queryFn: () => usersService.fetchAuthenticardUser(),
  });

  return authenticatedUser;
}

export default function ManageRolesDialog({
  handleClose,
  handleRoleUpdate,
  open,
  user,
}: Props) {
  const { updateUser } = useUserState();

  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["add-user-to-organization"],
    mutationFn: (values: UpdateUsersRolePayload) =>
      userManagementService.updateUsersRole(values),
  });

  const handleUpdateUserRole = async (values: { role: RoleName }) => {
    await mutateAsync({ id: user.id, roles: [values.role] });
    handleRoleUpdate();
    const authenticatedUser = await getAuthenticatedUser();
    if (authenticatedUser) {
      updateUser(authenticatedUser);
    }
  };

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(handleClose, 2000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, handleClose]);

  return (
    <StyledDialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div">
          Modify User Role
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={{ role: user.roles[0] }} // Take the first role as initial
          validationSchema={Yup.object({
            role: Yup.string().required("Role is required"),
          })}
          onSubmit={handleUpdateUserRole}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ width: "100%" }}>
                {isError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error.message}
                  </Alert>
                )}

                {isSuccess && (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    Role updated successfully!
                  </Alert>
                )}

                <StyledFormControl error={!!formik.errors.role}>
                  <FormLabel component="legend">Select User Role</FormLabel>
                  <StyledRadioGroup
                    value={formik.values.role}
                    onChange={(e) =>
                      formik.setFieldValue("role", e.target.value)
                    }
                  >
                    {Object.values(RoleName).map((role) => (
                      <FormControlLabel
                        key={role}
                        value={role}
                        control={<Radio />}
                        label={
                          <Box>
                            <Typography variant="subtitle1">{role}</Typography>
                            <Typography variant="body2" color="text.secondary">
                              {roleDescriptions[role]}
                            </Typography>
                          </Box>
                        }
                      />
                    ))}
                  </StyledRadioGroup>
                  <StyledErrorMessage name="role" component="div" />
                </StyledFormControl>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{ mr: 2 }}
                    disabled={
                      isPending ||
                      Object.keys(formik.errors).length > 0 ||
                      isSuccess
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      isPending ||
                      Object.keys(formik.errors).length > 0 ||
                      isSuccess
                    }
                  >
                    Update Role
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
}
