import { coreService } from "../../../../../../services";

const fetchBilling = async (): Promise<{ url: string }> => {
  const response = await coreService.httpClient.post(
    `/pricing-plans/create-customer-portal-session`,
    {},
  );

  return response.data;
};

export { fetchBilling };
