import React, { useEffect, useState } from "react";

import { Box, Card, Stack, Tab, Tabs } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import ShareIcon from "@mui/icons-material/Share";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import InsightsIcon from "@mui/icons-material/Insights";
import { ClosedCaption } from "@mui/icons-material";
import styled from "styled-components";

import SidebarView from "../common/Sidebar";
import { episodeService } from "./services";
import ShareEpisodeDialog from "./components/ShareEpisodeDialog";
import { WebSocketEventName } from "../common/enums/WebSocketEventName";
import { useWebSocket } from "../../hooks/useWebSocket";
import Insights from "./components/Insights";
import Preview from "./components/Preview";
import ButtonView from "../common/Button";

const StyledTab = styled(Tab)(
  () => `
  font-size: 11px;
  min-height: 48px;
  padding: 0px 20px;
`,
);

export default function Episode() {
  const { id: episodeId } = useParams<{ id: string }>();

  const [showShareEpisodeModal, setShowShareEpisodeModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"preview" | "insights">(
    "preview",
  );

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const socket = useWebSocket();

  const { data: insights, refetch } = useQuery({
    queryKey: ["insights", episodeId],
    queryFn: () => episodeService.fetchInsights(episodeId),
  });

  const { data: episode } = useQuery({
    queryKey: ["getEpisodeById", episodeId],
    queryFn: () => episodeService.getEpisodeById(episodeId),
  });

  const handleOpenShareEpisodeModal = () => {
    setShowShareEpisodeModal(true);
  };

  const handleCloseShareEpisodeModal = () => {
    setShowShareEpisodeModal(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on(WebSocketEventName.EPISODE_INSIGHTS_GENERATED, async () => {
        await refetch();
      });
    }

    return () => {
      if (socket) {
        socket.off(WebSocketEventName.EPISODE_INSIGHTS_GENERATED);
      }
    };
  }, [socket]);

  return (
    <>
      {showShareEpisodeModal && episode && (
        <ShareEpisodeDialog
          episode={episode}
          handleClose={handleCloseShareEpisodeModal}
        />
      )}
      <SidebarView pageName={`Episode - ${episode?.name}`} />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "none",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            marginX: 4,
            marginTop: 10,
            height: "100%",
            maxHeight: "875px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <StyledTab
                label="Preview"
                icon={<ClosedCaption />}
                iconPosition="start"
                onClick={() => setSelectedTab("preview")}
              />
              <StyledTab
                label="Insights"
                icon={<InsightsIcon />}
                iconPosition="start"
                onClick={() => setSelectedTab("insights")}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 99,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ButtonView
                sx={{ mr: 1, fontSize: "10px" }}
                type="button"
                variant="contained"
                size="small"
                label="Shorts"
                startIcon={<OndemandVideoIcon />}
                href={`/episodes/${episodeId}/videos`}
              />
              <ButtonView
                sx={{ fontSize: "10px" }}
                type="button"
                variant="contained"
                size="small"
                label="Share Episode"
                startIcon={<ShareIcon />}
                disabled
                handleOnClick={handleOpenShareEpisodeModal}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            {selectedTab === "preview" && (
              <Box>{episodeId && <Preview episode={episode} />}</Box>
            )}
            {selectedTab === "insights" && (
              <Box>
                {episodeId && (
                  <Insights
                    insights={insights}
                    refetch={refetch}
                    episodeId={episodeId}
                  />
                )}
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
}
