import { coreService } from "../../../services";

interface Metadata {
  number_of_episodes: number;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface ProjectsWithMetadata extends Project {
  id: string;
  name: string;
  description: string;
  created_at: string;
  metadata: Metadata;
}

interface Pagination {
  per_page: number;
  total_pages: number;
  total_items: number;
  current_page: number;
  previous_page: number | null;
  next_page: number | null;
}

interface ProjectsResponse {
  projects: ProjectsWithMetadata[];
  pagination: Pagination;
}

interface ProjectsPayload {
  page?: number;
  perPage?: number;
}

export interface CreateProjectPayload {
  project_name: string;
  project_description: string;
}

export interface UpdateProjectPayload extends CreateProjectPayload {
  projectId: string;
}

const fetchProjects = async ({
  perPage = 20,
  page = 1,
}: ProjectsPayload): Promise<ProjectsResponse> => {
  const response = await coreService.httpClient.get(
    `/projects?page=${page}&per_page=${perPage}`,
  );

  return response.data;
};

const createProject = async ({
  project_name,
  project_description,
}: CreateProjectPayload): Promise<Record<string, any>> => {
  const response = await coreService.httpClient.post(
    `/projects`,
    {
      name: project_name,
      description: project_description,
    },
    true,
  );

  return response.data;
};

const updateProject = async ({
  projectId,
  project_name,
  project_description,
}: UpdateProjectPayload): Promise<Record<string, any>> => {
  const response = await coreService.httpClient.patch(
    `/projects/${projectId}`,
    {
      name: project_name,
      description: project_description,
    },
  );

  return response.data;
};

const deleteProject = async (
  projectId: string,
): Promise<Record<string, any>> => {
  const response = await coreService.httpClient.del(`/projects/${projectId}`);

  return response.data;
};

export { fetchProjects, createProject, updateProject, deleteProject };
