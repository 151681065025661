import axios from "axios";
import moment from "moment";

import { config } from "../config";
import {
  CookieKey,
  deleteCookie,
  getCookie,
  setCookie,
} from "../hooks/useCookie";
import GlobalErrorAlertService from "../modules/GlobalErrorAlert/services/global-error-alert.service";

const envConfig = config();
// Create a custom Axios instance with error handling
const axiosInstance = axios.create({
  baseURL: envConfig.apiUrl,
  withCredentials: true,
});

// Add a request interceptor to include the JWT token in requests
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    const newAxiosConfig = axiosConfig;
    const token = getCookie(CookieKey.JWT_TOKEN);
    if (token && axiosConfig.withCredentials) {
      newAxiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newAxiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const originalRequest = error.config;
      const refreshToken = getCookie(CookieKey.REFRESH_TOKEN);

      if (error.response.status === 500) {
        GlobalErrorAlertService.showAlert(
          "An internal server error occurred. Please try again later.",
        );
      }

      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        // if server returns 401 status code and the refresh token is not available then redirect to login
        if (!refreshToken) {
          window.location.href = "/login";
        } else {
          try {
            const response: any = await axiosInstance.post(
              "/auth/refresh-token",
              {
                refresh_token: refreshToken,
              },
            );

            setCookie(CookieKey.JWT_TOKEN, response.data.jwt_token, {
              expires: moment()
                .add(response.data.jwt_token_expires_in, "milliseconds")
                .toDate()
                .toUTCString(),
            });
            setCookie(CookieKey.REFRESH_TOKEN, response.data.refresh_token, {
              expires: moment()
                .add(response.data.refresh_token_expires_in, "milliseconds")
                .toDate()
                .toUTCString(),
            });
            originalRequest.headers.Authorization = `Bearer ${response?.data.jwt_token}`;
            return await axios(originalRequest);
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          } catch (err) {
            // deleteCookie
            deleteCookie(CookieKey.JWT_TOKEN);
            deleteCookie(CookieKey.REFRESH_TOKEN);
            return Promise.reject(error);
          }
        }
      }
    }
    // Return a rejected promise with the error object
    return Promise.reject(error.response.data);
  },
);

// Get function using Axios
const get = async (url: string, conf: any = {}) => {
  return axiosInstance.get(url, conf);
};

// Post function using Axios
const post = async (
  url: string,
  formData: Record<string, any>,
  withToken?: boolean,
) => {
  if (withToken) {
    return axiosInstance.post(url, formData);
  }
  return axiosInstance.post(url, formData);
};

const put = async (
  url: string,
  formData: any,
  options: Record<string, any>,
) => {
  return axiosInstance.put(url, formData, options);
};

const patch = async (url: string, formData: any) => {
  return axiosInstance.patch(url, formData);
};

const del = async (url: string) => {
  return axiosInstance.delete(url);
};

export default { post, get, put, patch, del };
