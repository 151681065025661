import React from "react";

import { Box, Card, Stack, Typography } from "@mui/material";

import SidebarView from "../common/Sidebar";
import ListOfEpisodesSharedWithMe from "./components/ListOfEpisodesSharedWithMe";

export default function EpisodeSharedWithMeView() {
  return (
    <>
      <SidebarView pageName="Episodes Shared With Me" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <Typography variant="h5" component="h1">
            List of Episodes that others have shared with me
          </Typography>
          <ListOfEpisodesSharedWithMe />
        </Card>
      </Box>
    </>
  );
}
