import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../common/Button";
import { useAuth } from "../../middlewares/Auth/AuthContext";
import { SetNewPasswordPayload } from "./services/set-new-password.service";
import { setNewPasswordService } from "./services";

interface SetNewPasswordFormProps {
  hash: string;
  handleNewPasswordSuccessfullySet: () => void;
}

export default function SetNewPasswordForm({
  hash,
  handleNewPasswordSuccessfullySet,
}: SetNewPasswordFormProps) {
  const auth = useAuth();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["set-new-password"],
    mutationFn: (values: SetNewPasswordPayload) =>
      setNewPasswordService.setNewPassword({
        password: values.password,
        forgot_password_hash: hash,
      }),
    onSuccess() {
      handleNewPasswordSuccessfullySet();
    },
    onError(error: AxiosError) {
      if (error.response?.status === 401) {
        auth.logout();
      }
    },
  });

  const handleSubmit = async (values: any) => {
    await mutateAsync(values);
  };

  if (isPending) {
    return (
      <Box
        sx={{ display: "flex", marginTop: "64px", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("Required"),
        confirmPassword: Yup.string().equals(
          [Yup.ref("password")],
          "Passwords must match",
        ),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            {/* Password Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                error={!!formik.errors.password}
                helperText={formik.errors.password}
                {...formik.getFieldProps("password")}
              />
            </Grid>
            {/* Confirm Password Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                error={!!formik.errors.confirmPassword}
                helperText={formik.errors.confirmPassword}
                {...formik.getFieldProps("confirmPassword")}
              />
            </Grid>
          </Grid>
          <Button
            label="Set New Password"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
        </Box>
      )}
    </Formik>
  );
}
