import React from "react";

import { Box, CssBaseline } from "@mui/material";

import EditShortVideo from "../modules/EditShortVideo";

function EditShortVideoPage() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <EditShortVideo />
    </Box>
  );
}

export default EditShortVideoPage;
