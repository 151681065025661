import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../common/Button";
import { forgotPasswordService } from "./services";

interface SignupFormProps {
  handlePasswordReset: (email: string) => void;
  email?: string;
}

export default function ForgotPasswordForm({
  handlePasswordReset,
  email = "",
}: SignupFormProps) {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["signup"],
    mutationFn: (values: { email: string }) =>
      forgotPasswordService.forgotPassword(values.email),
    onSuccess(_response: any, values: { email: string }) {
      handlePasswordReset(values.email);
    },
  });

  const handleSubmit = async (values: any) => {
    await mutateAsync(values);
  };

  if (isPending) {
    return (
      <Box
        sx={{ display: "flex", marginTop: "64px", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        email: email || "",
      }}
      validationSchema={Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                {...formik.getFieldProps("email")}
              />
            </Grid>
          </Grid>
          <Button
            label="Send Reset Link"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}
