import React from "react";

import { Button, TableCell, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import moment from "moment";

import { RoleName } from "../../../../common/enums/RoleName";
import { Column } from "../../../../common/Table/types";

interface Props {
  column: Column;
  value: string | boolean | RoleName[];
  handleCustomEvent: (event: string, value?: string) => void;
}

function SharedEpisodeCell({
  column,
  value,
  handleCustomEvent,
}: Props): JSX.Element {
  switch (column.id) {
    case "expires_at":
      return (
        <TableCell align={column.align}>
          <Typography
            sx={{
              color:
                value &&
                moment(String(value)).isValid() &&
                moment(String(value)).isBefore(moment())
                  ? "red"
                  : "inherit",
            }}
          >
            {value}
          </Typography>
        </TableCell>
      );
    case "edit":
      return (
        <TableCell align={column.align}>
          <Button
            sx={{ padding: 0 }}
            title="Edit"
            startIcon={<ModeEditIcon />}
            onClick={() => handleCustomEvent("edit_role")}
          >
            Edit
          </Button>
        </TableCell>
      );
    case "revoke_access":
      return (
        <TableCell align={column.align}>
          <Button
            sx={{ padding: 0 }}
            title="Revoke access (User won't be able to access this episode anymore)"
            startIcon={<DeleteIcon />}
            onClick={() => handleCustomEvent("revoke_access")}
          >
            Revoke access
          </Button>
        </TableCell>
      );
    default:
      return (
        <TableCell align={column.align}>
          <Typography>{value}</Typography>
        </TableCell>
      );
  }
}

export default SharedEpisodeCell;
