import React from "react";

import { useParams } from "react-router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import SetUpAccountForm from "./components/SetUpAccountForm";

export default function SetUpAccountView() {
  const { hash } = useParams();
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h4">
        Podcast Assistant
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          padding: "30px 0px 10px 0px",
        }}
      >
        <Avatar sx={{ marginRight: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ marginTop: 0.5 }}>
          Your account is almost ready
        </Typography>
      </Box>

      {hash && <SetUpAccountForm hash={hash} />}
    </Box>
  );
}
