/* eslint-disable import/no-cycle */
import React from "react";

import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import ActionsList from "./ActionsList";
import InsightsModalManager, {
  ModalType,
} from "../../../../common/ModalManager";
import { Category } from "../../../../common/types";
import formatWord from "../../../../../utils/formatWord";

interface TitleProp {
  title: string;
  titles: string[];
  category: Category;
  refetch?: () => void;
}
export default function Title({ title, titles, category, refetch }: TitleProp) {
  const [openInsightsModalManager, setOpenInsightsModalManager] =
    React.useState(false);
  const handleOpenInsightsModalManager = () =>
    setOpenInsightsModalManager(true);

  const handleCopy = () => {
    navigator.clipboard.writeText(title);
  };

  return (
    <Box>
      <InsightsModalManager
        open={openInsightsModalManager}
        setOpen={setOpenInsightsModalManager}
        modalType={ModalType.TITLES}
        modalTitle={`Edit ${formatWord(category)}`}
        category={category}
        objectValue={{ title }}
        values={titles}
        refetch={refetch || (() => {})}
      />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "20px",
        }}
      >
        <ListItem alignItems="flex-start">
          <ListItemText primary={title} />
          <ActionsList
            handleOpenInsightsModalManager={handleOpenInsightsModalManager}
            handleCopy={handleCopy}
          />
        </ListItem>
        <Divider sx={{ width: "100%" }} />
      </List>
    </Box>
  );
}
