import React, { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import WebIcon from "@mui/icons-material/Web";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ChatIcon from "@mui/icons-material/Chat";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import DescriptionIcon from "@mui/icons-material/Description";
import TagIcon from "@mui/icons-material/Tag";
import TitleIcon from "@mui/icons-material/Title";
import { Button, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@tanstack/react-query";

import {
  InsightsResponse,
  RegenerateInsightsResponse,
} from "../../services/episode.service";
import { formatLabel } from "../../../../utils/formatLabel";
import Quote from "./parts/Quote";
import Title from "./parts/Title";
import ChapterView from "./parts/ChapterView";
import ContentPost from "./parts/ContentPost";
import { Category } from "../../../common/types";
import { episodeService } from "../../services";
import RegenerateInsightsDialog from "./RegenerateInsightsModal";
import NewsletterPost from "./parts/NewsletterPost";

const StyledTabs = styled(Tabs)`
  &.MuiTabs-vertical {
    min-width: 160px;
    max-width: 160px;
  }
`;

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
  isRegeneratingInsights?: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, isRegeneratingInsights, ...other } = props;

  if (isRegeneratingInsights) {
    return (
      <Box
        sx={{ display: "flex", marginTop: "64px", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface Props {
  insights?: InsightsResponse | undefined;
  refetch: () => void;
  episodeId: string;
}

export type Name =
  | "tweets"
  | "chapters"
  | "blog_post"
  | "newsletter"
  | "quotes"
  | "titles"
  | "keywords"
  | "descriptions"
  | "linkedin_posts";

export default function Insights({ insights, refetch, episodeId }: Props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [isRegenerateInsightsModalOpen, setIsRegenerateInsightsModalOpen] =
    useState<boolean>(false);
  const [regeneratingInsightsForIndex, setRegeneratingInsightsForIndex] =
    useState<number | null>(null);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["regenerateInsights"],
    mutationFn: (values: RegenerateInsightsResponse) =>
      episodeService.regenerateInsights(episodeId, values),
  });

  const tabIcons = {
    tweets: ChatIcon,
    chapters: MenuBookIcon,
    blog_post: WebIcon,
    newsletter: NewspaperIcon,
    quotes: FormatQuoteIcon,
    linkedin_posts: LinkedInIcon,
    titles: TitleIcon,
    keywords: TagIcon,
    descriptions: DescriptionIcon,
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const tabNames: string[] = insights ? Object.keys(insights) : [];
  const tabs = tabNames.map((name: string) => {
    const Icon = tabIcons[name as Name];
    return {
      name,
      icon: Icon,
    };
  });

  const selectedTab = tabNames[selectedTabIndex];

  const handleRegenerateInsights = async (prompt?: string) => {
    setRegeneratingInsightsForIndex(selectedTabIndex);
    await mutateAsync({
      category: selectedTab,
      custom_prompt: prompt,
    });
    await refetch();
    setRegeneratingInsightsForIndex(null);
  };

  const handleReloadData = async () => {
    await refetch();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "100%",
      }}
    >
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {tabs.length > 0
          ? tabs.map((tab, index) => {
              const Icon = tab.icon;
              return (
                <Tab
                  key={tab.name}
                  label={formatLabel(tab.name)}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: "12px",
                    alignSelf: "start",
                  }}
                  icon={<Icon sx={{ fontSize: "18px" }} />}
                  iconPosition="start"
                />
              );
            })
          : null}
      </StyledTabs>
      <Box sx={{ maxHeight: "740px", overflowY: "scroll", width: "100%" }}>
        {selectedTab && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setIsRegenerateInsightsModalOpen(true);
            }}
            sx={{
              ml: 5.5,
              fontSize: "12px",
            }}
          >
            Regenerate {formatLabel(selectedTab)}
          </Button>
        )}
        <Box sx={{ width: "80%", mx: "auto" }}>
          <TabPanel
            value={selectedTabIndex}
            index={0}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 0
            }
          >
            {insights &&
              insights.quotes.length > 0 &&
              insights.quotes.map((quoteObject) => {
                return (
                  <Quote
                    key={quoteObject.timestamp}
                    quoteObject={quoteObject}
                    quotes={insights.quotes}
                    refetch={refetch}
                    category={Category.QUOTES}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={1}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 1
            }
          >
            {insights &&
              insights.titles.length > 0 &&
              insights.titles.map((title) => {
                return (
                  <Title
                    key={title}
                    category={Category.TITLES}
                    title={title}
                    titles={insights.titles}
                    refetch={refetch}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={2}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 2
            }
          >
            {insights &&
              insights.tweets.length > 0 &&
              insights.tweets.map((tweet) => {
                return (
                  <Title
                    key={tweet}
                    category={Category.TWEETS}
                    title={tweet}
                    titles={insights.tweets}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={3}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 3
            }
          >
            {insights &&
              insights.chapters.length > 0 &&
              insights.chapters.map((chapter) => {
                return (
                  <ChapterView
                    key={chapter.title}
                    chapters={insights.chapters}
                    chapterObject={chapter}
                    category={Category.CHAPTERS}
                    refetch={handleReloadData}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={4}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 4
            }
          >
            {insights &&
              insights.keywords.length > 0 &&
              insights.keywords.map((keyword) => {
                return (
                  <Title
                    key={keyword}
                    category={Category.KEYWORDS}
                    title={`#${keyword}`}
                    titles={insights.keywords}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={5}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 5
            }
          >
            {insights && insights.blog_post && (
              // eslint-disable-next-line react/no-children-prop
              <ContentPost
                title="Blog Post"
                content={insights.blog_post}
                refetch={refetch}
                category={Category.BLOGPOST}
              />
            )}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={6}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 6
            }
          >
            {insights && insights.newsletter && (
              // eslint-disable-next-line react/no-children-prop, react/jsx-no-undef
              <NewsletterPost
                title="Newsletter"
                content={insights.newsletter}
                refetch={refetch}
                category={Category.NEWSLETTER}
              />
            )}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={7}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 7
            }
          >
            {insights &&
              insights.linkedin_posts.length > 0 &&
              insights.linkedin_posts.map((linkedinPost) => {
                return (
                  <Title
                    key={linkedinPost}
                    category={Category.LINKEDINPOSTS}
                    title={linkedinPost}
                    titles={insights.linkedin_posts}
                  />
                );
              })}
          </TabPanel>
          <TabPanel
            value={selectedTabIndex}
            index={8}
            isRegeneratingInsights={
              isPending && regeneratingInsightsForIndex === 8
            }
          >
            {insights &&
              insights.descriptions.length > 0 &&
              insights.descriptions.map((description) => {
                return (
                  <Title
                    category={Category.DESCRIPTIONS}
                    key={description}
                    title={description}
                    titles={insights.descriptions}
                  />
                );
              })}
          </TabPanel>
        </Box>
      </Box>
      {selectedTab && (
        <RegenerateInsightsDialog
          selectedTab={selectedTab}
          open={isRegenerateInsightsModalOpen}
          onClose={() => setIsRegenerateInsightsModalOpen(false)}
          onRegenerate={handleRegenerateInsights}
        />
      )}
    </Box>
  );
}
