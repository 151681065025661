import React, { useEffect, useState } from "react";

import { Stage, Layer } from "react-konva";
import { Box, Button } from "@mui/material";
import { ScreenShareOutlined } from "@mui/icons-material";
import { useParams } from "react-router";

import Ruler from "./components/Ruler";
import Row from "./components/Row";
import VerticalLine from "./components/VerticalLine/VerticalLine";
import { IElement } from "./components/Element/types/IElement";
import Zoom from "./components/Zoom";
import Controls from "./components/Controls";
import {
  ExportVideoCompressionLevel,
  ExportVideoResolution,
  VideoResponse,
} from "../../EditShortVideo/types";
import ExportVideoModal from "../../EditShortVideo/components/ExportVideoModal";
import { editShortVideoService } from "../../EditShortVideo/services";

const minimumTime = 60000; // 60 seconds in milliseconds

interface TimelineProps {
  video: VideoResponse;
  currentTimeInMilliseconds: number;
  isPlaying: boolean;
  onPlayPause: () => void;
  setCurrentTimeInMilliseconds: (time: number) => void;
  elements: IElement[];
  setElements: (elements: IElement[]) => void;
}

export default function Timeline({
  video,
  currentTimeInMilliseconds,
  isPlaying,
  onPlayPause,
  setCurrentTimeInMilliseconds,
  elements,
  setElements,
}: TimelineProps) {
  const { videoId } = useParams();
  // const [elements, setElements] = useState<IElement[]>(initialElements);
  // initial vertical line position in milliseconds
  // const [verticalLineXInMs, setVerticalLineXInMs] = useState(20000);
  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const maxTime = Math.max(
    minimumTime,
    ...elements.map((el) => el.start + el.width),
  );
  const totalMilliseconds = Math.ceil(maxTime);

  // Recalculate pixels per millisecond based on the zoom level
  const pixelsPerMillisecond =
    (window.innerWidth / totalMilliseconds) * zoomLevel;

  // Determine the maximum scrollable width of the timeline
  const timelineWidth = totalMilliseconds * pixelsPerMillisecond;

  // Event handler for zoom change
  const handleZoomChange = (zoom: number) => {
    setZoomLevel(Math.min(5, Math.max(0.1, zoom))); // Restrict zoom level between 0.1 and 5
  };

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        // `ctrlKey` for Windows/Linux, `metaKey` for Mac (Cmd key)
        e.preventDefault();
        const newZoomLevel = zoomLevel - e.deltaY * 0.001; // Adjust the zoom sensitivity as needed
        handleZoomChange(newZoomLevel);
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [zoomLevel]);

  const rows = elements.reduce((acc, element) => {
    const { row } = element;
    if (!acc[row - 1]) {
      acc[row - 1] = [];
    }
    acc[row - 1].push(element);
    return acc;
  }, [] as IElement[][]);

  // always add an empty row
  rows.push([]);

  const handleElementChange = (element: IElement) => {
    let updatedElements = elements.map((el) =>
      el.id === element.id ? element : el,
    );

    const isDragging = updatedElements.some((el) => el.isDragging);
    // if isDragging is false that means no element is being dragged so we can check for overlapping elements
    if (!isDragging) {
      const overlappingElement = updatedElements.find(
        (otherEl) =>
          element.id !== otherEl.id &&
          element.row === otherEl.row &&
          element.start < otherEl.start + otherEl.width &&
          element.start + element.width > otherEl.start,
      );
      if (overlappingElement) {
        const newElementStart = Math.max(
          0,
          overlappingElement.start + overlappingElement.width,
        );
        updatedElements = updatedElements.map((el) =>
          el.id === element.id ? { ...el, start: newElementStart } : el,
        );
      }
    }
    setElements(updatedElements);
  };

  const handleVerticalLineDrag = (x: number) => {
    setCurrentTimeInMilliseconds(Math.max(0, x / pixelsPerMillisecond));
  };

  const handleExportVideo = async (
    videoResolution: ExportVideoResolution,
    compressionLevel: ExportVideoCompressionLevel,
    fps: number,
  ): Promise<void> => {
    if (videoId) {
      await editShortVideoService.exportShortVideo(videoId, {
        compression_level: compressionLevel,
        video_resolution: videoResolution,
        fps,
      });
      setShowExportModal(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          border: "1px solid #ddd",
          borderBottom: "0px solid #ddd",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Controls
            isPlaying={isPlaying}
            currentTimestamp={currentTimeInMilliseconds}
            durationInMilliseconds={video.duration_in_milliseconds}
            onPlayPause={onPlayPause}
            onPrevious={() =>
              setCurrentTimeInMilliseconds(
                Math.max(0, currentTimeInMilliseconds - 100),
              )
            }
            onNext={() =>
              setCurrentTimeInMilliseconds(
                Math.min(
                  video.duration_in_milliseconds,
                  currentTimeInMilliseconds + 100,
                ),
              )
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Zoom zoomLevel={zoomLevel} onZoomChange={handleZoomChange} />
        </Box>
        <Box
          sx={{
            display: "flex",
            px: 1,
            pl: 20,
          }}
        >
          <Button
            sx={{
              alignSelf: "center",
            }}
            size="small"
            onClick={() => setShowExportModal(true)}
            variant="outlined"
            title="Edit Roles"
            startIcon={<ScreenShareOutlined />}
          >
            Export
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          overflowX: "scroll",
          border: "1px solid #ddd",
          borderTop: "0px solid #ddd",
          position: "relative",
        }}
      >
        <Box sx={{ width: timelineWidth, height: 250 }}>
          <Stage width={timelineWidth} height={250}>
            <Layer>
              <Ruler
                totalMilliseconds={maxTime}
                pixelsPerMillisecond={pixelsPerMillisecond}
              />
              {rows.map((row, rowIndex) => (
                <Row
                  key={row.map((el) => el.id).join("-")}
                  elements={elements.filter((el) => el.row === rowIndex + 1)}
                  rowIndex={rowIndex}
                  onElementChange={handleElementChange}
                  pixelsPerMillisecond={pixelsPerMillisecond}
                  numberOfRows={rows.length}
                />
              ))}
              <VerticalLine
                x={currentTimeInMilliseconds * pixelsPerMillisecond}
                onDrag={handleVerticalLineDrag}
              />
            </Layer>
          </Stage>
        </Box>
      </Box>
      <ExportVideoModal
        open={showExportModal}
        handleClose={() => setShowExportModal(false)}
        handleExport={handleExportVideo}
      />
    </>
  );
}
