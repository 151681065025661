import React, { useState } from "react";

import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "@tanstack/react-query";

import { pricingPlanService } from "./services";
import { PricingPlan } from "./types";
import PricingPlanCard from "./components/PricingPlanCard";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#f5f5f5",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  borderRadius: "5px",
  padding: "10px 60px",
}));

// Discount badge styling
const DiscountBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    right: 90,
    top: 0,
    backgroundColor: "#4caf50",
    color: "#fff",
    fontSize: "0.75rem",
    padding: "4px 8px",
    width: "80%",
  },
});

interface Props {
  handleOpenPricingPlanDialog: () => void;
  open: boolean;
}

export default function PricingPlaneDialog({
  handleOpenPricingPlanDialog,
  open,
}: Props) {
  const [plan, setPlan] = useState("yearly");

  const handlePlanChange = (event: any, newPlan: any) => {
    if (newPlan !== null) {
      setPlan(newPlan);
    }
  };

  const { data: pricingPlans } = useQuery({
    queryKey: ["getBillingPlans"],
    queryFn: () => pricingPlanService.getPricingPlans(),
  });

  const getPricesByInterval = (plans: PricingPlan[], interval: string) => {
    return plans.map((planItem: PricingPlan) => {
      const priceDetail = planItem.prices.find(
        (price) => price.recurring_interval === interval,
      );
      return {
        id: planItem.id,
        name: planItem.name,
        price_in_cents: priceDetail
          ? (priceDetail.price_in_cents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : null,
        description: planItem.description,
        recurring_interval: priceDetail?.recurring_interval,
        features: planItem.features,
        is_allowed_to_purchase: priceDetail?.is_allowed_to_purchase,
      };
    });
  };

  const pricingPlansData = getPricesByInterval(pricingPlans || [], plan);

  return (
    <Box>
      <BootstrapDialog open={open} maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography gutterBottom>Upgrade Plan / Pick a plan</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOpenPricingPlanDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ margin: "0 auto", padding: 0 }}>
            {/* Billing Cycle Toggle */}
            <Box display="flex" justifyContent="center" my={4}>
              <ToggleButtonGroup
                value={plan}
                exclusive
                onChange={handlePlanChange}
                aria-label="Plan selection"
                size="large"
              >
                {/* Yearly Plan with Discount */}
                <DiscountBadge badgeContent="-15% Discount">
                  <CustomToggleButton value="yearly" aria-label="yearly">
                    Yearly
                  </CustomToggleButton>
                </DiscountBadge>

                {/* Monthly Plan */}
                <CustomToggleButton value="monthly" aria-label="monthly">
                  Monthly
                </CustomToggleButton>
              </ToggleButtonGroup>
            </Box>

            <PricingPlanCard pricingPlansData={pricingPlansData} />
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
