import { coreService } from "../../../services";

const confirmAccount = async (
  hash: string | undefined,
): Promise<unknown | undefined> => {
  if (hash) {
    const response = await coreService.httpClient.get(
      `/auth/confirm-email/${hash}`,
    );
    return response.data;
  }
  return undefined;
};

export { confirmAccount };
