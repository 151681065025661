import { coreService } from "../../../services";

const uploadFileToS3 = async (
  file: File,
  preSignedUrl: string,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = async () => {
        try {
          const buffer = fileReader.result;
          const res = await coreService.httpClient.put(preSignedUrl, buffer, {
            headers: {
              "Content-Type": file.type || "application/octet-stream",
            },
            withCredentials: false,
            onUploadProgress, // Pass the callback here
          });

          resolve(res);
        } catch (error) {
          reject(error);
        }
      };
      fileReader.onerror = (e) => {
        reject(e);
      };

      fileReader.readAsArrayBuffer(file);
    } catch (e) {
      reject(e);
    }
  });
};

const getS3PreSignedUrl = async (
  formData: Record<string, any>,
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) => {
  const response = await coreService.httpClient.post(
    "/episodes/pre-signed-url",
    {
      ...formData,
      duration_in_seconds: formData.duration_in_seconds || 0,
    },
    true,
  );
  // Upload file to S3 with progress tracking
  return uploadFileToS3(file, response.data.url, onUploadProgress);
};

export { uploadFileToS3, getS3PreSignedUrl };
