import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import moment from "moment";

import Button from "../common/Button";
import { useAuth } from "../../middlewares/Auth/AuthContext";
import { CookieKey, setCookie } from "../../hooks/useCookie";
import { SigninResponse, SinginPayload } from "./services/login.service";
import { loginService } from "./services";

export default function LoginForm() {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["singin"],
    mutationFn: (values: SinginPayload) => loginService.singin(values),
    onSuccess(response: SigninResponse) {
      setCookie(CookieKey.JWT_TOKEN, response.jwt_token, {
        expires: moment()
          .add(response.jwt_token_expires_in, "milliseconds")
          .toDate()
          .toUTCString(),
      });
      setCookie(CookieKey.REFRESH_TOKEN, response.refresh_token, {
        expires: moment()
          .add(response.refresh_token_expires_in, "milliseconds")
          .toDate()
          .toUTCString(),
      });
      auth.login();
    },
    onError(error: any) {
      if (error?.statusCode === 403) {
        setErrorMessage(
          "Invalid request. Please verify your credentials or confirm that your account is activated.",
        );
      }
    },
  });

  const handleSubmit = async (
    values: { email: string; password: string },
    { setSubmitting }: any,
  ) => {
    setErrorMessage(null);
    try {
      await mutateAsync(values);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "", remember_me: false }}
      validationSchema={Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().min(8).required("Required"),
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            autoComplete="email"
            autoFocus
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            {...formik.getFieldProps("email")}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            {...formik.getFieldProps("password")}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={formik.values.remember_me}
                onChange={formik.handleChange}
                name="remember_me"
              />
            }
            label="Remember me"
          />
          {/* Display Loading Indicator */}
          {isPending && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          <Button
            label="Sign In"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isPending || formik.isSubmitting}
          />
          <Grid container>
            <Grid item xs>
              {formik.values?.email?.length > 0 && !formik.errors.email ? (
                <Link
                  href={`/forgot-password?email=${formik.values.email}`}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              ) : (
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              )}
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
