export const toHHMMSS = (sec: string) => {
  const times = sec.split("-");
  const formattedTimes = times.map((time) => {
    // Split time into hours, minutes, and seconds (with possible milliseconds)
    const parts = time.split(":");
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (parts.length === 3) {
      // Format: hh:mm:ss or hh:mm:ss.milliseconds
      hours = parseInt(parts[0], 10) || 0;
      minutes = parseInt(parts[1], 10) || 0;
      seconds = Math.floor(parseFloat(parts[2])) || 0;
    } else if (parts.length === 2) {
      // Format: mm:ss or mm:ss.milliseconds
      minutes = parseInt(parts[0], 10) || 0;
      seconds = Math.floor(parseFloat(parts[1])) || 0;
    } else if (parts.length === 1) {
      // Format: ss or ss.milliseconds
      seconds = Math.floor(parseFloat(parts[0])) || 0;
    }

    // Handle overflow (e.g., 90 seconds -> 1 minute, 30 seconds)
    minutes += Math.floor(seconds / 60);
    seconds %= 60;

    hours += Math.floor(minutes / 60);
    minutes %= 60;

    // Format as hh:mm:ss
    return [hours, minutes, seconds]
      .map((v) => v.toString().padStart(2, "0"))
      .join(":");
  });

  return formattedTimes.join(" - ");
};

export const convertSecondsToHoursMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return {
    hours,
    minutes,
  };
};
