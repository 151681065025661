import React from "react";

import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Chip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Column } from "../../../../common/Table/types";
import { EpisodeStatus } from "../../../../Episode/enums/EpisodeStatus";

interface TableListProps {
  columns: Column[];
  row: Record<string, any>;
}

interface TableCellProps {
  column: Column;
  row: Record<string, any>;
  value: string;
}

function EpisodeCell({ column, row, value }: TableCellProps): JSX.Element {
  const handleEpisodeClick = (episodeId: string) => {
    window.location.href = `episodes/${episodeId}`;
  };

  if (column.id === "view" && row.status === EpisodeStatus.READY) {
    return (
      <TableCell key={column.id} align={column.align}>
        <IconButton onClick={() => handleEpisodeClick(row.id)}>
          <VisibilityIcon />
        </IconButton>
      </TableCell>
    );
  }
  if (column.id === "status") {
    return (
      <TableCell key={column.id} align={column.align}>
        <Chip
          label={value}
          color={value === "ready" ? "success" : "warning"}
          size="small"
          variant="outlined"
        />
      </TableCell>
    );
  }
  return (
    <TableCell key={column.id} align={column.align}>
      <Typography>
        {column.format && typeof value === "number"
          ? column.format(value)
          : value}
      </Typography>
    </TableCell>
  );
}

export default function TableList({ columns, row }: TableListProps) {
  return (
    <TableRow role="checkbox" tabIndex={-1}>
      {columns.map((column: Column) => {
        const value = row[column.id];
        return (
          <EpisodeCell
            key={column.id}
            column={column}
            row={row}
            value={value}
          />
        );
      })}
    </TableRow>
  );
}
