import { coreService } from "../../../services";
import { RoleName } from "../../common/enums/RoleName";
import { Pagination } from "../../common/types/Pagination";
import { PaginationQueryRequest } from "../../common/types/PaginationQueryRequest";

export interface OrganizationUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  created_at: string;
  roles: RoleName[];
}

interface GetOrganizationUsersResponse {
  users: OrganizationUser[];
  pagination: Pagination;
}

export interface AddUserToOrganizationPayload {
  email: string;
  roles: RoleName[];
}

export interface SetUserActivePayload {
  id: string;
  isActive: boolean;
}

export interface UpdateUsersRolePayload {
  id: string;
  roles: RoleName[];
}

export interface ConfirmEmailAndSetPasswordPayload {
  hash: string;
  password: string;
  first_name: string;
  last_name: string;
}

const fetchOrganizationUsers = async ({
  perPage = 20,
  page = 1,
}: PaginationQueryRequest): Promise<GetOrganizationUsersResponse> => {
  const response = await coreService.httpClient.get(
    `/organizations/users?page=${page}&per_page=${perPage}`,
  );

  return response.data;
};

const adduserToOrganization = async (
  payload: AddUserToOrganizationPayload,
): Promise<void> => {
  await coreService.httpClient.post(`/organizations/users`, payload);
};

const setIsUserActive = async (data: SetUserActivePayload): Promise<void> => {
  await coreService.httpClient.patch(
    `/organizations/users/${data.id}/is-active`,
    {
      is_active: data.isActive,
    },
  );
};

const updateUsersRole = async (data: UpdateUsersRolePayload): Promise<void> => {
  await coreService.httpClient.patch(`/organizations/users/${data.id}/roles`, {
    roles: data.roles,
  });
};

const deleteUser = async (id: string): Promise<void> => {
  await coreService.httpClient.del(`/organizations/users/${id}`);
};

const confirmEmailAndSetPassword = async (
  data: ConfirmEmailAndSetPasswordPayload,
): Promise<void> => {
  await coreService.httpClient.post(
    `/organizations/users/confirm-email-and-set-password/${data.hash}`,
    {
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
    },
  );
};

export {
  fetchOrganizationUsers,
  adduserToOrganization,
  setIsUserActive,
  updateUsersRole,
  deleteUser,
  confirmEmailAndSetPassword,
};
