/* eslint-disable react/require-default-props */
import React from "react";

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { SidebarListProps } from "../../types";

interface Props {
  sidebarList: SidebarListProps[];
}

export default function SidebarList({ sidebarList }: Props) {
  return (
    <List component="nav" sx={{ marginTop: "10px", px: 1 }}>
      <>
        {sidebarList.map((item) => {
          return (
            <Box key={item.name}>
              <ListItemButton
                disabled={item.disabled}
                sx={{
                  paddingBottom: "16px",
                  paddingTop: "16px",
                  backgroundColor: item.isSelected
                    ? (theme) => theme.palette.grey[50]
                    : "",
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.grey[50],
                  },
                }}
                href={item.link}
              >
                <ListItemIcon
                  sx={{
                    boxShadow: 2,
                    padding: 1.5,
                    backgroundColor: (theme) => theme.palette.grey[50],
                    color: item.isSelected ? "#fff" : "#000",
                    minWidth: "auto",
                    borderRadius: 2,
                    background: (theme) =>
                      item.isSelected ? theme.palette.info.main : "",
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: item.isSelected ? "medium" : "normal",
                    letterSpacing: 0,
                    marginLeft: 2,
                  }}
                  primary={item.name}
                />
              </ListItemButton>
              {item.subMenu &&
                item.subMenu.some(
                  (subMenu: SidebarListProps) => subMenu.isSelected,
                ) && (
                  <List>
                    {item.subMenu.map((subItem: SidebarListProps) => (
                      <ListItemButton
                        key={subItem.name}
                        sx={{
                          paddingLeft: "40px",
                          backgroundColor: subItem.isSelected
                            ? (theme) => theme.palette.info.main
                            : "",
                        }}
                        href={subItem.link}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.name} />
                      </ListItemButton>
                    ))}
                  </List>
                )}
            </Box>
          );
        })}
      </>
    </List>
  );
}
