import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Stack, Typography, styled } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Props {
  title: string;
  value: string | number;
  Icon: React.ElementType;
  color: string;
  disabled: boolean;
}

const StyledStack = styled(Stack)`
  &.MuiStack-root {
    overflow: visible;
  }
`;

export default function CardView({
  title,
  value,
  Icon,
  color,
  disabled,
}: Props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      component={StyledStack}
      spacing={6}
      direction="row"
      sx={{
        position: "relative",
        px: 2,
        py: 1,
        borderRadius: 2,
        marginBottom: `${mobile ? "20px" : "0px"}`,
        opacity: disabled ? "0.4" : 1,
        maxWidth: `${mobile ? "100%" : "25%"}`,
        flexBasis: "20%",
        justifyContent: "space-between",
      }}
    >
      <Stack spacing={0} sx={{ alignSelf: "center" }}>
        <Typography variant="body1" sx={{ fontSize: "12px" }}>
          {title}
        </Typography>
        <Typography variant="h5">{value}</Typography>
      </Stack>
      <Box sx={{ alignSelf: "center" }}>
        <Icon
          sx={{
            fontSize: "52px",
            color: "#ffffff",
            backgroundColor: color,
            padding: "12px",
            borderRadius: 2,
          }}
        />
      </Box>
    </Card>
  );
}
