import React, { ChangeEvent, useState } from "react";

import { Box, TextField } from "@mui/material";

interface Props {
  name: string;
  values: any;
  onUpdateText: (text: string) => void;
  isCurrentParagraph: boolean;
  onParagraphClick: () => void;
}

const InputField = React.forwardRef<HTMLDivElement, Props>(
  (
    { name, values, onUpdateText, isCurrentParagraph, onParagraphClick },
    ref,
  ) => {
    const [localValue, setLocalValue] = useState<string>(values[name]);
    const [debounceTimeout, setDebounceTimeout] =
      useState<NodeJS.Timeout | null>(null);

    const debouncedUpdateText = (text: string) => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
      const timeout = setTimeout(() => {
        onUpdateText(text);
      }, 500);
      setDebounceTimeout(timeout);
    };

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setLocalValue(e.target.value);
      debouncedUpdateText(e.target.value);
    };

    return (
      <Box
        ref={ref}
        sx={{
          pr: 6,
        }}
      >
        <TextField
          margin="normal"
          variant="standard"
          sx={{
            cursor: "pointer",
            backgroundColor: isCurrentParagraph
              ? "rgba(0, 0, 0, 0.1)"
              : "transparent",
            "&:hover": {
              borderLeft: "2px solid gray",
              pl: 1,
            },
          }}
          multiline
          fullWidth
          name={name}
          value={localValue}
          onChange={handleChange}
          onClick={onParagraphClick}
        />
      </Box>
    );
  },
);

export default InputField;
