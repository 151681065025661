import React from "react";

import { Box } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import Markdown from "react-markdown";

import ActionsList from "./ActionsList";
import InsightsModalManager, {
  ModalType,
} from "../../../../common/ModalManager";
import { Category } from "../../../../common/types";

interface ContentPostProp {
  title: string;
  content: string;
  refetch: () => void;
  category: Category;
}
export default function ContentPost({
  title,
  content,
  refetch,
  category,
}: ContentPostProp) {
  const [openInsightsModalManager, setOpenInsightsModalManager] =
    React.useState(false);
  const handleOpenInsightsModalManager = () =>
    setOpenInsightsModalManager(true);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
  };

  return (
    <Box>
      <InsightsModalManager
        open={openInsightsModalManager}
        setOpen={setOpenInsightsModalManager}
        modalType={ModalType.BLOGPOST}
        modalTitle={`${title}`}
        objectValue={{ title: content }}
        refetch={refetch || (() => {})}
        category={category}
      />
      <Box sx={{ paddingBottom: "42px" }}>
        <ActionsList
          handleOpenInsightsModalManager={handleOpenInsightsModalManager}
          handleCopy={handleCopy}
        />
      </Box>
      <Markdown>{content}</Markdown>
    </Box>
  );
}
