import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Column } from "./types";

type HandleClick = (
  event: React.MouseEvent<HTMLButtonElement>,
  value: string,
) => void;

type CustomEvent = (event: string, value?: string) => void;

interface TableProps {
  columns: Column[];
  rows: Array<Record<string, any>>;
  TableList: React.ComponentType<{
    row: Record<string, any>;
    columns: Column[];
    handleClick: HandleClick;
    handleCustomEvent: CustomEvent;
  }>;
  handleClick: HandleClick;
  handleCustomEvent: CustomEvent;
}

export default function TableView({
  columns,
  rows,
  handleClick,
  handleCustomEvent,
  TableList,
}: TableProps): JSX.Element {
  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length
          ? rows.map((row: Record<string, any>) => {
              return (
                <TableList
                  key={row.id}
                  row={row}
                  columns={columns}
                  handleClick={handleClick}
                  handleCustomEvent={handleCustomEvent}
                />
              );
            })
          : null}
      </TableBody>
    </Table>
  );
}
