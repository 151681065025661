import { coreService } from "../../../../services";
import { AuthenticatedUserResponse } from "../../Sidebar/types";

const fetchAuthenticardUser = async (): Promise<AuthenticatedUserResponse> => {
  const response = await coreService.httpClient.get(
    "/users/authenticated-user",
  );

  return response.data;
};

export { fetchAuthenticardUser };
