import * as React from "react";

import Container from "@mui/material/Container";

import Footer from "../modules/common/Footer";
import SetUpAccountView from "../modules/SetUpAccount";

export default function SetUpAccount() {
  return (
    <Container component="main" maxWidth="xs">
      <SetUpAccountView />
      <Footer />
    </Container>
  );
}
