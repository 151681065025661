import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { SubtitleStyles } from "../../../../types";
import StyleSelectorButtons, { StyleType } from "./StyleSelectorButtons";
import TextStyle from "./TextStyle";
import TextEffect from "./TextEffect";

interface SubtitleStyleEditorProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function StyleEditor({
  styles,
  onUpdateStyles,
}: SubtitleStyleEditorProps) {
  const [styleType, setStyleType] = useState<StyleType>(StyleType.TEXT);

  return (
    <Box sx={{ pt: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }} gutterBottom>
        Edit Subtitle Styles
      </Typography>
      <Box
        sx={{
          my: 2,
        }}
      >
        <StyleSelectorButtons
          selectedStyleType={styleType}
          onStyleTypeChange={setStyleType}
        />
      </Box>
      {styleType === StyleType.TEXT && (
        <TextStyle styles={styles} onUpdateStyles={onUpdateStyles} />
      )}
      {styleType === StyleType.EFFECT && (
        <TextEffect styles={styles} onUpdateStyles={onUpdateStyles} />
      )}
    </Box>
  );
}
