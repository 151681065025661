import { coreService } from "../../../services";
import { Project } from "../../Project/services/projects.service";

export interface Quote {
  quote: string;
  context: string;
  speaker: string;
  timestamp: string;
}

export interface Chapter {
  title: string;
  start: string;
  end: string;
}

export interface InsightsResponse {
  quotes: Quote[];
  titles: string[];
  tweets: string[];
  chapters: Chapter[];
  keywords: string[];
  blog_post: string;
  newsletter: string;
  linkedin_posts: string[];
  descriptions: string[];
}

export interface Upload {
  id: string;
  key: string;
  url?: string;
  mime_type: string;
  size: number;
  created_at?: string;
}

export interface Paragraph {
  end: number;
  text: string;
  start: number;
  speaker: number;
}

export interface Episode {
  id: string;
  name: string;
  project?: Project;
  upload: Upload;
  created_at?: string;
  duration_in_milliseconds: number;
  paragraphs?: Paragraph[];
}

export interface RegenerateInsightsResponse {
  category: string;
  custom_prompt?: string;
}

const getEpisodeById = async (
  episodeId: string | undefined,
): Promise<Episode | undefined> => {
  if (episodeId) {
    const response = await coreService.httpClient.get(`/episodes/${episodeId}`);
    return response.data;
  }
  return undefined;
};

const fetchInsights = async (
  episodeId: string | undefined,
): Promise<InsightsResponse | undefined> => {
  if (episodeId) {
    const response = await coreService.httpClient.get(
      `/episodes/${episodeId}/insights`,
    );

    return response.data;
  }

  return undefined;
};

const updateInsights = async (
  episodeId: string | undefined,
  payload: Record<string, any>,
): Promise<InsightsResponse | undefined> => {
  if (episodeId) {
    const response = await coreService.httpClient.patch(
      `/episodes/${episodeId}/insights`,
      payload,
    );

    return response.data;
  }

  return undefined;
};

const regenerateInsights = async (
  episodeId: string,
  payload: RegenerateInsightsResponse,
): Promise<InsightsResponse> => {
  const response = await coreService.httpClient.post(
    `/episodes/${episodeId}/insights`,
    payload,
  );
  return response.data;
};

const exportSrtByEpisodeId = async (
  episodeId: string,
): Promise<InsightsResponse> => {
  const response = await coreService.httpClient.get(
    `/episodes/${episodeId}/export-srt`,
    { responseType: "blob" },
  );
  return response.data;
};

export {
  fetchInsights,
  getEpisodeById,
  updateInsights,
  regenerateInsights,
  exportSrtByEpisodeId,
};
