import { coreService } from "../../../services";
import { Pagination } from "../../common/types/Pagination";
import { ProjectsWithMetadata } from "../../Project/services/projects.service";

export interface Projects {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

interface ProjectsResponse {
  projects: ProjectsWithMetadata[];
  pagination: Pagination;
}

const fetchProjects = async (): Promise<ProjectsResponse> => {
  const response = await coreService.httpClient.get(`/projects`);

  return response.data;
};

export { fetchProjects };
