import React from "react";

import { TableRow } from "@mui/material";

import { Column } from "../../../../common/Table/types";
import SharedEpisodeCell from "./SharedEpisodeCell";

interface Props {
  columns: Column[];
  row: Record<string, any>;
  handleCustomEvent: (event: string, value?: string) => void;
}

export default function SharedEpisodeRow({
  columns,
  row,
  handleCustomEvent,
}: Props) {
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map((column) => (
        <SharedEpisodeCell
          key={column.id}
          column={column}
          value={row[column.id]}
          handleCustomEvent={(event) => handleCustomEvent(event, row.id)}
        />
      ))}
    </TableRow>
  );
}
