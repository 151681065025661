import React, { useEffect, useCallback, useMemo } from "react";

import Box from "@mui/material/Box";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { CookieKey, setCookie } from "../../hooks/useCookie";
import { useAuth } from "../../middlewares/Auth/AuthContext";

export default function AppleLoginCallbackPage() {
  // Memoize queryParams to prevent it from being recreated on every render
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const auth = useAuth();
  const navigate = useNavigate();

  // Remove duplicate dependency queryParams
  const handelAppleLogin = useCallback(async (): Promise<void> => {
    try {
      if (queryParams.get("jwt_token") && queryParams.get("refresh_token")) {
        setCookie(CookieKey.JWT_TOKEN, queryParams.get("jwt_token"), {
          expires: moment()
            .add(queryParams.get("jwt_token_expires_in"), "milliseconds")
            .toDate()
            .toUTCString(),
        });
        setCookie(CookieKey.REFRESH_TOKEN, queryParams.get("refresh_token"), {
          expires: moment()
            .add(queryParams.get("refresh_token_expires_in"), "milliseconds")
            .toDate()
            .toUTCString(),
        });
      }
      auth.login();
      navigate("/");
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    } catch (e) {
      navigate("/login");
    }
  }, [queryParams, auth, navigate]);

  useEffect(() => {
    if (queryParams) {
      handelAppleLogin();
    }
  }, [queryParams, handelAppleLogin]);

  return <Box />;
}
