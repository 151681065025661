import React from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Alert,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Dayjs } from "dayjs";

import CustomButton from "../../../common/Button";
import { RoleName } from "../../../common/enums/RoleName";
import { sharedEpisodeService } from "../../../SharedEpisodes/services";
import { ShareEpisodePayload } from "../../../SharedEpisodes/services/shared-episodes.service";
import { Episode } from "../../services/episode.service";
import DatePickerValue from "../../../SharedEpisodes/components/EditSharedEpisodeDialog/parts/DatePicker";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  episode: Episode;
  handleClose: () => void;
}

export default function ShareEpisodeDialog({ episode, handleClose }: Props) {
  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["share-episode"],
    mutationFn: (data: ShareEpisodePayload) =>
      sharedEpisodeService.shareEpisode(data),
  });

  const handleShareEpisode = async (data: ShareEpisodePayload) => {
    await mutateAsync({
      ...data,
      episode_id: episode.id,
    });
  };

  if (isSuccess) {
    handleClose();
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Share Episode
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          initialValues={{
            episode_id: episode.id,
            email: "",
            role: RoleName.VIEWER,
            expires_at: undefined,
          }}
          validationSchema={Yup.object({
            email: Yup.string().required("Required"),
          })}
          onSubmit={handleShareEpisode}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isError ? (
                  <Alert severity="error" sx={{ marginBottom: "12px" }}>
                    {error.message}
                  </Alert>
                ) : null}

                {isSuccess ? (
                  <Alert severity="success" sx={{ marginBottom: "12px" }}>
                    Episode shared successfully!
                  </Alert>
                ) : null}
                <TextField
                  margin="normal"
                  label="Email"
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                  {...formik.getFieldProps("email")}
                />
                <FormControl
                  required
                  error={!!formik.errors.role}
                  component="fieldset"
                  sx={{ mt: 2 }}
                  variant="standard"
                >
                  <FormLabel component="legend" sx={{ textAlign: "left" }}>
                    Role
                  </FormLabel>
                  <FormGroup>
                    <RadioGroup
                      defaultValue={RoleName.VIEWER}
                      onChange={(e) =>
                        formik.setFieldValue("role", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value={RoleName.VIEWER}
                        control={<Radio />}
                        label={RoleName.VIEWER}
                      />
                      <FormControlLabel
                        value={RoleName.EDITOR}
                        control={<Radio />}
                        label={RoleName.EDITOR}
                      />
                      <FormControlLabel
                        value={RoleName.ADMIN}
                        control={<Radio />}
                        label={RoleName.ADMIN}
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
                <FormControl
                  sx={{ mt: 2 }}
                  error={!!formik.errors.expires_at}
                  variant="standard"
                >
                  <FormLabel sx={{ textAlign: "left" }}>
                    Expires At (Optional)
                  </FormLabel>
                  <FormGroup>
                    <DatePickerValue
                      value={null}
                      onChange={(date: Dayjs | null) =>
                        formik.setFieldValue(
                          "expires_at",
                          date?.format("YYYY-MM-DD"),
                        )
                      }
                    />
                  </FormGroup>
                </FormControl>
                <CustomButton
                  label="Share Episode"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isPending || Object.keys(formik.errors).length > 0}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
