import React from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Alert,
  Box,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useMutation } from "@tanstack/react-query";

import CustomButton from "../../../common/Button";
import { RoleName } from "../../../common/enums/RoleName";
import {
  SharedEpisode,
  UpdateSharedEpisodeRequest,
} from "../../services/shared-episodes.service";
import { sharedEpisodeService } from "../../services";
import DatePickerValue from "./parts/DatePicker";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  handleClose: () => void;
  open: boolean;
  sharedEpisode: SharedEpisode;
}

export default function EditSharedEpisodeDialog({
  handleClose,
  open,
  sharedEpisode,
}: Props) {
  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["update-shared-episode-roles"],
    mutationFn: (values: UpdateSharedEpisodeRequest) =>
      sharedEpisodeService.updateSharedEpisode(values),
  });

  const handleUpdateSharedEpisodeRoles = async ({
    role,
    expires_at,
  }: {
    role: RoleName;
    expires_at?: string;
  }) => {
    await mutateAsync({ id: sharedEpisode.id, role, expires_at });
  };

  if (isSuccess) {
    setTimeout(() => {
      handleClose();
    }, 2000);
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Modify User Roles
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          initialValues={{
            role: sharedEpisode.role.name,
            expires_at: sharedEpisode.expires_at,
          }}
          onSubmit={handleUpdateSharedEpisodeRoles}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isError ? (
                  <Alert severity="error" sx={{ marginBottom: "12px" }}>
                    {error.message}
                  </Alert>
                ) : null}

                {isSuccess ? (
                  <Alert severity="success" sx={{ marginBottom: "12px" }}>
                    Roles updated successfully!
                  </Alert>
                ) : null}
                <FormControl
                  required
                  error={!!formik.errors.role}
                  component="fieldset"
                  sx={{ mt: 2 }}
                  variant="standard"
                >
                  <FormLabel component="legend" sx={{ textAlign: "left" }}>
                    Role
                  </FormLabel>
                  <FormGroup>
                    <RadioGroup
                      defaultValue={sharedEpisode.role?.name}
                      onChange={(e) =>
                        formik.setFieldValue("role", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value={RoleName.VIEWER}
                        control={<Radio />}
                        label={RoleName.VIEWER}
                      />
                      <FormControlLabel
                        value={RoleName.EDITOR}
                        control={<Radio />}
                        label={RoleName.EDITOR}
                      />
                      <FormControlLabel
                        value={RoleName.ADMIN}
                        control={<Radio />}
                        label={RoleName.ADMIN}
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
                <FormControl
                  sx={{ mt: 2 }}
                  error={!!formik.errors.expires_at}
                  variant="standard"
                >
                  <FormLabel sx={{ textAlign: "left" }}>
                    Expires At (Optional)
                  </FormLabel>
                  <FormGroup>
                    <DatePickerValue
                      value={dayjs(sharedEpisode.expires_at)}
                      onChange={(date: Dayjs | null) =>
                        formik.setFieldValue(
                          "expires_at",
                          date?.format("YYYY-MM-DD"),
                        )
                      }
                    />
                  </FormGroup>
                </FormControl>

                <CustomButton
                  label="Update Roles"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isPending || Object.keys(formik.errors).length > 0}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
