import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

import { toHHMMSS } from "../../../../../utils/formatTIme";
import ActionsList from "./ActionsList";
import InsightsModalManager, {
  ModalType,
} from "../../../../common/ModalManager";
import { Quote as QuoteType } from "../../../services/episode.service";
import { Category } from "../../../../common/types";

export type QuoteObject = {
  speaker: string;
  quote: string;
  timestamp: string;
};

interface QuoteProps {
  quoteObject: QuoteObject;
  quotes: QuoteType[];
  refetch: () => void;
  category: Category;
}

export default function Quote({
  quoteObject,
  quotes,
  refetch,
  category,
}: QuoteProps) {
  const [openInsightsModalManager, setOpenInsightsModalManager] =
    React.useState(false);
  const handleOpenInsightsModalManager = () =>
    setOpenInsightsModalManager(true);

  const handleCopy = () => {
    navigator.clipboard.writeText(quoteObject.quote);
  };

  return (
    <Box
      key={quoteObject.timestamp}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px 20px 10px 20px",
      }}
    >
      <InsightsModalManager
        open={openInsightsModalManager}
        setOpen={setOpenInsightsModalManager}
        modalType={ModalType.QUOTES}
        modalTitle="Edit Quote"
        objectValue={quoteObject}
        values={quotes}
        refetch={refetch}
        category={category}
      />
      <Card sx={{ width: "100%", alignSelf: "center" }} variant="outlined">
        <CardContent>
          <ActionsList
            handleOpenInsightsModalManager={handleOpenInsightsModalManager}
            handleCopy={handleCopy}
          />
          <Box>
            <Box sx={{ display: "flex" }}>
              <RecordVoiceOverIcon
                sx={{ fontSize: "16px", marginTop: "4px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                  paddingBottom: "20px",
                  marginLeft: "10px",
                  color: "#0F4C81",
                  fontWeight: "medium",
                }}
              >
                {quoteObject.speaker}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "70%",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  wordBreak: "break-word",
                  fontWeight: "medium",
                }}
              >
                "{quoteObject.quote}"
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyItems: "center" }}>
              <AccessTimeIcon sx={{ marginTop: "10px", fontSize: "18px" }} />
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontSize: "12px",
                  marginLeft: "4px",
                  marginTop: "10px",
                  backgroundColor: "#0288d1",
                  color: "#fff",
                  fontWeight: "medium",
                  paddingX: 1,
                  borderRadius: 4,
                }}
              >
                {toHHMMSS(quoteObject.timestamp)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
