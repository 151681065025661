import React from "react";

import { Box, Popover, Slider, Typography } from "@mui/material";
import { FormatLineSpacing } from "@mui/icons-material";

import "./style.css";
import { SubtitleStyles } from "../../../../../../types";

interface LetterSpacingProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function LetterSpacing({
  styles,
  onUpdateStyles,
}: LetterSpacingProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleValueChange = (
    key: "line_height" | "letter_spacing",
    value: number,
  ) => {
    let validatedValue: number;
    switch (key) {
      case "line_height":
        validatedValue = Math.min(Math.max(value, 1), 3);
        break;
      case "letter_spacing":
        validatedValue = Math.min(Math.max(value, 0), 10);
        break;
      default:
        validatedValue = value;
    }
    onUpdateStyles({
      ...styles,
      [key]: validatedValue,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "letter-spacing-popover" : undefined;

  return (
    <Box
      sx={{
        border: "1px solid #0000003b",
        borderRadius: "12px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        type="button"
        className="letter-spacing-button"
        onClick={handleClick}
        aria-describedby={id}
        aria-label="Select Letter Casing"
      >
        <FormatLineSpacing />
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: "-10px",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="subtitle1">Line Height</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Slider
              value={styles.line_height}
              onChange={(_, newValue) =>
                handleValueChange("line_height", newValue as number)
              }
              aria-labelledby="line-height-slider"
              min={1}
              max={3}
              step={0.1}
              sx={{ minWidth: "160px" }}
            />
            <input
              type="number"
              step={0.1}
              onChange={(e) =>
                handleValueChange("line_height", parseFloat(e.target.value))
              }
              min={1}
              max={3}
              value={styles.line_height}
              style={{ width: "60px" }}
            />
          </Box>

          <Typography variant="subtitle1">Letter Spacing</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Slider
              value={styles.letter_spacing}
              onChange={(_, newValue) =>
                handleValueChange("letter_spacing", newValue as number)
              }
              aria-labelledby="letter-spacing-slider"
              min={0}
              max={10}
              step={0.1}
              sx={{ minWidth: "160px" }}
            />
            <input
              type="number"
              step={0.1}
              onChange={(e) =>
                handleValueChange("letter_spacing", parseFloat(e.target.value))
              }
              min={0}
              max={10}
              value={styles.letter_spacing}
              style={{ width: "60px" }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
