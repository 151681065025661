import React from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { Alert, Box, TextField } from "@mui/material";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";

import { projectsService } from "../../services";
import { CreateProjectPayload } from "../../services/projects.service";
import CustomButton from "../../../common/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface Props {
  handleClose: () => void;
  open: boolean;
}

export default function CreateProjectDialog({ handleClose, open }: Props) {
  const { mutateAsync, isError, error, isPending, isSuccess } = useMutation({
    mutationKey: ["create-project"],
    mutationFn: (values: CreateProjectPayload) =>
      projectsService.createProject(values),
  });

  const handleCreateProject = async (values: CreateProjectPayload) => {
    await mutateAsync(values);
  };

  if (isSuccess) {
    setTimeout(() => {
      handleClose();
      window.location.href = "/projects";
    }, 2000);
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        New Project
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          initialValues={{ project_name: "", project_description: "" }}
          validationSchema={Yup.object({
            project_name: Yup.string().required("Required"),
          })}
          onSubmit={handleCreateProject}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isError ? (
                  <Alert severity="error" sx={{ marginBottom: "12px" }}>
                    {error.message}
                  </Alert>
                ) : null}

                {isSuccess ? (
                  <Alert severity="success" sx={{ marginBottom: "12px" }}>
                    Project Created!
                  </Alert>
                ) : null}

                <TextField
                  sx={{ width: "70%", alignSelf: "center" }}
                  margin="normal"
                  label="Project Name"
                  error={!!formik.errors.project_name}
                  helperText={formik.errors.project_name}
                  {...formik.getFieldProps("project_name")}
                />

                <TextField
                  sx={{ width: "70%", alignSelf: "center" }}
                  margin="normal"
                  label="Project Description"
                  error={!!formik.errors.project_description}
                  helperText={formik.errors.project_description}
                  {...formik.getFieldProps("project_description")}
                />
                <CustomButton
                  label="Create Project"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isPending || isSuccess}
                />
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
