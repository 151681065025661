import {
  adduserToOrganization,
  fetchOrganizationUsers,
  setIsUserActive,
  updateUsersRole,
  deleteUser,
  confirmEmailAndSetPassword,
} from "./user-management.service";

export const userManagementService = {
  fetchOrganizationUsers,
  adduserToOrganization,
  setIsUserActive,
  updateUsersRole,
  deleteUser,
  confirmEmailAndSetPassword,
};
