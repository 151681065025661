import React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import PaymentIcon from "@mui/icons-material/Payment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { CookieKey, deleteCookie } from "../../../../../hooks/useCookie";
import { accountService } from "./services";

export default function AccountMenu() {
  // States
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    deleteCookie(CookieKey.JWT_TOKEN);
    deleteCookie(CookieKey.REFRESH_TOKEN);
    window.location.href = "/login";
  };

  const handleBilling = async () => {
    const billingData = await accountService.fetchBilling();
    window.location.replace(billingData.url);
  };

  const handleAccount = async () => {
    window.location.replace("/account");
  };

  return (
    <Box>
      <IconButton color="inherit" onClick={handleClick}>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        slotProps={{
          paper: {
            style: {
              width: "240px",
            },
          },
        }}
      >
        <MenuItem onClick={handleAccount}>
          <ManageAccountsIcon sx={{ marginRight: "12px" }} />
          <Typography sx={{ fontSize: "14px" }}>Account</Typography>
        </MenuItem>
        <MenuItem onClick={handleBilling}>
          <PaymentIcon sx={{ marginRight: "12px" }} />
          <Typography sx={{ fontSize: "14px" }}>Billing</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: "12px" }} />
          <Typography sx={{ fontSize: "14px" }}>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
