import React from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useUserState } from "../../../store";
import { RoleName } from "../enums/RoleName";

interface RoleRouteProps {
  children: React.ReactElement;
  allowedRoles: RoleName[];
  redirectPath?: string;
}

function RoleRoute({
  children,
  allowedRoles,
  redirectPath = "/episodes",
}: RoleRouteProps) {
  const { user } = useUserState();
  const location = useLocation();

  const hasRequiredRole = () => {
    if (
      !user?.roles ||
      !Array.isArray(user.roles) ||
      !Array.isArray(allowedRoles)
    ) {
      return false;
    }

    return user.roles.some((userRole) => allowedRoles.includes(userRole));
  };

  if (!hasRequiredRole()) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children;
}

export default RoleRoute;
