import React from "react";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import confirmAccountService from "./services";

export default function ConfirmEmailView() {
  const { hash } = useParams();

  if (!hash) {
    window.location.href = "/login";
  }

  const { isSuccess, isError } = useQuery({
    queryKey: ["accountConfirm", hash],
    queryFn: () => confirmAccountService.confirmAccount(hash),
  });

  if (isSuccess) {
    window.location.href = "/login";
  }

  if (isError) {
    window.location.href = "/login";
  }

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        justifyItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box sx={{ alignSelf: "center" }}>
        <Card sx={{ padding: 10 }}>
          <CardContent
            sx={{
              display: "flex",
            }}
          >
            <Typography component="p" variant="subtitle2">
              Your account is almost ready! Please wait...
            </Typography>
            <CircularProgress size="22px" sx={{ ml: 2 }} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
