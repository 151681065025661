import React from "react";

import Box from "@mui/material/Box";
import {
  Alert,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BusinessIcon from "@mui/icons-material/Business";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Bounce, toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";

import SidebarView from "../common/Sidebar";
import Footer from "../common/Footer";
import { organizationsService } from "./services";
import CustomButton from "../common/Button";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </Box>
  );
}

export default function AccountView() {
  const [tabValue, setTablValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTablValue(newValue);
  };

  const { data, isSuccess } = useQuery({
    queryKey: ["organization"],
    queryFn: () => organizationsService.fetchOrganizationDetails(),
  });

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: ["update-project"],
    mutationFn: (value: string) =>
      organizationsService.updateOrganization(value),
  });

  const handleSave = async (params: { organization_name: string }) => {
    await toast.promise(mutateAsync(params.organization_name), {
      success: {
        toastId: `${params.organization_name}-success`,
        render() {
          return "Updated!";
        },
        type: "success",
        theme: "colored",
        autoClose: 2000,
        transition: Bounce,
        delay: 500,
      },
    });
  };

  return (
    <>
      <SidebarView pageName="Account Settings" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            margin: 12,
            overflow: "visible",
          }}
        >
          <Box sx={{ alignSelf: "start" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "none" }}
                icon={<BusinessIcon />}
                iconPosition="start"
                label="Organization"
              />
            </Tabs>
          </Box>
          <Divider />
          <CustomTabPanel value={tabValue} index={0}>
            <Card
              sx={{
                maxWidth: 700,
                mt: 5,
                py: 2,
                boxShadow: 0,
                border: 1,
                borderColor: "lightgray",
              }}
            >
              <CardContent>
                <Typography variant="body1" component="p" gutterBottom>
                  Organization Information
                </Typography>
                <Divider sx={{ my: 2 }} />
                {isSuccess && (
                  <Formik
                    initialValues={{ organization_name: data?.name ?? "" }}
                    validationSchema={Yup.object({
                      organization_name: Yup.string().required("Required"),
                    })}
                    onSubmit={handleSave}
                  >
                    {(formik) => (
                      <form onSubmit={formik.handleSubmit}>
                        <Box
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {isError ? (
                            <Alert
                              severity="error"
                              sx={{ marginBottom: "12px" }}
                            >
                              {error.message}
                            </Alert>
                          ) : null}

                          <TextField
                            margin="normal"
                            label="Organization name"
                            error={!!formik.errors.organization_name}
                            helperText={formik.errors.organization_name}
                            {...formik.getFieldProps("organization_name")}
                          />
                          <CustomButton
                            label="Update organization"
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isPending}
                          />
                        </Box>
                      </form>
                    )}
                  </Formik>
                )}
              </CardContent>
            </Card>
          </CustomTabPanel>
        </Card>
        <Footer />
      </Box>
    </>
  );
}
