/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Notify() {
  return (
    <Box>
      <ToastContainer newestOnTop />
    </Box>
  );
}

export default Notify;
