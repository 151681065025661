import React from "react";

import { Box, CssBaseline } from "@mui/material";

import Videos from "../modules/Videos";

function VideosPage() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Videos />
    </Box>
  );
}

export default VideosPage;
