import { coreService } from "../../../services";

export interface SignupPayload {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  organization_name?: string;
  send_promotional_emails?: boolean;
}

const signup = async (payload: SignupPayload): Promise<void> => {
  await coreService.httpClient.post("/auth/signup", payload);
};

export { signup };
