import * as React from "react";

import Container from "@mui/material/Container";

import Footer from "../modules/common/Footer";
import ForgotPasswordView from "../modules/ForgotPassword";

export default function ForgotPasswordPage() {
  return (
    <Container component="main" maxWidth="xs">
      <ForgotPasswordView />
      <Footer />
    </Container>
  );
}
