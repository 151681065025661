export enum Category {
  QUOTES = "quotes",
  TITLES = "titles",
  TWEETS = "tweets",
  KEYWORDS = "keywords",
  LINKEDINPOSTS = "linkedin-posts",
  DESCRIPTIONS = "descriptions",
  CHAPTERS = "chapters",
  BLOGPOST = "blog_post",
  NEWSLETTER = "newsletter",
}
