import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@tanstack/react-query";
import { Typography } from "@mui/material";

import { projectsService } from "../../../../services";

interface Props {
  handleOpenDeleteDialog: () => void;
  openDeleteDialog: boolean;
  projectId: string;
  projectName: string;
}

export default function DeleteDialog({
  handleOpenDeleteDialog,
  openDeleteDialog,
  projectId,
  projectName,
}: Props) {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["delete-project"],
    mutationFn: () => projectsService.deleteProject(projectId),
  });

  const handleSave = async () => {
    await mutateAsync();

    window.location.reload();
  };
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={handleOpenDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete project{" "}
          <Typography variant="body1" component="span" fontWeight="bold">
            "{projectName}"
          </Typography>
          {" ?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isPending} onClick={handleOpenDeleteDialog}>
          Back
        </Button>
        <Button
          disabled={isPending}
          onClick={handleSave}
          autoFocus
          sx={{ color: "red" }}
        >
          Confrim
        </Button>
      </DialogActions>
    </Dialog>
  );
}
