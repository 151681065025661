import React, { useState } from "react";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Divider, Stack } from "@mui/material";
import Link from "@mui/material/Link";

import ContinueWithGoogle from "../Login/components/ContinueWithGoogle";
import ContinueWithApple from "../Login/components/ContinueWithApple";
import SignupForm from "./RegisterForm";

export default function RegisterView() {
  const [isSuccessfullySignedUp, setSsSuccessfullySignedUp] =
    useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h4">
        Podcast Assistant
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          padding: "30px 0px 10px 0px",
        }}
      >
        <Avatar sx={{ marginRight: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ marginTop: 0.5 }}>
          Sign Up
        </Typography>
      </Box>
      {isSuccessfullySignedUp && (
        <Box>
          <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
            You have successfully signed up. We have sent you an email to{" "}
            <b>{userEmail}</b>. Please verify your email address.
          </Typography>
          <Typography component="p" sx={{ marginY: 2, textAlign: "center" }}>
            Back to{" "}
            <Link href="/login" variant="body2">
              Sign In
            </Link>
          </Typography>
        </Box>
      )}
      {!isSuccessfullySignedUp && (
        <Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <ContinueWithGoogle
              onClick={() => {
                const serverUrl = process.env.REACT_APP_API_URL;
                window.location.href = `${serverUrl}/auth/signin/google`;
              }}
            />
            <ContinueWithApple
              onClick={() => {
                const serverUrl = process.env.REACT_APP_API_URL;
                window.location.href = `${serverUrl}/auth/signin/apple`;
              }}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant="body1" color="textSecondary">
                Or
              </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
          </Box>
          <SignupForm
            handleSuccessfulSignup={(email: string) => {
              setSsSuccessfullySignedUp(true);
              setUserEmail(email);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
