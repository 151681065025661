import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../common/Button";
import { useAuth } from "../../middlewares/Auth/AuthContext";
import { SignupPayload } from "./services/signup.service";
import { signupService } from "./services";

interface SignupFormProps {
  handleSuccessfulSignup: (email: string) => void;
}

export default function SignupForm({
  handleSuccessfulSignup,
}: SignupFormProps) {
  const auth = useAuth();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["signup"],
    mutationFn: (values: SignupPayload) => signupService.signup(values),
    onSuccess(_response: any, values: SignupPayload) {
      handleSuccessfulSignup(values.email);
    },
    onError(error: AxiosError) {
      if (error.response?.status === 401) {
        auth.logout();
      }
    },
  });

  const handleSubmit = async (values: any) => {
    await mutateAsync(values);
  };

  if (isPending) {
    return (
      <Box
        sx={{ display: "flex", marginTop: "64px", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        organization_name: "",
        send_promotional_emails: false,
      }}
      validationSchema={Yup.object({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("Required"),
        organization_name: Yup.string(),
        send_promotional_emails: Yup.boolean(),
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {(formik) => (
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            {/* First Name Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="First Name"
                autoComplete="first_name"
                autoFocus
                error={!!formik.errors.first_name}
                helperText={formik.errors.first_name}
                {...formik.getFieldProps("first_name")}
              />
            </Grid>
            {/* Last Name Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Last Name"
                autoComplete="last_name"
                autoFocus
                error={!!formik.errors.last_name}
                helperText={formik.errors.last_name}
                {...formik.getFieldProps("last_name")}
              />
            </Grid>
            {/* Email Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                {...formik.getFieldProps("email")}
              />
            </Grid>
            {/* Password Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                error={!!formik.errors.password}
                helperText={formik.errors.password}
                {...formik.getFieldProps("password")}
              />
            </Grid>
            {/* Organization Name Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Organization Name"
                autoFocus
                error={!!formik.errors.organization_name}
                helperText={formik.errors.organization_name}
                {...formik.getFieldProps("organization_name")}
              />
            </Grid>
            {/* Send Promotional Emails Checkbox */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    {...formik.getFieldProps("send_promotional_emails")}
                    checked={formik.values.send_promotional_emails}
                  />
                }
                label="I want to receive news, offers, and helpful content via email."
              />
            </Grid>
          </Grid>
          {/* Sign Up Button */}
          <Button
            label="Sign Up"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}
