import React from "react";

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export function SidebarContent({ sidebarContent }: any) {
  return (
    <List
      component="nav"
      sx={{
        marginTop: "50px",
        height: "95%",
        backgroundColor: (theme) => theme.palette.grey[50],
      }}
    >
      <Box sx={{ mt: "30px" }}>
        {sidebarContent.map((item: any) => {
          return (
            <Box
              key={item.name}
              sx={{ opacity: `${item.disabled ? "0.3" : "1"}` }}
            >
              <ListItemButton
                sx={{
                  px: 1,
                  paddingBottom: "12px",
                  paddingTop: "12px",
                  backgroundColor: item.isSelected
                    ? (theme) => theme.palette.grey[100]
                    : "",
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.grey[50],
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "10px" }}>{item.name}</Typography>
                </ListItemText>
              </ListItemButton>
            </Box>
          );
        })}
      </Box>
    </List>
  );
}
