import React from "react";

import { Box, Card, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import SidebarView from "../common/Sidebar";
import { sharedEpisodeService } from "../SharedEpisodes/services";
import Insights from "../Episode/components/Insights";

export default function EpisodesSharedWithMeDetailsView() {
  const { id: episodeId } = useParams();

  const { data: insights, refetch } = useQuery({
    queryKey: ["get-insights", episodeId],
    queryFn: () =>
      sharedEpisodeService.getInsightsForEpisodeSharedWithMeByEpisodeId(
        episodeId,
      ),
  });

  return (
    <>
      <SidebarView pageName="Episodes Shared With Me" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card
          component={Stack}
          spacing={0}
          direction="column"
          sx={{
            position: "relative",
            px: 4,
            py: 6,
            borderRadius: 2,
            margin: 12,
            marginLeft: 6,
            marginRight: 6,
          }}
        >
          <Typography variant="h5" component="h1">
            Episode Shared With Me Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "24px 0px 24px 0px",
            }}
          >
            {episodeId && (
              <Insights
                insights={insights}
                refetch={refetch}
                episodeId={episodeId}
              />
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
}
