import { useEffect, useRef } from "react";

import { io, Socket } from "socket.io-client";

import { getCookie } from "../utils/cookie";
import { CookieKey } from "./useCookie";

export const useWebSocket = () => {
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const newSocket = io(`${serverUrl}?jwt=${getCookie(CookieKey.JWT_TOKEN)}`);

    socketRef.current = newSocket;

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return socketRef.current;
};
