import React from "react";

import { Group, Rect } from "react-konva";

import {
  ELEMENT_HEIGHT_IN_PX,
  ROW_HEIGHT_IN_PX,
  RULER_HEIGHT_IN_PX,
} from "../../constants";
import { IElement } from "./types/IElement";

interface ElementProps {
  element: IElement;
  onElementChange: (element: IElement) => void;
  pixelsPerMillisecond: number;
  numberOfRows: number;
}

export default function Element({
  element,
  onElementChange,
  pixelsPerMillisecond,
  numberOfRows,
}: ElementProps) {
  const [isResizing, setIsResizing] = React.useState<string | null>(null);
  const [initialElement, setInitialElement] = React.useState<IElement>(element);

  const isCursorNearElementEdge = (e: any, el: IElement): string | null => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const elementX = el.start * pixelsPerMillisecond;
    const elementWidth = el.width * pixelsPerMillisecond;

    if (pos.x >= elementX && pos.x <= elementX + 20) {
      return "left";
    }
    if (
      pos.x >= elementX + elementWidth - 20 &&
      pos.x <= elementX + elementWidth
    ) {
      return "right";
    }
    return null;
  };

  const handleDragStart = (e: any) => {
    setInitialElement(element);
    setIsResizing(isCursorNearElementEdge(e, element));
    onElementChange({
      ...element,
      isDragging: !isCursorNearElementEdge(e, element),
      isResizing: !!isCursorNearElementEdge(e, element),
    });
  };

  const handleDragMove = (e: any) => {
    let mouseX = e.target.x() / pixelsPerMillisecond;
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const currentCursorX = pointerPosition ? pointerPosition.x : 0;

    // Prevent element from going out of bounds on the left
    if (mouseX < 0) {
      mouseX = 0;
      e.target.x(0);
    }

    // Prevent element from moving up and down when resizing
    if (element.isResizing) {
      e.target.y(0);
    }

    if (element.isResizing) {
      if (isResizing === "left") {
        const newWidth = initialElement.width + (initialElement.start - mouseX);
        if (newWidth > 0) {
          onElementChange({
            ...element,
            start: mouseX,
            width: newWidth,
          });
        }
      } else if (isResizing === "right") {
        const newWidth = currentCursorX / pixelsPerMillisecond - element.start;
        // fix element left side
        e.target.x(element.start * pixelsPerMillisecond);
        if (newWidth > 0) {
          onElementChange({
            ...element,
            start: element.start,
            width: newWidth,
          });
        }
      }
    } else if (element.isDragging) {
      const newYRelativeToGroup = e.target.getAbsolutePosition().y;

      if (newYRelativeToGroup < RULER_HEIGHT_IN_PX) {
        e.target.absolutePosition({
          y: RULER_HEIGHT_IN_PX,
          x: e.target.getAbsolutePosition().x,
        });
      }

      if (newYRelativeToGroup > ROW_HEIGHT_IN_PX * numberOfRows) {
        const positionY =
          (numberOfRows - 1) * ROW_HEIGHT_IN_PX + RULER_HEIGHT_IN_PX;
        e.target.absolutePosition({
          y: positionY,
          x: e.target.getAbsolutePosition().x,
        });
      }

      onElementChange({
        ...element,
        start: mouseX,
        isDragging: true,
      });
    }
  };

  const handleDragEnd = (e: any) => {
    const newX = e.target.x() / pixelsPerMillisecond; // Convert pixels to time
    let newYRelativeToGroup = e.target.getAbsolutePosition().y; // Get the y position of the element relative to the group

    // Snap to the closest row
    newYRelativeToGroup = Math.max(
      RULER_HEIGHT_IN_PX,
      Math.min(newYRelativeToGroup, ROW_HEIGHT_IN_PX * numberOfRows),
    );
    const nearestRow = Math.round(
      (newYRelativeToGroup - RULER_HEIGHT_IN_PX) / ROW_HEIGHT_IN_PX,
    );
    const snappedY = nearestRow * ROW_HEIGHT_IN_PX + RULER_HEIGHT_IN_PX;

    // Set the position to the snapped row
    e.target.absolutePosition({
      x: e.target.getAbsolutePosition().x,
      y: snappedY,
    });

    onElementChange({
      ...element,
      row: nearestRow + 1,
      start: newX,
      isDragging: false,
      isResizing: false,
    });
  };

  const handleMouseEnter = (e: any) => {
    if (!element.isDraggable) return;
    const stage = e.target.getStage();
    const resizeDirection = isCursorNearElementEdge(e, element);
    stage.container().style.cursor = resizeDirection ? "ew-resize" : "pointer";
    onElementChange({ ...element, isHovered: true });
  };

  const handleMouseLeave = (e: any) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = "default";
    onElementChange({ ...element, isHovered: false });
  };

  return (
    <Group>
      <Rect
        x={element.start * pixelsPerMillisecond}
        y={0}
        width={element.width * pixelsPerMillisecond}
        height={ELEMENT_HEIGHT_IN_PX}
        fill={element.isSelected ? "green" : "#c7c9d6"}
        draggable={element.isDraggable} // Use the isDraggable property
        strokeWidth={element.isHovered ? 2 : 0}
        stroke="green"
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        cornerRadius={8}
      />
      <Rect
        visible={element.isHovered || element.isResizing}
        x={element.start * pixelsPerMillisecond + 5}
        y={(50 - 15) / 2} // Center the rect vertically
        width={2}
        height={15}
        fill="white"
      />
      <Rect
        visible={element.isHovered || element.isResizing}
        x={element.start * pixelsPerMillisecond + 10}
        y={(50 - 15) / 2} // Center the rect vertically
        width={2}
        height={15}
        fill="white"
      />
      <Rect
        visible={element.isHovered || element.isResizing}
        x={
          element.start * pixelsPerMillisecond +
          element.width * pixelsPerMillisecond -
          10
        }
        y={(50 - 15) / 2} // Center the rect vertically
        width={2}
        height={15}
        fill="white"
      />
      <Rect
        visible={element.isHovered || element.isResizing}
        x={
          element.start * pixelsPerMillisecond +
          element.width * pixelsPerMillisecond -
          15
        }
        y={(50 - 15) / 2} // Center the rect vertically
        width={2}
        height={15}
        fill="white"
      />
    </Group>
  );
}
