import React, { useRef } from "react";

import { Box } from "@mui/material";

import SubtitleEditor from "./parts/SubtitleEditor";
import { Episode } from "../../services/episode.service";
import { VideoPlayerState } from "./types/VideoPlayerState";
import VideoPreviewPlayer from "./parts/VideoPreviewPlayer";

interface Props {
  episode?: Episode;
}

export default function Preview({ episode }: Props) {
  const playerRef = useRef<any>(null);
  const [videoPlayerState, setVideoPlayerState] =
    React.useState<VideoPlayerState>({
      isPlaying: false,
      currentTime: 0,
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        height: "100vh",
        overflow: "none",
      }}
    >
      <Box
        sx={{
          width: "30%",
          height: "100%",
          pr: 4,
        }}
      >
        {episode && (
          <SubtitleEditor
            episode={episode}
            currentTime={videoPlayerState?.currentTime}
            onParagraphClick={(paragraph) => {
              playerRef.current.seekTo(paragraph.start);
              setVideoPlayerState({
                ...videoPlayerState,
                currentTime: paragraph.start,
              });
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "70%",
          height: "100%",
        }}
      >
        {episode?.upload?.url && (
          <VideoPreviewPlayer
            ref={playerRef}
            src={episode.upload.url}
            videoPlayerState={videoPlayerState}
            onUpdatePlayerState={setVideoPlayerState}
          />
        )}
      </Box>
    </Box>
  );
}
