import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from "./projects.service";

export const projectsService = {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
};
